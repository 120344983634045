import axios from 'axios';
import React, {useEffect} from 'react';
import {useLocation, useHistory} from 'react-router';
import {successLog, errorLog} from '../helpers/Logger';
import {constantContact, aweber} from '../helpers/Urls';

// TODO: add hanlder for promise proxy || currently not waiting for proxy token in proxy mode

const OAuthRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  const redirectToSetupConfig = (data, planId, actionCode) => {
    // redirect back to setup config
    successLog('Generated OAuthToken', data);
    let redirectURL = localStorage.getItem('OAuthActionURL');

    // redirect to home
    if (redirectURL.includes('get-started')) {
      console.log(redirectURL);
      return history.push(`/?planId=${planId}&actionCode=${actionCode}`);
    }
    //  redirect to action page
    return history.push(redirectURL);
  };

  const ctctCodeToToken = () => {
    const params = new URLSearchParams(location.search);
    let code = params.get('code');
    let plan_id = localStorage.getItem('plan_id');

    if (code && plan_id)
      axios
        .get(constantContact.getAccessToken, {
          params: {
            code,
            plan_id,
          },
        })
        .then(({data}) => {
          // redirect back to setup config
          redirectToSetupConfig(data, plan_id, 'constant_contact');
        })
        .catch((err) => {
          errorLog('Error generating OAuthToken', err);
        });
    else history.push('/actions');
  };

  const aweberCodeToToken = (codedUrl) => {
    let plan_id = localStorage.getItem('plan_id');
    axios
      .get(aweber.getOAuthToken, {
        params: {
          url: codedUrl,
          plan_id,
        },
      })
      .then(({data}) => redirectToSetupConfig(data, plan_id, 'aweber'))
      .catch((err) => {
        errorLog('Error generating OAuthToken', err);
      });
  };

  useEffect(() => {
    //TODO add handler if Aweber or Constant Contact
    if (localStorage.getItem('OAuthActionURL').includes('constant_contact')) {
      console.log('constant contact handler');
      ctctCodeToToken();
    } else {
      console.log('Aweber handlder');
      console.log(window.location);
      const codedUrl = window.location.href;
      aweberCodeToToken(codedUrl);
    }
    // handleCode();
    // eslint-disable-next-line
  }, []);
  return <div>Redirecting</div>;
};

export default OAuthRedirect;
