import React, {useState, useEffect} from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfigureEmail from '../../components/settings/ConfigureEmail';

import LayoutDashboard from '../../layout/LayoutDashboard';

import axios from 'axios';

import {settings} from '../../helpers/Urls';
import PreviewEmail from '../../components/previewEmail';
import {useSelector} from 'react-redux';
import {warningLog} from '../../helpers/Logger';

const Email = (props) => {
  const account = useSelector((state) => state.account);
  const [styling, setStyling] = useState({
    header: {
      bg: '',
      color: '',
    },
    body: {
      bg: '',
      color: '',
    },
    button: {
      bg: '',
      color: '',
    },
    font: '',
  });

  const [content, setContent] = useState({
    fromName: '',
    replyToAddress: '',
    emailLogo: '',
  });

  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(true);

  // TODO: change settnings.onbaording URL -> settings.branding for more meaning
  useEffect(() => {
    warningLog('styling', styling);
    warningLog('content', content);
    axios
      .get(`${settings.onboarding}`, {
        params: {
          type: 'email',
        },
      })
      .then(({data: emailData}) => {
        const colorScheme = JSON.parse(emailData?.color_scheme);

        warningLog('colorScheme', colorScheme);

        setStyling((prevState) => ({
          ...prevState,
          ...colorScheme,
          font: emailData?.font,
        }));

        setContent((prevState) => ({
          ...prevState,
          fromName: emailData?.from_name,
          replyToAddress: emailData?.reply_to_address,
          emailLogo: emailData?.email_logo,
        }));

        setLoading(false);
      })
      .catch((error) => setLoading(false));
    // eslint-disable-next-line
  }, [account]);

  return (
    <LayoutDashboard pageLoading={loading} className="email">
      <Breadcrumbs link="/settings" text="Settings" />
      <section className="banner">
        <h2 className="section-title">Emails</h2>
        <p>
          Change the configuration of the emails we wsend to yhour customers
        </p>
      </section>

      <section className="configuration-wrapper">
        <ConfigureEmail
          setFiles={setFiles}
          styling={styling}
          setStyling={setStyling}
          content={content}
          setContent={setContent}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
          files={files}
        />
        <PreviewEmail styling={styling} content={content} />
      </section>
    </LayoutDashboard>
  );
};

Email.propTypes = {};

export default Email;
