import React, {useEffect, useRef} from 'react';
import LayoutDashboard from '../layout/LayoutDashboard';
import runScanDom from '../lib/billsbyScanDom';
import PropTypes from 'prop-types';
import moment from 'moment';

import {connect} from 'react-redux';
import {cancelAccount} from '../actions/account';

function MySubscription({
  account: {account},

  cancelAccount,
  proxyMode,
}) {
  const viewInvoiceRef = useRef('invoice');
  const changePaymentCardRef = useRef('paymentCard');

  const customerUniqueId = account?.customerUniqueId;
  const subscriptionUniqueId = account?.subscriptionUniqueId;

  // eslint no-script-url: "error"

  useEffect(() => {
    console.log('scanning dom');

    setTimeout(() => {
      runScanDom();
    }, 3000);
    runScanDom();
  }, []);

  const handleOnClick = (type) => {
    runScanDom();
    if (type === 'VIEW_INVOICE') {
      viewInvoiceRef.current.click();
    } else {
      changePaymentCardRef.current.click();
    }
  };

  const handleCancelAccount = async () => {
    await cancelAccount(customerUniqueId, subscriptionUniqueId);
  };

  return (
    <LayoutDashboard className="page-my-subscription">
      <section className="banner">
        <h2 className="section-title">Manage Subscription</h2>
        <p>
          This is where you can manage your GetOnboard.ly subscription, and
          cancel it if you'd like, as well as viewing all of your invoices and
          getting information about your upcoming bills. To change these
          details, contact our customer services team.
        </p>
      </section>

      <section className="subscription-details">
        <h2 className="section-title">Subscription details</h2>
        <div className="content-wrapper">
          <div className="description">
            <div className="tr">
              <div className="td ">
                <span className="text-bold">Plan name </span>
              </div>
              <div className="td">
                <span> {account?.subscription?.planName}</span>
              </div>
            </div>

            <div className="tr">
              <div className="td ">
                <span className="text-bold">Plan frequency</span>
              </div>
              <div className="td">
                <span>{account?.subscription?.planFrequency}</span>
              </div>
            </div>

            <div className="tr">
              <div className="td ">
                <span className="text-bold">Next payment date </span>
              </div>
              <div className="td">
                <span>
                  {account?.subscription?.nextPaymentDate &&
                    moment(account?.subscription?.nextPaymentDate).format(
                      'MMMM DD YYYY'
                    )}
                </span>
              </div>
            </div>

            <div className="tr">
              <div className="td ">
                <span className="text-bold">Next payment amount</span>
              </div>
              <div className="td">
                <span>{account?.subscription?.nextPaymentAmount}</span>
              </div>
            </div>
          </div>
          <button
            className="btn-green"
            onClick={() => handleOnClick('VIEW_INVOICE')}
          >
            View invoices
          </button>
          {/* eslint-disable-next-line */}
          <a
            ref={viewInvoiceRef}
            href=""
            onClick={(e) => {
              e.preventDefault();
            }}
            data-billsby-type="account"
            data-billsby-customer={customerUniqueId}
            data-billsby-subscription={subscriptionUniqueId}
            data-billsby-action="billinghistory"
          ></a>
        </div>
      </section>

      <section className="payment-details">
        <h2 className="section-title">Payment details</h2>
        <div className="content-wrapper">
          <div className="description">
            <div className="tr">
              <div className="td ">
                <span className="text-bold">Billing address </span>
              </div>
              <div className="td">
                <span>
                  {account?.subscription?.billingAddress?.addressLine1}
                </span>
              </div>
            </div>

            <div className="tr">
              <div className="td ">
                <span className="text-bold"> Payment card</span>
              </div>
              <div className="td">
                <span> On file</span>
              </div>
            </div>
          </div>
          <button
            className="btn-green"
            onClick={() => handleOnClick('CHANGE_PAYMENT_CARD')}
          >
            Change payment card
          </button>
          {/* eslint-disable-next-line */}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
            }}
            ref={changePaymentCardRef}
            data-billsby-type="account"
            data-billsby-customer={customerUniqueId}
            data-billsby-subscription={subscriptionUniqueId}
            data-billsby-action="paymentdetails"
          ></a>
        </div>
      </section>

      <section className="cancel-account">
        <h2 className="section-title">Cancel account</h2>
        <div className="content-wrapper">
          <div className="description">
            <div className="tr">
              <div className="td ">
                <span>
                  When you cancel GetOnboard.ly, we’ll delete your account and
                  all of your configuration and settings immediately. You won’t
                  be able to recover it, and you won’t get a refund for unused
                  time.
                </span>{' '}
                <span className="text-bold">
                  Make sure to change your redirect URLs and webhook URLs before
                  cancelling.{' '}
                </span>
              </div>
            </div>
          </div>
          <button className="btn-red" onClick={handleCancelAccount}>
            Cancel GetOnboard.ly
          </button>
        </div>
      </section>
    </LayoutDashboard>
  );
}

MySubscription.propTypes = {
  proxyMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  account: state.account,
  proxyMode: state.account.proxyMode,
});

export default connect(mapStateToProps, {cancelAccount})(MySubscription);
