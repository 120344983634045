import React from 'react';
import {Link} from 'react-router-dom';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const Options = ({getstarted, isLive, accountType}) => {
  console.log(
    '%c 💂🏿‍♀️ account type ',
    'color:yellow;background:black;padding:5px;',
    accountType
  );

  return (
    <div className="options">
      {((!isLive && getstarted.branding?.onboarding?.configured) || isLive) && (
        <Link to="/settings/branding" className="options__link">
          <div className="options__card">
            <p>Branding</p>
          </div>
        </Link>
      )}

      {accountType === 'Billsby' ? (
        <Link to="/settings/billsby" className="options__link">
          <div className="options__card">
            <p>Billsby</p>
          </div>
        </Link>
      ) : (
        <Link to="/settings/chargebee" className="options__link">
          <div className="options__card">
            <p>Chargebee</p>
          </div>
        </Link>
      )}

      {((!isLive && getstarted.branding?.email?.configured) || isLive) && (
        <Link to="/settings/emails" className="options__link">
          <div className="options__card">
            <p>Emails</p>
          </div>
        </Link>
      )}
    </div>
  );
};

Options.propTypes = {
  getstarted: PropTypes.object.isRequired,
  isLive: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  getstarted: state.getstarted,
  isLive: state.account?.account?.live,
  accountType: state?.account?.account?.accountType,
});

export default connect(mapStateToProps, {})(Options);
