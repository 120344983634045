import React, {useState, useEffect} from 'react';
import {actions} from '../../../helpers/Urls';

import {useParams} from 'react-router-dom';
import LayoutDashboard from '../../../layout/LayoutDashboard';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Banner from '../../../components/actions/newsletters/Banner';
import {AweberActionForm} from '../../../components/actions/forms';
import axios from 'axios';

const ActionConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');
  let {planId} = useParams();

  const onSubmit = (isSuccess) => {
    console.log(isSuccess);
  };

  useEffect(() => {
    axios.get(actions.getPlans + '?id=' + planId).then(({data: plan}) => {
      setPlanName(plan.plan_name);
    });
  }, []);

  return (
    <LayoutDashboard pageLoading={loading} className="action-configuration">
      <Breadcrumbs link="/actions" text={`Actions for ${planName}`} />
      <AweberActionForm loadin={loading} planId={planId} onSubmit={onSubmit} />
    </LayoutDashboard>
  );
};

export default ActionConfiguration;
