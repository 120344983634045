import React, {useState, useEffect} from 'react';
import LayoutDashboard from '../../layout/LayoutDashboard';
import {useForm} from 'react-hook-form';
import Button from '../../components/Buttons';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faKey, faReply, faTrophy} from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../../components/Breadcrumbs';
import {settings} from '../../helpers/Urls';
import axios from 'axios';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {errorLog, infoLog} from '../../helpers/Logger';

const Billsby = () => {
  const account = useSelector((state) => state.account);
  const {register, handleSubmit, errors} = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [linked, setLinked] = useState({
    accountType: '',
    apiKey: '',
    domain: '',
    redirectUrl: '',
    webhookSecret: '',
    webhookUrl: '',
  });
  const [dataLoading, setDataLoading] = useState(true);

  const onSubmit = (data) => {
    infoLog('Form data', data);
    setSubmitLoading(true);

    axios
      .put(`${settings.billsby}`, {...data})
      .then((data) => {
        setSubmitLoading(false);
        toast.success('Settings saved succesfully', {});
      })
      .catch((error) => {
        errorLog('Error', error.response.data);

        if (error.response.data.toLowerCase().includes('api key')) {
          toast.error('Settings saved succesfully', {});
        } else {
          toast.error('Something went wrong', {});
        }
        setSubmitLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${settings.linked}`)
      .then(({data}) => {
        infoLog('Data', data);
        setLinked({
          accountType: data.accountType,
          apiKey: data.api_key,
          domain: data.domain,
          redirectUrl: data.redirect_url,
          webhookSecret: data.webhook_secret,
          webhookUrl: data.webhook_url,
        });
        setDataLoading(false);
      })
      .catch((error) => {
        errorLog('Something went wrong', error.response.data);
        setDataLoading(false);
      });
  }, [account]);
  return (
    <LayoutDashboard pageLoading={dataLoading} className="billsby-settings">
      <Breadcrumbs link="/settings" text="Settings" />
      <section className="banner">
        <h2 className="section-title">Billsby</h2>
        <p>
          Update the configuration for how your Billsby account interacts with
          your GetOnboard.ly account. This GetOnboard.ly account is linked to{' '}
          {linked.domain}.billsby.com - you can't change that part
        </p>
      </section>

      <div className="form-wrapper v2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tab-header">
            <div className="tab-header__icon">
              <FontAwesomeIcon icon={faKey} />
            </div>
            <div className="tab-header__name">API key</div>
          </div>

          <section>
            <div
              className={clsx('input-wrapper', {
                'input-error': errors.apiKey,
              })}
            >
              <label>
                <span>API key</span>
                <input
                  name="apiKey"
                  placeholder={linked.apiKey}
                  defaultValue={linked.apiKey}
                  ref={register({
                    required: 'Required',
                    // pattern: {
                    //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //   message: 'invalid email address',
                    // },
                  })}
                />
              </label>
            </div>
          </section>

          <div className="tab-header">
            <div className="tab-header__icon">
              <FontAwesomeIcon icon={faReply} />
            </div>
            <div className="tab-header__name">Web hook forwarding</div>
          </div>

          <section>
            <div
              className={clsx('input-wrapper', {
                'input-error': errors.webhookUrl,
              })}
            >
              <label>
                <span>URL</span>
                <input
                  name="webhookUrl"
                  placeholder={linked.webhookUrl}
                  defaultValue={linked.webhookUrl}
                  ref={register({
                    required: 'Required',
                    // pattern: {
                    //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //   message: 'invalid email address',
                    // },
                  })}
                />
              </label>
            </div>

            <div
              className={clsx('input-wrapper', {
                'input-error': errors.webhookSecret,
              })}
            >
              <label>
                <span>Secret</span>
                <input
                  name="webhookSecret"
                  placeholder={linked.webhookSecret}
                  defaultValue={linked.webhookSecret}
                  ref={register({
                    // pattern: {
                    //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //   message: 'invalid email address',
                    // },
                  })}
                />
              </label>
            </div>
          </section>

          <div className="tab-header">
            <div className="tab-header__icon">
              <FontAwesomeIcon icon={faTrophy} />
            </div>
            <div className="tab-header__name">Redirect URL</div>
          </div>

          <section>
            <div className="row">
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.redirectUrl,
                })}
              >
                <label>
                  <span>Redirect URL</span>
                  <input
                    name="redirectUrl"
                    placeholder={linked.redirectUrl}
                    defaultValue={linked.redirectUrl}
                    ref={register({
                      required: 'Required',
                      // pattern: {
                      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      //   message: 'invalid email address',
                      // },
                    })}
                  />
                </label>
                <span className="extra">
                  /?cid=XXX&sid=XXX&getonboardlyid=XXX
                </span>
              </div>
            </div>
          </section>

          <Button
            className="btn-purple"
            loading={submitLoading}
            text="Update branding settings"
          />
        </form>
      </div>
    </LayoutDashboard>
  );
};

export default Billsby;
