import React from 'react';
import clsx from 'clsx';

// NOTE: this component works with react-form-hook
const CustomerAction = ({
  title,
  description,
  label,
  name,
  placeholder,
  defaulValue,
  register,
  errors,
}) => {
  return (
    <div
      key={name}
      className={clsx('input-wrapper action', {'input-error': errors[name]})}
    >
      <h3 className="action__title">{title}</h3>
      <p className="action__description">{description}</p>
      <label className="action__label">
        <span>{label}</span>
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          defaultValue={defaulValue}
          ref={register}
        />
      </label>
    </div>
  );
};

export default CustomerAction;
