import React from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

const Toggler = ({actionCode, text, isToggled, callback}) => {
  const handleToggle = () => {
    callback(actionCode, !isToggled, text);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    callback(actionCode, isToggled, value);
  };

  return (
    <div style={{display: 'flex'}}>
      <div className="toggler-wrapper">
        <div className="toggler-button">
          <span
            className={clsx('check', {'active-btn': isToggled})}
            onClick={() => handleToggle()}
          >
            {' '}
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <span
            className={clsx('times', {'active-btn': !isToggled})}
            onClick={() => handleToggle()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        {/* <input type="text" value={text} onChange={handleChange} /> */}
      </div>
      <div className="text-wrapper">
        <div className="auto-grow-input">
          <input
            value={text}
            onChange={handleChange}
            type="text"
            className="web-form-snippet-input transparent-input"
          />
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
};

export default Toggler;
