import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LayoutDashboard from '../layout/LayoutDashboard';
import NotFoundPage from './NotFoundPage';

export const PageNotFound = ({isAuthenticated, loading}) => {
  console.log(isAuthenticated);
  return (
    <>
      {isAuthenticated !== null && (
        <>
          {!isAuthenticated && !loading ? (
            <NotFoundPage />
          ) : (
            <LayoutDashboard>404</LayoutDashboard>
          )}
        </>
      )}
      ;
    </>
  );
};

PageNotFound.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps)(PageNotFound);
