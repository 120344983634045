import React, {useState, useEffect} from 'react';
import LayoutDark from '../layout/LayoutDark';
import {useForm} from 'react-hook-form';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import {resetURL} from '../helpers/Urls';

function ResetPassword({location, history}) {
  const {register, handleSubmit, errors, setError} = useForm();
  const [token, setToken] = useState('');
  const [notification, setNotification] = useState({
    type: 'success',
    message: null,
  });

  const onSubmit = (data) => {
    const {confirmPassword, password} = data;

    if (password === '')
      setError('password', {
        type: 'manual',
        message: 'Enter new password',
      });
    else if (confirmPassword === '')
      setError('confirmPassword', {
        type: 'manual',
        message: 'Confirm new password',
      });
    else {
      console.log(data);
      if (confirmPassword !== password) {
        setError('confirmPassword', {
          type: 'manual',
          message: 'Mismatched password',
        });
      } else {
        axios
          .put(
            resetURL.resetPassword,
            {
              password,
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
              },
            }
          )
          .then(({data}) => {
            history.push('/login');
          })
          .catch((err) => {
            console.log(err.response.data);
            setNotification({
              type: 'error',
              message: err.response.data.message,
            });
          });
      }
    }
  };

  const verifyToken = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (!token) history.push('/login');
    else {
      axios
        .get(resetURL.validateToken + '?token=' + token)
        .then(({data}) => {
          setToken(data.token);
        })
        .catch((err) => {
          console.log(err.response.data);
          history.push('/login');
        });
    }
  };

  const handleNotification = () => {
    if (notification.message) {
      setTimeout(() => {
        setNotification({...notification, message: null});
      }, 5000);
    }
  };

  useEffect(() => {
    handleNotification();
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="login-page">
      <LayoutDark>
        <div className="container">
          <h1 className="page-title">Get back in to GetOnboard.ly</h1>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.password,
                })}
              >
                <label>
                  <span>Password</span>
                  <input ref={register} type="password" name="password" />
                </label>
                {errors.password && (
                  <span className="error-text" role="alert">
                    {errors.password.message}
                    {console.log(errors)}
                  </span>
                )}
              </div>

              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.confirmPassword,
                })}
              >
                <label>
                  <span>Confirm</span>
                  <input
                    ref={register}
                    type="password"
                    name="confirmPassword"
                  />
                </label>
                {errors.confirmPassword && (
                  <span className="error-text" role="alert">
                    {errors.confirmPassword.message}
                    {console.log(errors)}
                  </span>
                )}
              </div>

              <button type="submit" className="btn-purple">
                Reset password
              </button>
            </form>
            {notification.message ? (
              <div
                style={
                  notification.type === 'success'
                    ? {
                        color: 'lightgreen',
                        textAlign: 'center',
                        marginTop: '20px',
                      }
                    : {color: 'red', textAlign: 'center', marginTop: '20px'}
                }
              >
                {notification.message}
              </div>
            ) : (
              ''
            )}
            <div className="form-footer">
              <NavLink to="/login" className="forgot">
                Return to login
              </NavLink>
            </div>
          </div>
        </div>
      </LayoutDark>
    </div>
  );
}

export default ResetPassword;
