import {combineReducers} from 'redux';
import auth from './auth';
import alert from './alert';
import account from './account';
import getstarted from './getstarted';
import actions from './actions';
import toast from './toast';
export default combineReducers({
  auth,
  alert,
  account,
  getstarted,
  toast,
  actions,
});
