import {ADD_TOAST, REMOVE_TOAST} from '../actions/types';

const initialState = [
  {
    id: 1,
    message: 'Hello World',
  },
];

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case ADD_TOAST:
      return [...state, ...payload];
    case REMOVE_TOAST:
      return [payload];
    default:
      return state;
  }
}
