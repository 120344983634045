import React, {useLayoutEffect, useState} from 'react';
import LayoutDashboard from '../layout/LayoutDashboard';
import bannerIcon from '../assets/images/banner-icon.svg';
import BillsbyConnect from '../components/BillsbyConnect';
import BrandingSetup from '../components/BrandingSetup';
import FirstAction from '../components/FirstAction';
import TestItOut from '../components/TestItOut';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {useCookies} from 'react-cookie';
import useQuery from '../utils/useQuery';

// Redux
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  linkAccount,
  fetchGetStartedStatus,
  goLiveAccount,
} from '../actions/getstarted';
import ChargebeeConnect from '../components/ChargebeeConnect';

function GetStarted({
  history,
  token,
  getstarted,
  fetchGetStartedStatus,
  linkAccount,
  proxyMode,
  account: {account},
  accountType,

  goLiveAccount,
}) {
  let query = useQuery();
  const defaultPlanId = query.get('planId');

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showBranding, setShowBranding] = useState(false);
  const [brandingSetupSteps, setBrandingSetupSteps] = useState(1);
  const [showTest, setShowTest] = useState(false);
  const [showFirstAction, setShowFirstAction] = useState(false);
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies([
    `GetOnboardlyTest.${account?.customerUniqueId}`,
  ]);

  const handleConnectAccount = (type, payload) =>
    new Promise((resolve, reject) => {
      let _payload = {...payload, accountType};
      linkAccount(type, _payload)
        .then((response) => {
          if (response === 'DONE') setShowLinkModal(false);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });

  const fetchLinkedAccount = () =>
    fetchGetStartedStatus(token ?? localStorage.token);

  const handleGoLive = () => {
    goLiveAccount(account.customerUniqueId, account.subscriptionUniqueId)
      .then((success) => {
        // NOTE Remove Cookie if live is successful
        setCookie(`GetOnboardlyTest.${account?.customerUniqueId}`, 'true', {
          path: '/',
          maxAge: 0, // Expire now
        });
        console.log('Reload');
        // If success redirect to settings
        history.go(0);
        console.log('Reloaded');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    if (account) fetchLinkedAccount();
    //eslint-disable-next-line
  }, [account]);

  useLayoutEffect(() => {
    if (defaultPlanId) {
      setShowFirstAction(true);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <LayoutDashboard className="page-get-started">
      <section className="banner">
        <h2 className="section-title">Manage Subscription</h2>
        <p>
          The next step is to complete the GetOnboard.ly onboarding checklist
          (very meta, we know) and you'll be able to go live with onboarding
          customers. Don't worry, until you go live , your customers will all
          bypass your onboarding experience
        </p>
        <img className="banner-image" src={bannerIcon} alt="banner" />
      </section>
      <section className="steps">
        <div className="steps__item active-steps ">
          <p className="steps__number">1</p>
          <div className="wrapper">
            <p className="steps__description">
              Link your {accountType} account
            </p>
            <button
              className={clsx(
                {'btn-green': !getstarted.isLinked},
                {'btn-gold btn-unclickable': getstarted.isLinked}
              )}
              onClick={() => {
                if (!getstarted.isLinked) setShowLinkModal(true);
              }}
              // disabled={proxyMode}
            >
              {getstarted.isLinked ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="btn-icon-prefix"
                  />
                  Linked {accountType}
                </>
              ) : (
                'Link Account'
              )}
            </button>
          </div>
        </div>

        <div
          className={clsx('steps__item', {'active-steps': getstarted.isLinked})}
        >
          <p className="steps__number">2</p>
          <div className="wrapper">
            <p className="steps__description">
              Design your GetOnboard.ly page and emails
            </p>
            <button
              className={clsx(
                {'btn-green': !getstarted.isConfiguredBranding},
                {'btn-gold btn-unclickable': getstarted.isConfiguredBranding}
              )}
              onClick={() => {
                setShowBranding(true);
              }}
              // onClick={() => {
              //   if (getstarted.isLinked && !getstarted.isConfiguredBranding)
              //     setShowBranding(true);
              // }}
            >
              {getstarted.isConfiguredBranding ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="btn-icon-prefix"
                  />
                  Configured branding
                </>
              ) : (
                'Configure branding'
              )}
            </button>
          </div>
        </div>

        <div
          className={clsx('steps__item', {
            'active-steps': getstarted.isAddedFirstAction,
          })}
        >
          <p className="steps__number">3</p>
          <div className="wrapper">
            <p className="steps__description">Add your First action</p>
            <button
              className={clsx(
                {'btn-green': !getstarted.isAddedFirstAction},
                {'btn-gold': getstarted.isAddedFirstAction}
                // {'btn-gold btn-unclickable': getstarted.isAddedFirstAction}
              )}
              onClick={() => {
                setShowFirstAction(true);
              }}
            >
              {getstarted.isAddedFirstAction ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="btn-icon-prefix"
                  />
                  Added first action
                </>
              ) : (
                'Add first action'
              )}
            </button>
          </div>
        </div>

        <div
          className={clsx('steps__item', {
            // NOTE: temporarily true
            'active-steps':
              getstarted.isConfiguredBranding && getstarted.isAddedFirstAction,
          })}
        >
          <p className="steps__number">4</p>
          <div className="wrapper">
            <p className="steps__description">Test everything is working</p>
            <button
              className={clsx(
                {'btn-green': !getstarted.isTestedOut},
                {'btn-gold btn-unclickable': getstarted.isTestedOut}
              )}
              onClick={() => {
                if (getstarted.isConfiguredBranding) setShowTest(true);
              }}
            >
              {getstarted.isTestedOut ? (
                <>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="btn-icon-prefix"
                  />
                  Tested Out
                </>
              ) : (
                'Test it out'
              )}
            </button>
          </div>
        </div>
      </section>

      <button
        className={clsx('btn-purple', {
          'btn-disabled': !getstarted.isAddedFirstAction,
        })}
        // disabled={proxyMode}
        onClick={handleGoLive}
      >
        Go Live with Onboard.ly
      </button>

      <section>
        <h2 className="section-title">Getting help along the way</h2>
        <p>
          As you complete each stage of the onboarding checklist, we'll provide
          help and support in the application. If you still need some more
          assistancem our help documentation technical team is available in the
          lower right hand corner ov every screen.
        </p>
      </section>

      {accountType === 'Billsby' && (
        <BillsbyConnect
          show={showLinkModal}
          handleClose={() => setShowLinkModal(false)}
          linkedAccount={getstarted.linkedAccount}
          customerUniqueId={account?.customerUniqueId}
          handleConnectAccount={handleConnectAccount}
          fetchLinkedAccount={fetchLinkedAccount}
          proxyMode={proxyMode}
        />
      )}
      {accountType === 'Chargebee' && (
        <ChargebeeConnect
          show={showLinkModal}
          handleClose={() => setShowLinkModal(false)}
          linkedAccount={getstarted.linkedAccount}
          customerUniqueId={account?.customerUniqueId}
          handleConnectAccount={handleConnectAccount}
          fetchLinkedAccount={fetchLinkedAccount}
          proxyMode={proxyMode}
        />
      )}

      <BrandingSetup
        show={showBranding}
        brandingSetupSteps={brandingSetupSteps}
        setBrandingSetupSteps={setBrandingSetupSteps}
        handleClose={() => setShowBranding(false)}
        fetchLinkedAccount={fetchLinkedAccount}
        // linkedAccount={getstarted.linkedAccount}
        // proxyMode={proxyMode}
      />
      <FirstAction
        show={showFirstAction}
        handleClose={() => setShowFirstAction(false)}
        fetchLinkedAccount={fetchLinkedAccount}
      />
      <TestItOut
        show={showTest}
        cid={account?.customerUniqueId ?? null}
        handleClose={() => setShowTest(false)}
      />
    </LayoutDashboard>
  );
}

GetStarted.propTypes = {
  getstarted: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  proxyMode: PropTypes.bool,
  accountType: PropTypes.string,
};

const mapStateToProps = (state) => ({
  getstarted: state.getstarted,
  account: state.account,
  proxyMode: state.account.proxyMode,
  token: state.account.proxyMode ? state.account.proxyToken : state.auth.token,
  accountType: state?.account?.account?.accountType,
});

export default connect(mapStateToProps, {
  fetchGetStartedStatus,
  linkAccount,
  goLiveAccount,
})(GetStarted);
