import React, {useEffect, useState} from 'react';
import siteLogoGreen from '../assets/images/getonboard.ly-green@2x.png';
import siteLogoWhite from '../assets/images/getonboard.ly-white@2x.png';
import {useForm} from 'react-hook-form';
import clsx from 'clsx';
import BeatLoader from 'react-spinners/BeatLoader';

import axios from 'axios';

import {billsbyURL, registerURL} from '../helpers/Urls';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../actions/auth';
import Toggler from '../components/Toggler';

function Register({location, history, isAuthenticated}) {
  const {register, handleSubmit, errors, setError} = useForm();
  const [wantsNewsLetter, setWantsNewsLetter] = useState(true);
  const [customerName, setCustomerName] = useState('');
  const [payload, setPayload] = useState({
    customerUniqueId: null,
    subscriptionUniqueId: null,
    password: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDuplicateEmail, setIsDuplicateEmail] = useState(false);
  const [notification, setNotification] = useState({
    status: 'success',
    message: '',
  });
  const [requestLoading, setRequestLoading] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    const {confirmPassword, password} = data;
    if (password === '')
      return setError('password', {
        type: 'manual',
        message: 'Enter Password',
      });
    if (confirmPassword === '')
      return setError('confirmPassword', {
        type: 'manual',
        message: 'Confirm password',
      });

    if (confirmPassword !== password)
      return setError('confirmPassword', {
        type: 'manual',
        message: 'Mismatched password',
      });

    // Send register request
    console.log('Send Register Request');
    let _payload = {
      ...payload,
      password,
      marketingConsent: wantsNewsLetter,
    };
    setPayload(_payload);

    setRequestLoading(true);

    axios
      .post(registerURL.registerUser, _payload)
      .then((result) => {
        // Redirect after 3 seconds to Login page.
        // setIsLoading(false);
        history.push('/login');
      })
      .catch((err) => {
        console.log(err?.response?.data);
        if (err?.response?.data?.message) {
          setError('password', {
            type: 'manual',
            message: err.response.data.message,
          });

          setNotification({
            status: 'error',
            message: err.response.data.message,
          });
        }
      });
  };

  if (isAuthenticated) {
    history.push('/');
  }

  const verifyRegistration = async () => {
    const params = new URLSearchParams(location.search);
    const customerUniqueId = params.get('cid');
    const subscriptionUniqueId = params.get('sid');

    if (!customerUniqueId && !subscriptionUniqueId) history.push('/login');

    await axios
      .get(billsbyURL.customerDetails + '/' + customerUniqueId)
      .then(async ({data}) => {
        // Set Data
        setCustomerName(data.firstName);

        // Check email if already exists in DB
        await axios
          .get(
            registerURL.verifyEmail +
              '/' +
              data.email +
              `?cid=${customerUniqueId}&sid=${subscriptionUniqueId}`
          )
          .then(({data}) => {
            if (data.result === 'exists') {
              // Render Duplicate Email Address Notification
              setIsDuplicateEmail(true);
              setIsLoading(false);
            } else {
              setPayload({...payload, customerUniqueId, subscriptionUniqueId});
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err.response.data);
            history.push('/login');
          });
      })
      .catch((err) => {
        console.log(err.response.data);
        history.push('/login');
      });
  };

  useEffect(() => {
    if (location.search !== '') verifyRegistration();
    else history.push('/login');

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!isLoading && (
        <div className="register-page">
          <div
            className={clsx('banner', {'duplicate-email': isDuplicateEmail})}
          >
            <div className="container">
              <img
                src={siteLogoGreen}
                className="banner__logo"
                alt="banner-logo"
              />
              <h1 className="banner__title">
                Welcome to GetOnboard.ly {customerName}. Let’s finish
                configuring your account.
              </h1>

              <p className="banner__description">
                Once you’ve configured GetOnboard.ly, your customers will be
                directed to a branded account activation screen just like this
                one to collect the details you need from them. But for right
                now, here’s what we need from you.
              </p>
            </div>
          </div>
          <div
            className={clsx('content-wrapper', {
              'duplicate-email': isDuplicateEmail,
            })}
          >
            {isDuplicateEmail ? (
              <div className="container">
                <h2 className="title-2">Duplicate email address</h2>
                <p>
                  It looks like you signed up using an email address that’s
                  already associated with another GetOnboard.ly account. Don’t
                  worry, we’ve cancelled the new subscription you setup today so
                  you won’t be charged and you can <a href="/login">login</a> to
                  your account on our website to continue using GetOnboard.ly.
                </p>
              </div>
            ) : (
              <div className="container">
                <h2 className="title-2">Your GetOnboard.ly account password</h2>
                <p>
                  You’ll use this password to login to and access your
                  GetOnboard.ly account.
                </p>

                <div className="form-wrapper">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className={clsx('input-wrapper', {
                        'input-error': errors.password,
                      })}
                    >
                      <label>
                        <span>Password</span>
                        <input ref={register} type="password" name="password" />
                      </label>
                      {errors.password && (
                        <span className="error-text" role="alert">
                          {errors.password.message}
                          {console.log(errors)}
                        </span>
                      )}
                    </div>

                    <div
                      className={clsx('input-wrapper', {
                        'input-error': errors.confirmPassword,
                      })}
                    >
                      <label>
                        <span>Confirm password</span>
                        <input
                          ref={register}
                          type="password"
                          name="confirmPassword"
                        />
                      </label>
                      {errors.confirmPassword && (
                        <span className="error-text" role="alert">
                          {errors?.confirmPassword?.message}
                          {console.log(errors)}
                        </span>
                      )}
                    </div>

                    <h2 className="title-2 space-top">
                      Your marketing preferences
                    </h2>
                    <p>
                      We’d love to keep in touch with you. How can we tell you
                      the latest news about your subscription?
                    </p>
                    {console.log('wants', wantsNewsLetter)}

                    <Toggler
                      title="Email"
                      setIsToggled={setWantsNewsLetter}
                      isToggled={wantsNewsLetter}
                    />

                    <button className="btn-purple">
                      {requestLoading ? (
                        <BeatLoader loading={requestLoading} color="white" />
                      ) : (
                        ' Activate my subscription'
                      )}
                    </button>
                  </form>

                  {notification.message !== '' ? (
                    <div
                      className={
                        'notification notification-' + notification.status
                      }
                    >
                      {notification.message}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </div>

          <div
            className={clsx('subscription-wrapper', {
              'duplicate-email': isDuplicateEmail,
            })}
          >
            <div className="container">
              <p>Subscription provisioned by</p>
              <img src={siteLogoWhite} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Register.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {login})(Register);
