import axios from 'axios';

import {user} from '../helpers/Urls';
import {getCurrentAccount, loadProxyUser} from './account';

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UNLINK_ACCOUNT,
  CLEAR_ACCOUNT,
} from './types';

import setAuthToken from '../utils/setAuthToken';
import {reRenderTrengo} from '../lib/trengo';

//load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
    try {
      const res = await axios.get(user.validate);
      const {customerUniqueId, subscriptionUniqueId} = res?.data?.user;

      dispatch({
        type: USER_LOADED,
        payload: {user: res?.data?.user, isAdmin: res?.data?.user?.isAdmin},
      });

      if (localStorage.proxyMode === 'true') {
        dispatch(loadProxyUser(localStorage.proxyEmail));
      } else {
        dispatch(getCurrentAccount(customerUniqueId, subscriptionUniqueId));
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: AUTH_ERROR,
      });
    }
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

//Login User
export const login = (email, password) => async (dispatch) => {
  localStorage.clear();
  console.log('%cLOGIN INITIALIZED', 'color:orange;font-size:20px');
  const body = {
    email,
    password,
  };

  try {
    const res = await axios.post(user.login, body);
    const {data: token} = res;

    console.log('%c LOGIN SUCCESS', 'color:green;font-size:20px');

    dispatch({
      type: LOGIN_SUCCESS,
      payload: token,
    });

    dispatch(loadUser());
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

//Logout User
export const logout = () => async (dispatch) => {
  localStorage.clear();
  reRenderTrengo();

  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: UNLINK_ACCOUNT,
  });
  dispatch({
    type: CLEAR_ACCOUNT,
  });
};
