import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

import Modal from './shared/Modal';

const TestItOut = ({cid, show, handleClose}) => {
  const [cookies, setCookie] = useCookies([`GetOnboardlyTest.${cid}`]);
  const [isToggled, setIsToggled] = useState(false);
  const onToggle = () => setIsToggled(!isToggled);

  const saveCookieSettings = () => {
    console.log('toggled', isToggled);
    if (isToggled) {
      setCookie(`GetOnboardlyTest.${cid}`, 'true', {
        path: '/',
        maxAge: 86400, // Expires after 24 hours
      });
    } else {
      setCookie(`GetOnboardlyTest.${cid}`, 'true', {
        path: '/',
        maxAge: 0, // Expire now
      });
    }
    handleClose();
  };

  useEffect(() => {
    if (cookies[`GetOnboardlyTest.${cid}`]) setIsToggled(true);
    //eslint-disable-next-line
  }, [show]);

  return (
    <Modal
      show={show}
      title="Test everything is working!"
      handleClose={handleClose}
    >
      <div className="modal-body">
        <p>
          Finally, we need to test everything is working. When you turn on test
          mode, every time you doa new registration to your website through{' '}
          <span className="text-bold">this web browser</span>, we'll show you
          Getonboard.ly and the registration flow will work as it will once you
          go live. You'll need to make sure cookies are on, and you'll want to
          flick the switch button once you're done testing. This is it - the
          final stage!
        </p>
      </div>
      <div className="switch-wrapper">
        <label className="toggle-switch">
          <input type="checkbox" checked={isToggled} onChange={onToggle} />
          <span className="switch" />
        </label>
      </div>

      <button className="btn-purple" onClick={saveCookieSettings}>
        Update testing setting
      </button>
    </Modal>
  );
};

export default TestItOut;
