import React, {useEffect, useState} from 'react';
import LayoutDark from '../layout/LayoutDark';
import {useForm} from 'react-hook-form';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import axios from 'axios';
import {resetURL} from '../helpers/Urls';

function Forgot(props) {
  const {register, handleSubmit, errors} = useForm();
  const [notification, setNotification] = useState({
    type: 'success',
    message: null,
  });

  const onSubmit = (data) => {
    console.log(data);
    axios
      .get(resetURL.sendMail + '?email=' + data.email)
      .then(({data}) => {
        console.log(data);
        setNotification({type: 'success', message: data});
      })
      .catch((err) => {
        console.log(err.response.data);
        setNotification({type: 'error', message: err.response.data});
      });
  };

  const handleNotification = () => {
    if (notification.message) {
      setTimeout(() => {
        setNotification({...notification, message: null});
      }, 5000);
    }
  };

  useEffect(() => {
    handleNotification();
    // eslint-disable-next-line
  }, [notification]);

  return (
    <div className="login-page">
      <LayoutDark>
        <div className="container">
          <h1 className="page-title">Login to GetOnboard.ly</h1>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={clsx('input-wrapper', {'input-error': errors.email})}
              >
                <label>
                  <span>Email address</span>
                  <input
                    name="email"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                </label>
                {errors.email && (
                  <span className="error-text" role="alert">
                    {errors.email.message}
                  </span>
                )}
              </div>

              <button type="submit" className="btn-purple">
                Send password reset email
              </button>
            </form>

            {notification.message ? (
              <div
                style={
                  notification.type === 'success'
                    ? {
                        color: 'lightgreen',
                        textAlign: 'center',
                        marginTop: '20px',
                      }
                    : {color: 'red', textAlign: 'center', marginTop: '20px'}
                }
              >
                {notification.message}
              </div>
            ) : (
              ''
            )}

            <div className="form-footer">
              <NavLink to="/login" className="forgot">
                Return to login
              </NavLink>
            </div>
          </div>
        </div>
      </LayoutDark>
    </div>
  );
}

export default Forgot;
