import {
  ACCOUNT_ERROR,
  CLEAR_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNTS,
  GET_PROXY_ACCOUNT,
  LOGOUT,
} from './types';
import {user, customerURL} from '../helpers/Urls';
import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import {signInTrengo} from '../lib/trengo';

//get current account profile
export const getCurrentAccount =
  (customerUniqueId, subscriptionUniqueId) => async (dispatch) => {
    try {
      // get profile
      //get initiated from loadUser()
      const account = await axios.get(user.profile);
      const subscription = await axios.get(
        `${user.subscription}/${subscriptionUniqueId}`
      );

      // run Trengo insert

      signInTrengo(
        account.data.email,
        `${account.data.firstName} ${account.data.lastName}`
      );

      dispatch({
        type: GET_ACCOUNT,
        payload: {
          customerUniqueId,
          subscriptionUniqueId,
          ...account.data,
          subscription: subscription.data,
        },
      });
    } catch (error) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: error.response,
      });
    }
  };

export const loadProxyUser = (email) => async (dispatch) => {
  try {
    //get proxy account by admin token
    const account = await axios.post(
      `${user.proxyAccount}`,
      {
        email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );

    //get proxy's subscription via proxy token
    const subscription = await axios.get(
      `${user.subscription}/${account?.data?.user?.subscriptionUniqueId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${account?.data?.token}`,
        },
      }
    );

    if (localStorage.proxyToken) {
      setAuthToken(localStorage.proxyToken);
    } else {
      setAuthToken(account?.data?.token);
    }

    dispatch({
      type: GET_PROXY_ACCOUNT,
      payload: {
        account: {...account.data?.user, subscription: subscription?.data},
        proxyEmail: email,
        proxyToken: account?.data?.token,
      },
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });
  }

  //set auth to proxyToken here
};

export const clearAccount = () => (dispatch) => {
  setAuthToken(localStorage.token);
  dispatch({
    type: CLEAR_ACCOUNT,
  });
};

//can be removedonce paged request is done
export const getAllAccount = () => async (dispatch) => {
  try {
    const res = await axios.get(customerURL.allCustomer);
    dispatch({
      type: GET_ACCOUNTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_ERROR,
      payload: error,
    });
  }
};

export const getPagedAccount =
  (page = 1, perPage = 10, order = 'desc', search) =>
  async (dispatch) => {
    try {
      const res = await axios.get(customerURL.paginated, {
        params: {
          page,
          perPage,
          order,
          search,
        },
      });
      console.log('paginated request', res.data);
      dispatch({
        type: GET_ACCOUNTS,
        payload: res.data,
      });
    } catch (error) {
      console.log('error', error);
      dispatch({
        type: ACCOUNT_ERROR,
        payload: error,
      });
    }
  };

//cancel account
export const cancelAccount =
  (customerUniqueId, subscriptionUniqueId) => async (dispatch) => {
    try {
      await axios
        .delete(
          `${user.cancelAccount}/${customerUniqueId}/${subscriptionUniqueId}`
        )
        .then((result) => {
          console.log(result);
          // Log out after cancelling account
          dispatch({
            type: LOGOUT,
          });
        })
        .catch((err) => {
          dispatch({
            type: ACCOUNT_ERROR,
            payload: err.response.data,
          });
        });
    } catch (error) {
      dispatch({
        type: ACCOUNT_ERROR,
        payload: error.response,
      });
    }
  };
