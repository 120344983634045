import React from 'react';
import navigationImage from '../assets/images/preview-head.png';
import CustomerAction from '../components/CustomerAction';
import {useForm} from 'react-hook-form';

const PreviewOnboarding = ({styling, content, actions = []}) => {
  const headerFontStyle = {
    color: styling.header.color,
    fontFamily: styling.headerFont,
  };

  const bodyStyle = {
    background: styling.body.bg,
    color: styling.body.color,
    fontFamily: styling.bodyFont,
  };
  const {register, handleSubmit, errors} = useForm();
  const onSubmit = (data) => {};

  return (
    <div className="preview-onboarding">
      <div className="img-header">
        <img src={navigationImage} alt="" />
      </div>
      <div
        className="customer-header"
        style={{background: styling?.header?.bg}}
      >
        <div className="customer-header__wrapper">
          <img
            src={content.onboardingLogo}
            alt=""
            className="customer-header__logo"
          />

          <h1 className="customer-header__title" style={headerFontStyle}>
            Welcome to {content.companyName} {content.customerName} lets finish
            your account
          </h1>

          <p
            className="customer-header__title-description"
            style={headerFontStyle}
          >
            {content.explainerText}
          </p>
        </div>
      </div>

      <div className="customer-body" style={bodyStyle}>
        <div className="form-wrapper v2">
          <form onSubmit={handleSubmit(onSubmit)}>
            {actions.map(
              (
                {name, placeholder, defaulValue, title, description, label},
                index
              ) => (
                <CustomerAction
                  name={name}
                  placeholder={placeholder}
                  defaulValue={defaulValue}
                  register={register({required: true})}
                  errors={errors}
                  title={title}
                  description={description}
                  label={label}
                  key={index}
                />
              )
            )}

            <div className="button-wrapper">
              <button
                type="submit"
                className="btn"
                disabled
                style={{
                  background: styling.button.bg,
                  color: styling.button.color,
                  font: styling.bodyFont,
                }}
              >
                {content.buttonText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PreviewOnboarding;
