import React, {useEffect, useState} from 'react';
import LayoutDashboard from '../../layout/LayoutDashboard';
import Breadcrumbs from '../../components/Breadcrumbs';
import {actions} from '../../helpers/Urls';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {errorLog} from '../../helpers/Logger';

const SelectPlan = () => {
  var {id} = useParams();
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${actions.getActions}/${id}`)
      .then(({data}) => {
        setPlan(data);
        setLoading(false);
      })
      .catch((error) => {
        errorLog('Error fething single plan', error.response);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <LayoutDashboard pageLoading={loading} className="actions">
      <Breadcrumbs link="/actions" text="Actions" />
      <section className="banner">
        <h2 className="section-title">Pick an action for {plan.plan_name}</h2>
        <p>
          Each plan can have unlimited actions. Which action do you need for{' '}
          {plan.plan_name}?
        </p>
      </section>
      <div className="options">
        {plan?.available_actions?.map(({action_name, action_code}) => (
          <Link
            to={`/actions/${plan.plan_id}/${action_code}`}
            key={action_code}
            className="options__link"
          >
            <div className="options__card">
              <p>{action_name}</p>
            </div>
          </Link>
        ))}
      </div>
    </LayoutDashboard>
  );
};

export default SelectPlan;
