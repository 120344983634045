import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import axios from 'axios';
import {actions} from '../../../helpers/Urls';
import {useForm} from 'react-hook-form';
import PuffLoader from 'react-spinners/PuffLoader';
import {toast} from 'react-toastify';

import {
  faDoorOpen,
  faPencilAlt,
  faBan,
  faCheck,
  faTimes,
  faEnvelopeOpenText,
  faThList,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../../../components/Buttons';
import Toggler from '../../../components/Toggler';
import {requestLog} from '../../../helpers/Logger';
import {Actions} from '../../../lib/Actions';
import {getActionConfig} from '../../../helpers/HelperFunctions';

import TabHeader from '../../../components/actions/newsletters/TabHeader';
import Groupings from '../../../components/actions/newsletters/Groupings';
import Banner from '../../../components/actions/newsletters/Banner';

const MailerLightActionForm = ({
  type,
  onSubmit = () => null,
  setLoading = () => null,

  planId,
}) => {
  const {register, handleSubmit, errors, watch, setError} = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const [brandingConfig, setBrandingConfig] = useState({});
  const [planName, setPlanName] = useState('');

  const [apiChecking, setApiChecking] = useState(false);
  const [isApiValid, setIsApiValid] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [apiKeyValue, setApiKeyValue] = useState('');

  const apiKey = watch('apiKey');

  const [signUpAddToGroup, setSignUpAddToGroup] = useState([]);
  const [signUpAddToGroupOption, setSignUpAddToGroupOption] = useState([]);
  const [signUpRemoveFromGroup, setSignUpRemoveFromGroup] = useState(true);

  const [cancelAddToGroup, setCancelAddToGroup] = useState([]);
  const [cancelAddToGroupOption, setCancelAddToGroupOption] = useState([]);
  const [cancelRemoveFromGroup, setCancelRemoveFromGroup] = useState(true);
  const [cancelAction, setCancelAction] = useState(true);

  const [marketingConsent, setMarketingConsent] = useState(true);
  const [syncAction, setSyncAction] = useState(true);
  const [webFormSnippet, setWebFormSnippet] = useState(true);
  const [wfSnippetText, setWFSnippetText] = useState(
    'Get email updates about your subscription'
  );
  const [emailSnippet, setEmailSnippet] = useState(true);
  const [emailSnippetText, setEmailSnippetText] = useState(
    'You’ll get email updates about your subscription'
  );

  useEffect(() => {
    setApiChecking(true);
    const delayDebounceFn = setTimeout(() => {
      if (apiKey === '') {
        return;
      }
      axios
        .get(actions.mailerlite, {
          params: {
            apiKey,
          },
        })
        .then(({data}) => {
          setIsApiValid(true);
          setApiChecking(false);

          const mailCategories = data.map(({id, name}) => ({
            value: id,
            label: name,
          }));
          setSignUpAddToGroupOption(mailCategories);
          setCancelAddToGroupOption(mailCategories);
        })
        .catch((error) => {
          setIsApiValid(false);
          setApiChecking(false);
        });
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    //eslint-disable-next-line
  }, [apiKey]);

  const saveConfig = async (data) => {
    setSubmitLoading(true);
    requestLog('Saving...', data);

    if (signUpAddToGroup.length === 0) {
      setSubmitLoading(false);
      return toast.error('Sign up group is empty', {});
    }

    if (!isApiValid) {
      setSubmitLoading(false);
      return setError('apiKey', {
        type: 'manual',
        message: 'Api key not valid',
      });
    }

    const action = new Actions({
      planId,
      planName,
      actionCode: 'mailerlite',
      actionName: 'Mailerlite',
      actionConfig: {
        api_key: apiKey,
        signup_actions: {
          add_to_group: signUpAddToGroup,
          remove_from_group: signUpRemoveFromGroup,
          marketing_consent: marketingConsent,
        },
        sync_action: syncAction,
        cancel_actions: {
          toggle: cancelAction,
          add_to_group: cancelAddToGroup,
          remove_from_group: cancelRemoveFromGroup,
        },
        web_form_snippet_text: wfSnippetText,
        email_snippet_text: emailSnippetText,
      },
    });

    await action.save(isUpdateMode);
    setSubmitLoading(false);
    onSubmit(true);

    // NOTE: Test mode 🧪
    // console.log('Payload test mode', {
    //   planId,
    //   planName,
    //   actionCode: 'mailerlite',
    //   actionName: 'Mailerlite',
    //   actionConfig: {
    //     api_key: apiKey,
    //     signup_actions: {
    //       add_to_group: signUpAddToGroup,
    //       remove_from_group: signUpRemoveFromGroup,
    //       marketing_consent: marketingConsent,
    //     },
    //     sync_action: syncAction,
    //     cancel_actions: {
    //       toggle: cancelAction,
    //       add_to_group: cancelAddToGroup,
    //       remove_from_group: cancelRemoveFromGroup,
    //     },
    //     web_form_snippet_text: wfSnippetText,
    //     email_snippet_text: emailSnippetText,
    //   },
    // });
    // onSubmit(true);
    // NOTE: Test mode END 🧪
  };

  const handleSignUpAddToGroupOnChange = (value) => setSignUpAddToGroup(value);

  const handleCancelAddToGroupOnChange = (value) => setCancelAddToGroup(value);

  const syncData = ({action_config}) => {
    setApiKeyValue(action_config.api_key);
    setSignUpAddToGroup(action_config.signup_actions.add_to_group);
    setSignUpRemoveFromGroup(action_config.signup_actions.remove_from_group);
    setMarketingConsent(action_config.signup_actions.marketing_consent);
    setSyncAction(action_config.sync_action);
    setCancelAction(action_config.cancel_actions.toggle);
    setCancelAddToGroup(action_config.cancel_actions.add_to_group);
    setCancelRemoveFromGroup(action_config.cancel_actions.remove_from_group);
    // setWebFormSnippet(action_config.web_form_snippet);
    setWFSnippetText(action_config.web_form_snippet_text);
    // setEmailSnippet(action_config.email_snippet);
    setEmailSnippetText(action_config.email_snippet_text);
    setIsUpdateMode(true);
  };

  const fetchInitialConfig = () => {
    setLoading(true);
    getActionConfig(planId, 'mailerlite')
      .then(([plan, action, brandings]) => {
        setPlanName(plan.data.plan_name);
        if (action.data) {
          if (action.data.action_config) {
            syncData(action.data);
          }
        }
        let branding = brandings.data;
        branding.color_scheme = JSON.parse(branding.color_scheme);
        setBrandingConfig(branding);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err?.response?.data);
      });
  };

  useEffect(() => {
    fetchInitialConfig();
    // eslint-disable-next-line
  }, []);

  const colordiv = () => ({
    background: brandingConfig?.color_scheme?.body?.bg,
    color: brandingConfig?.color_scheme?.body?.color,
    fontFamily: brandingConfig?.body_font,
  });

  return (
    <>
      <Banner title="MailerLite" />
      <form onSubmit={handleSubmit(saveConfig)}>
        <div className="form-wrapper v2">
          <section className="mailerlite-api-key">
            <h2 className="section-title">MailerLite API key</h2>
            <p>
              Find your API key at{' '}
              <span className="text-bold">
                Account {'>'} Extras {'>'} API Keys
              </span>
            </p>
            <div
              className={clsx('input-wrapper', 'with-icon', {
                'input-error': errors.apiKey,
              })}
            >
              <label>
                <span>API key</span>
                <input
                  name="apiKey"
                  placeholder="************"
                  defaultValue={apiKeyValue}
                  ref={register({
                    required: 'Required',
                  })}
                />
              </label>

              <span className="checker-icon">
                {apiChecking ? (
                  <PuffLoader loading={apiChecking} color="#4ea699" />
                ) : isApiValid ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <FontAwesomeIcon icon={faTimes} />
                )}
              </span>
            </div>
          </section>

          <TabHeader icon={faDoorOpen} name="Sign up action" />

          <Groupings
            description={`On sign up, we’ll add the customer to the MailerLite audience group you specify:`}
            options={signUpAddToGroupOption}
            value={signUpAddToGroup}
            onChange={handleSignUpAddToGroupOnChange}
            removeToggleHandle={setSignUpRemoveFromGroup}
            removeToggleValue={signUpRemoveFromGroup}
          >
            <Toggler
              title="Collect marketing consent"
              setIsToggled={setMarketingConsent}
              isToggled={marketingConsent}
            />
          </Groupings>

          <TabHeader
            icon={faPencilAlt}
            name="Sync action"
            toggleAction={setSyncAction}
            isToggled={syncAction}
          />

          <section>
            <p>
              If your clients name or email address changes in your subscription
              billing platform, we’ll change it in mailerlite.
            </p>
          </section>

          <TabHeader
            icon={faBan}
            name="Cancel action"
            toggleAction={setCancelAction}
            isToggled={cancelAction}
          />

          <Groupings
            description={` If the subscription is cancelled, we’ll remove the customer from
              the group you chose above. You can also add them to a different
              group. We’ll only take action if they provided marketing consent
              in the sign up action, unless you chose not to collect marketing
              consent. When customers switch plans, we first process a cancel
              action on their old plan, and then process a sign up action on
              their new plan.`}
            options={cancelAddToGroupOption}
            value={cancelAddToGroup}
            onChange={handleCancelAddToGroupOnChange}
            removeToggleHandle={setCancelRemoveFromGroup}
            removeToggleValue={cancelRemoveFromGroup}
          />

          {marketingConsent && (
            <>
              <TabHeader icon={faThList} name="Web form snippet" />

              <div className="colorize-div" style={colordiv()}>
                <Toggler
                  setIsToggled={setWebFormSnippet}
                  isToggled={webFormSnippet}
                />
                <div className="text-wrapper">
                  <div className="auto-grow-input">
                    <input
                      value={wfSnippetText}
                      onChange={(e) => setWFSnippetText(e.target.value)}
                      type="text"
                      className="web-form-snippet-input transparent-input"
                    />
                    <span>{wfSnippetText}</span>
                  </div>
                </div>
              </div>
            </>
          )}

          <TabHeader
            icon={faEnvelopeOpenText}
            name="Email snippet"
            toggleAction={setEmailSnippet}
            isToggled={emailSnippet}
          />

          <div className="colorize-div" style={colordiv()}>
            <div className="text-wrapper">
              <FontAwesomeIcon icon={faCheck} />
              <div className="auto-grow-input">
                <input
                  value={emailSnippetText}
                  onChange={(e) => setEmailSnippetText(e.target.value)}
                  type="text"
                  className="web-form-snippet-input transparent-input"
                />
                <span>{emailSnippetText}</span>
              </div>
            </div>
          </div>
          <Button
            disabled={!isApiValid}
            className="btn-purple"
            loading={submitLoading}
            text={
              (isUpdateMode ? `Update` : `Activate`) +
              ` MailerLite action for ${planName}`
            }
          />
        </div>
      </form>
    </>
  );
};

export default MailerLightActionForm;
