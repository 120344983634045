import React, {useState} from 'react';
import ColorPicker from '../ColorPicker';
import UploadImage from '../UploadImage';

import clsx from 'clsx';
import {settings} from '../../helpers/Urls';
import {fontOptions} from '../../helpers/StaticVariables';

import axios from 'axios';
import Button from '../../components/Buttons';
import {toast} from 'react-toastify';

import {useForm} from 'react-hook-form';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import {requestLog, successLog, errorLog} from '../../helpers/Logger';

const ConfigureEmail = ({
  content,
  styling,
  setStyling,
  setFiles,
  setIsUploaded,
  isUploaded,
  setContent,
  files,
}) => {
  const {register, handleSubmit, errors} = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = () => {
    setSubmitLoading(true);
    requestLog(
      'Request Update...',
      files.length > 0 && files[0].size ? files[0] : ''
    );

    const fd = new FormData();
    fd.append('fromName', content.fromName);
    fd.append('replyToAddress', content.replyToAddress);

    fd.append('font', styling.font);

    fd.append(
      'colorScheme',
      JSON.stringify({
        header: styling.header,
        body: styling.body,
        button: styling.button,
      })
    );

    if (content.emailLogo !== '')
      fd.append('logo', files.length > 0 && files[0].size ? files[0] : '');

    axios
      .put(settings.updateEmail, fd)
      .then(({data}) => {
        successLog('Onboarding succesffuly updated', data);
        setSubmitLoading(false);
        toast.success('Settings saved succesfully', {});
      })
      .catch((err) => {
        errorLog('Save Branding Error', err.response.data);
        setSubmitLoading(false);
        toast.error('Something went wrong');
      });
  };

  return (
    <div className="email">
      <div className="form-wrapper v2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="duo-wrapper">
            <div className="left">
              {' '}
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.fromName,
                })}
              >
                <label>
                  <span>From Name</span>
                  <input
                    onChange={(e) =>
                      setContent({
                        ...content,
                        fromName: e.target.value,
                      })
                    }
                    name="fromName"
                    defaultValue={content?.fromName}
                    ref={register({
                      required: 'Required',
                    })}
                    placeholder={`Burt's Bee Keeping`}
                  />
                </label>
              </div>
              <div className={clsx('input-wrapper')}>
                <label>
                  <span>Font</span>
                  <Dropdown
                    options={fontOptions}
                    onChange={(font) =>
                      setStyling({
                        ...styling,
                        font: font.value,
                      })
                    }
                    value={styling.font}
                    placeholder="Select an option"
                  />
                </label>
              </div>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.bodyFont,
                })}
              >
                <div className="button-wrapper">
                  <span>Logo</span>
                  <UploadImage
                    ImgPreviewUrl={(preview) =>
                      setContent({
                        ...content,
                        emailLogo: preview,
                      })
                    }
                    isUploaded={isUploaded}
                    onImageSelect={(file) => setFiles(file)}
                    setIsUploaded={(isImageUploaded) =>
                      setIsUploaded(isImageUploaded)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="right">
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.explainerText,
                })}
              >
                <label>
                  <span>Reply-To address</span>
                  <input
                    name="replyToAddress"
                    defaultValue={content?.replyToAddress}
                    placeholder={`support@burtsbeekiiping.com`}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        replyToAddress: e.target.value,
                      })
                    }
                    ref={register({
                      required: 'Required',
                    })}
                  />
                </label>
              </div>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.bodyFont,
                })}
              >
                <div className="button-wrapper">
                  <span>Color Scheme</span>
                  <div className="palette-wrapper">
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.header?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              header: {
                                ...styling.header,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.header?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              header: {
                                ...styling.header,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Header</p>
                    </div>
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.body?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              body: {
                                ...styling.body,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.body?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              body: {
                                ...styling.body,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Body</p>
                    </div>
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.button?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              button: {
                                ...styling.button,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.button?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              button: {
                                ...styling.button,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Buttons</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Button
            className="btn-purple"
            loading={submitLoading}
            text="Update Email settings"
          />
        </form>
      </div>
    </div>
  );
};

export default ConfigureEmail;
