import React, {useEffect} from 'react';
import LayoutDashboard from '../layout/LayoutDashboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';
import ActionList from '../components/actions/ActionList';
import {useDispatch, useSelector} from 'react-redux';
import {getActions} from '../actions/actions';

function Actions(props) {
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.actions.plans);
  const account = useSelector((state) => state.account); // to make sure token is set for proxy or user
  const loading = useSelector((state) => state.actions.loading);

  useEffect(() => {
    dispatch(getActions());
  }, [dispatch]);

  return (
    <LayoutDashboard pageLoading={loading} className="actions">
      <section className="banner">
        <h2 className="section-title">Actions</h2>
        <p>
          Each of your plans trigger across distinct products and services. When
          you create a new plan, you'll need to come back here to setup the
          actions for it. Each action is distict - so changing the Mailchimp
          action for one plan won't change it for another
        </p>
      </section>

      {/* TODO: loop plans */}
      {plans.map((plan, i) => (
        <div key={i} className="content-wrapper">
          <div className="tab-header">
            <div className="tab-header__icon">
              <FontAwesomeIcon icon={faDollarSign} />
            </div>
            <div className="tab-header__name">
              {' '}
              {`${plan.plan_name}  (${plan.plan_id})`}
            </div>
            {/* NOTE: Show only if Mailchimp has 'Collect marketing consent' ON */}
            {plan?.actions[0]?.action_config?.signup_actions
              ?.marketing_consent ? (
              <div className="tab-header__btn">
                <Link
                  to={`/actions/edit-web-form/${plan.plan_id}`}
                  className="btn-white"
                >
                  Edit web form
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>

          <section className="plan-container no-bg">
            <ActionList
              actionList={plan.actions}
              planID={plan.plan_id}
              available_actions={plan.available_actions}
            />
          </section>
        </div>
      ))}
    </LayoutDashboard>
  );
}

export default Actions;
