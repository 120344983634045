import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import LayoutDashboard from '../../../layout/LayoutDashboard';
import Breadcrumbs from '../../../components/Breadcrumbs';

import Banner from '../../../components/actions/newsletters/Banner';
import MailerLightActionForm from '../../../components/actions/forms/MailerLightActionForm';

const ActionConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');
  let {planId} = useParams();

  const onSubmit = (isSuccess) => {
    console.log(isSuccess);
  };

  return (
    <LayoutDashboard pageLoading={loading} className="action-configuration">
      <Breadcrumbs link="/actions" text={`Actions for ${planName}`} />
      <MailerLightActionForm
        setLoading={setLoading}
        onSubmit={onSubmit}
        planName={planName}
        setPlanName={setPlanName}
        planId={planId}
      />
    </LayoutDashboard>
  );
};

export default ActionConfiguration;
