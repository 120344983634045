import React from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

const Toggler = ({title, isToggled, setIsToggled}) => {
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="toggler-wrapper">
      {title ? (
        <>
          <span className="title">{title}</span>{' '}
        </>
      ) : (
        ''
      )}
      <div className="toggler-button">
        <span
          className={clsx('check', {'active-btn': isToggled})}
          onClick={() => handleToggle()}
        >
          {' '}
          <FontAwesomeIcon icon={faCheck} />
        </span>
        <span
          className={clsx('times', {'active-btn': !isToggled})}
          onClick={() => handleToggle()}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    </div>
  );
};

export default Toggler;
