import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const Buttons = ({loading, className, text, disabled = false}) => {
  return (
    <button disabled={disabled} type="submit" className={className}>
      {loading ? <BeatLoader loading={loading} color="white" /> : text}
    </button>
  );
};

export default Buttons;
