import React, {useState, useEffect} from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfigureOnboarding from '../../components/settings/ConfigureOnboarding';

import LayoutDashboard from '../../layout/LayoutDashboard';

import axios from 'axios';

import {settings} from '../../helpers/Urls';
import PreviewOnboarding from '../../components/PreviewOnboarding';
import {useSelector} from 'react-redux';
import {infoLog, warningLog} from '../../helpers/Logger';

const Branding = (props) => {
  const account = useSelector((state) => state.account);

  const [styling, setStyling] = useState({
    header: {
      bg: '',
      color: '',
    },
    body: {
      bg: '',
      color: '',
    },
    button: {
      bg: '',
      color: '',
    },
    bodyFont: '',
    headerFont: '',
  });

  const [content, setContent] = useState({
    buttonText: '',
    companyName: '',
    explainerText: '',
    onboardingLogo: '',
    customerName: '',
  });

  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    warningLog('Styling', styling);
    warningLog('Content', content);

    axios
      .get(`${settings.onboarding}`, {
        params: {
          type: 'onboarding',
        },
      })
      .then(({data: onboardingData}) => {
        const colorScheme = JSON.parse(onboardingData?.color_scheme);
        infoLog('ColorScheme', colorScheme);

        setStyling((prevState) => ({
          ...prevState,
          ...colorScheme,
          bodyFont: onboardingData?.body_font,
          headerFont: onboardingData?.header_font,
        }));

        setContent((prevState) => ({
          ...prevState,
          buttonText: onboardingData?.button_text,
          companyName: onboardingData?.company_name,
          explainerText: onboardingData?.explainer_text,
          onboardingLogo: onboardingData?.onboarding_logo,
        }));

        setLoading(false);
      })
      .catch((error) => setLoading(false));
    // eslint-disable-next-line
  }, [account]);

  return (
    <LayoutDashboard pageLoading={loading} className="branding">
      <Breadcrumbs link="/settings" text="Settings" />
      <section className="banner">
        <h2 className="section-title">Branding</h2>
        <p>
          Change the brand identity that'll be displayed to customer on your
          GetOnBoard.ly page
        </p>
      </section>

      <section className="configuration-wrapper">
        <ConfigureOnboarding
          setFiles={setFiles}
          styling={styling}
          setStyling={setStyling}
          content={content}
          setContent={setContent}
          isUploaded={isUploaded}
          setIsUploaded={setIsUploaded}
          files={files}
        />
        <PreviewOnboarding styling={styling} content={content} />
      </section>
    </LayoutDashboard>
  );
};

Branding.propTypes = {};

export default Branding;
