import React from 'react';
import JSONPretty from 'react-json-pretty';
import axios from 'axios';
import {toast} from 'react-toastify';

import Modal from './shared/Modal';
import {eventLog} from '../helpers/Urls';

const FailedEvent = ({show, handleClose, logs}) => {
  const handleReattempt = () => {
    axios
      .post(`${eventLog.reAttempt}/${logs?.id}`)
      .then(() => {
        toast.success('Action re-attempt queued', {});
      })
      .catch((error) => {
        if (error?.response?.data?.detail) {
          toast.error('Already exists in queue', {});
        } else {
          toast.error('Something went wrong', {});
        }
      });
  };

  return (
    <Modal
      show={show}
      title={
        <>
          Failed Mailchimp Sync for {logs?.getonboardly_id} on{' '}
          {logs?.added_to_database}{' '}
        </>
      }
      handleClose={handleClose}
    >
      <div className="modal-body">
        <p>
          Here's the response Mailchimp provided to explain why this action
          failed. You can manually complete the action on behalf of the
          customer, or if you think you've now fixed the problem, you can press
          the button below to queue the action for reattempt. You'll see results
          in the event log after a few minutes.
        </p>
        <div className="json-response-wrapper">
          <JSONPretty
            id="json-pretty"
            data={logs?.json_response}
            space="3"
          ></JSONPretty>
        </div>
      </div>
      <button className="btn-purple" onClick={handleReattempt}>
        Reattempt action
      </button>
    </Modal>
  );
};

export default FailedEvent;
