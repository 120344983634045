import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import domainImg from '../assets/images/domain-tip.png';
import domainImg2x from '../assets/images/domain-tip@2x.png';
import {urlRegex, wizardSteps} from '../helpers/StaticVariables';
import WizardButton from './wizard/WizardButton';
import Modal from './shared/Modal';
import {checkIsEmptyOrNull, removeSpaces} from '../helpers/HelperFunctions';
import BeatLoader from 'react-spinners/BeatLoader';

const BillsbyConnect = ({
  show,
  handleClose,
  linkedAccount,
  handleConnectAccount,
  customerUniqueId,
  fetchLinkedAccount,
}) => {
  const [step, setStep] = useState(1);
  const [billsbyDomain, setBillsByDomain] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [webhookCheckLoader, setWebhookCheckLoader] = useState(false);
  const [webhookSecret, setWebhookSecret] = useState('');
  const [redirectURL, setRedirectURL] = useState('');
  const [isBothBlanked, setIsBothBlanked] = useState({
    clicked: false,
    both: false,
  });
  const [verifiedDomain, setVerifiedDomain] = useState(false);
  const [error, setError] = useState(null);
  const [isSavedWebhookForwarding, setIsSavedWebhookForwarding] = useState(
    false
  );
  const [webhookChecker, setWebhookChecker] = useState();

  // fetch webhook function in parent component
  const checkWebhook = () => {
    console.log('Checking webhook');
    fetchLinkedAccount();
  };

  // Initialize webhook checker
  const setChecker = () => {
    setWebhookCheckLoader(true);
    setWebhookChecker(setInterval(checkWebhook, 5000));
  };

  // Clear Webhook
  const clearChecker = () => {
    // console.log('Clear webhook checker');
    clearInterval(webhookChecker);
  };

  const handleNextStep = async () => {
    if (step === 1)
      if (checkIsEmptyOrNull(removeSpaces(billsbyDomain)))
        return setError('Please enter a Billsby domain');

    if (step === 2) {
      if (checkIsEmptyOrNull(removeSpaces(apiKey)))
        return setError('Please enter a Billsby API Key');
      if (!apiKey.includes(billsbyDomain))
        return setError("Domain and API Key doesn't match");
      if (verifiedDomain) return setStep(step + 1);

      /**
       * Sends API Request:
       * - Validates domain and API key in Billsby
       * - Updates/Saves domain and API key in DB (initialize record in DB)
       * - Set Verified Domain to True
       * - Moves to next step
       *
       * Error:
       * - Domain and API Key not found in Billsby
       */
      return handleConnectAccount('domain', {
        domain: billsbyDomain,
        apiKey,
      })
        .then((result) => {
          setVerifiedDomain(true);
          setStep(step + 1);
        })
        .catch((err) =>
          setError(
            'Domain and API Key not found in Billsby. Please enter a valid domain and API Key.'
          )
        );
    }

    if (step === 3) {
      if (isSavedWebhookForwarding) return;

      if (isBothBlanked.clicked && isBothBlanked.both) return;

      if (isBothBlanked.clicked && !isBothBlanked.both) {
        if (webhookUrl === '')
          return setError(
            'Please enter the webhook URL found in your Billsby account'
          );

        if (urlRegex.test(webhookUrl))
          /**
           * Sends API Request:
           * - Save webhook forwarding info and save to database
           *
           * Then:
           * - Run Webhook Checker
           */
          return handleConnectAccount('webhook', {
            webhookUrl,
            webhookSecret,
          })
            .then((result) => {
              setIsSavedWebhookForwarding(true);
              setChecker();
            })
            .catch((err) => {
              setError('Failed Saving Webhook Forwarding Details');
            });
        else return setError('Invalid URL format');
      }
    }

    if (step === 4) {
      clearChecker();
      if (redirectURL === '')
        return setError(
          'Please enter a redirect URL for us to send customers to'
        );

      if (redirectURL !== '')
        if (urlRegex.test(redirectURL))
          return handleConnectAccount('redirect', {
            redirectUrl: redirectURL,
          });
        else return setError('Invalid URL format');
    }

    setError(null);

    return setStep(step + 1);
  };

  const handleWebhookForwardingChoice = (type) => {
    if (type === 'isBlank') {
      setIsBothBlanked({clicked: true, both: true});
      setChecker();
    } else {
      setIsBothBlanked({clicked: true, both: false});
      clearChecker();
    }
  };

  useEffect(() => {
    clearChecker();
    setWebhookCheckLoader(false);

    if (linkedAccount) {
      let _step = 1;
      // set Local data with passed props
      setBillsByDomain(linkedAccount.domain);
      setApiKey(linkedAccount.api_key);
      setWebhookUrl(linkedAccount.webhook_url ?? '');
      setWebhookSecret(linkedAccount.webhook_secret ?? '');
      setRedirectURL(linkedAccount.redirect_url ?? '');

      // Check wehook url if !empty
      if (!checkIsEmptyOrNull(linkedAccount.webhook_url))
        setIsSavedWebhookForwarding(true);

      // Auto step up
      if (!checkIsEmptyOrNull(linkedAccount.domain)) _step++;
      if (!checkIsEmptyOrNull(linkedAccount.api_key)) _step++;
      if (linkedAccount.webhook_status === 'active') _step++;

      return setStep(_step);
    }

    setStep(1);
    //eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    // Auto clear error after 5 seconds
    const timer = setTimeout(() => {
      setError(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    /**
     * Monitors webhook status
     * Works with step 3 > Adding webhook forwarding URL
     */
    if (linkedAccount?.webhook_status === 'active') {
      setStep(step + 1);
      setWebhookCheckLoader(false);
    }

    //eslint-disable-next-line
  }, [linkedAccount]);

  useEffect(() => {
    // Monitor steps:
    if (step !== 3) clearChecker();
    // eslint-disable-next-line
  }, [step]);

  return (
    <Modal
      show={show}
      title="Connecting your Billsby account"
      handleClose={handleClose}
    >
      <div className="modal-body">
        <p>
          So that you can start using GetOnboard.ly, you’ll need to connect your
          Billsby account by giving us your account domain and API key, setting
          up web hook forwarding and configuring your redirect URL. This’ll take
          less than five minutes. As long as you follow all of the steps
          correctly, your customers will have no idea you’ve connected
          GetOnboard.ly until you go live, and everything you currently use will
          still work as expected.
        </p>

        <div className="wizard-container">
          {wizardSteps.map((ws) => (
            <div
              className={clsx(
                'wizard-steps',
                {'step-disabled': ws.step > step},
                {'step-done': ws.step < step},
                {
                  'collapse less':
                    (ws.step === 1 && step === 3) ||
                    (step === 4 && ws.step === 2),
                },
                {'collapse lesser': ws.step === 1 && step === 4}
              )}
              key={ws.step}
            >
              <div className="wizard-steps__content">
                <div className={`wizard-steps__content__header `}>
                  <span className="wizard-steps__number">
                    {ws.step < step ? (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    ) : (
                      ws.step
                    )}
                  </span>
                  <div
                    className="wizard-steps__content__title"
                    onClick={() => {
                      console.log(ws.step);
                      if (ws.step <= step) setStep(ws.step);
                    }}
                  >
                    {ws.title}
                  </div>
                </div>

                {ws.step === 1 && step === 1 && (
                  <div className="wizard-steps__content__body">
                    <div className="wizard-steps__content__body__img-holder">
                      <img
                        src={domainImg}
                        srcSet={domainImg2x}
                        alt="e.g. Billsby domain"
                      />
                    </div>
                    <p>
                      After logging in to your account on Billsby, look at the
                      address in your web browser. We need just the bit after
                      the https:// but before the .billsby.com.
                    </p>
                    <div className="input-group-wrapper">
                      <label className="input-label">Billsby Domain</label>
                      <div className="input-text-group">
                        <input
                          type="text"
                          className="input"
                          placeholder="burtsbeekeeping"
                          value={billsbyDomain}
                          onChange={(e) => setBillsByDomain(e.target.value)}
                        />
                        <span className="input-ext">.billsby.com</span>
                      </div>
                    </div>
                  </div>
                )}

                {ws.step === 2 && step === 2 && (
                  <div className="wizard-steps__content__body">
                    <p>
                      Great! Next we need your API key. This lets us communicate
                      with your Billsby account.
                    </p>
                    <a
                      className="btn-purple-inverted"
                      href={`https://${billsbyDomain}.billsby.com/configuration/api-keys`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access API keys page
                    </a>

                    <div className="input-group-wrapper">
                      <label className="input-label">API Key</label>
                      <div className="input-text-group ">
                        <input
                          type="text"
                          className="input full-width"
                          placeholder="burtsbeekeeping_bd775d524b624c80e9f51db661c052b4"
                          value={apiKey}
                          onChange={(e) => setApiKey(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {ws.step === 3 && step === 3 && (
                  <div className="wizard-steps__content__body">
                    {isSavedWebhookForwarding ? (
                      <>
                        <p>
                          OK! We’ve saved your existing URL (<b>{webhookUrl}</b>
                          )
                          {webhookSecret
                            ? ` and secret (
                         ${(<b>XXXXXXX</b>)})`
                            : ''}
                          . We’re now forwarding all the web hooks that we
                          receive to your existing web hook URL for you, and
                          we’ll keep doing this unless you cancel your
                          GetOnboard.ly account or don’t complete this setup
                          wizard. You can change your forwarding address later
                          on.
                        </p>
                        <p>
                          Now delete both the URL and secret, and in the URL
                          field enter the following unique URL:
                        </p>
                        <h2>
                          {process.env.REACT_APP_BE_URL}/webhooks/
                          {customerUniqueId?.toLowerCase()}
                        </h2>
                        <p>
                          Then press <b>Update web hook details</b> and finally
                          press the <b>Test</b> button next to{' '}
                          <b>Customer updated</b> (it’ll be forwarded to your
                          existing URL too)
                        </p>
                      </>
                    ) : (
                      <>
                        {!isBothBlanked.clicked && (
                          <>
                            <p>
                              This next step is slightly trickier. We need to
                              both forward your web hooks from Billsby to
                              GetOnboard.ly, and ensure GetOnboard.ly can
                              forward them to your existing web hook URL if you
                              have one.
                            </p>
                            <a
                              className="btn-purple-inverted"
                              href={`https://${billsbyDomain}.billsby.com/configuration/api-keys/webhooks`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Access web hooks page
                            </a>
                            <p>
                              <b>
                                On the linked page, are both URL and secret key
                                blank?
                              </b>
                            </p>
                          </>
                        )}

                        <div className="button-group">
                          <button
                            onClick={() => {
                              handleWebhookForwardingChoice('isBlank');
                            }}
                            className={`btn-purple ${
                              isBothBlanked.clicked &&
                              !isBothBlanked.both &&
                              'disabled-btn'
                            }`}
                          >
                            {isBothBlanked.clicked && isBothBlanked.both && (
                              <FontAwesomeIcon icon={faCheckCircle} />
                            )}
                            They are both blank
                            <BeatLoader
                              loading={webhookCheckLoader}
                              color="white"
                            />
                          </button>

                          <button
                            onClick={() => {
                              handleWebhookForwardingChoice('isNotBlank');
                            }}
                            className={`btn-purple ${
                              isBothBlanked.clicked &&
                              isBothBlanked.both &&
                              'disabled-btn'
                            }`}
                          >
                            {isBothBlanked.clicked && !isBothBlanked.both && (
                              <FontAwesomeIcon icon={faCheckCircle} />
                            )}
                            At least one of them is populated
                          </button>
                        </div>

                        {isBothBlanked.clicked && isBothBlanked.both && (
                          <>
                            <p>
                              Great! In the URL field, add the following unique
                              URL:
                            </p>
                            <h2>
                              {process.env.REACT_APP_BE_URL}/webhooks/
                              {customerUniqueId.toLowerCase()}
                            </h2>
                            <p>
                              Then press <b>Update web hook details</b> and
                              finally press the <b>Test</b> button next to{' '}
                              <b>Customer updated</b>
                            </p>
                          </>
                        )}

                        {isBothBlanked.clicked && !isBothBlanked.both && (
                          <>
                            <p>OK! What’s in those fields?</p>
                            <div className="input-group-wrapper">
                              <label className="input-label">URL</label>
                              <div className="input-text-group">
                                <input
                                  type="text"
                                  className="input full-width"
                                  placeholder="https://www.yourwebhookurl.com"
                                  value={webhookUrl ?? ''}
                                  onChange={(e) =>
                                    setWebhookUrl(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="input-group-wrapper">
                              <label className="input-label">Secret</label>
                              <div className="input-text-group">
                                <input
                                  type="text"
                                  className="input full-width"
                                  placeholder="You can leave this blank if secret isn’t populated"
                                  value={webhookSecret ?? ''}
                                  onChange={(e) =>
                                    setWebhookSecret(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
                {ws.step === 4 && step === 4 && (
                  <div className="wizard-steps__content__body">
                    <p>
                      Finally, let’s configure your redirect URL. This is where
                      we send customers after they’ve finished completing your
                      GetOnboard.ly form. Until you go live, we’ll just forward
                      customers straight to your redirect URL. So customers will
                      be redirected from Billsby to GetOnboard.ly, and then from
                      GetOnboard.ly to you. Simple.
                    </p>
                    <a
                      className="btn-purple-inverted"
                      href={`https://${billsbyDomain}.billsby.com/configuration/checkout-account-management`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access redirect URL page
                    </a>
                    <p>
                      Scroll down until you see ‘Redirect URL’. If there’s
                      already something in this box, copy and paste it below. If
                      not, provide a redirect URL below for us to send customers
                      to - this could be a login page, and thank you page, or
                      just your home page. We’ll pass through the Customer ID
                      and Subscription ID from Billsby, and add the end users
                      GetOnboard.ly Profile ID too (after you go live). You can
                      change it later on.
                    </p>
                    <div className="input-group-wrapper">
                      <label className="input-label">Redirect URL</label>
                      <div className="input-text-group">
                        <input
                          type="text"
                          className="input full-width"
                          placeholder="https://www.mycompany.com/welcome"
                          value={redirectURL}
                          onChange={(e) => setRedirectURL(e.target.value)}
                        />
                      </div>
                    </div>
                    <p>
                      Finally, delete anything that’s already in the box and add
                      your GetOnboard.ly redirect URL, then press Update URL:
                    </p>
                    <h2>
                      https://getonboard.ly/registration/
                      {customerUniqueId?.toLowerCase()}
                    </h2>
                  </div>
                )}

                {ws.step === step && (
                  <>
                    <WizardButton
                      handleNextStep={handleNextStep}
                      step={step}
                      isSavedWebhookForwarding={isSavedWebhookForwarding}
                      isBothBlanked={isBothBlanked}
                      type={'Billsby'}
                    />
                    <div className="error-msg">{error}</div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="modal-footer"></div> */}
    </Modal>
  );
};

export default BillsbyConnect;
