import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {customerRegistration} from '../helpers/Urls';
import useQuery from '../utils/useQuery';
import {useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {MemoizedFontPicker} from '../components/FontPickerHidden';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

import {useForm} from 'react-hook-form';
// import CustomerAction from '../components/CustomerAction';

import BeatLoader from 'react-spinners/BeatLoader';

import {warningLog, infoLog, errorLog} from '../helpers/Logger';

// const fontAPIKey = process.env.REACT_APP_GOOGLEFONTS_API_KEY;

const CustomerRegistration = () => {
  // eslint-disable-next-line
  const {register, handleSubmit, errors} = useForm();
  const [loading, setLoading] = useState(true);
  const [customerActivationID, setCustomerActivationID] = useState('');
  const [redirectURL, setRedirectURL] = useState('');
  const [planId, setPlanId] = useState('');
  const [actions, setActions] = useState([]);

  const [styling, setStyling] = useState({
    header: {
      bg: '',
      color: '',
    },
    body: {
      bg: '',
      color: '',
    },
    button: {
      bg: '',
      color: '',
    },
    bodyFont: '',
    headerFont: '',
  });

  const [content, setContent] = useState({
    buttonText: '',
    companyName: '',
    explainerText: '',
    onboardingLogo: '',
    customerName: '',
  });

  let query = useQuery();
  const cid = query.get('cid');
  const sid = query.get('sid');
  var {cid: ownerCID} = useParams();
  ownerCID = ownerCID.toUpperCase();
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies([`GetOnboardlyTest.${ownerCID}`]);
  // eslint-disable-next-line
  const [isTestMode, setIsTestMode] = useState(
    !!cookies[`GetOnboardlyTest.${ownerCID}`]
  );

  const onSubmit = (data) => {
    warningLog('form data', data);
    infoLog('redirect URL', redirectURL);
    setLoading(true);
    if (isTestMode) return (window.location.href = redirectURL);
    activateSubscription(customerActivationID);
  };

  const setConfig = () => {
    axios
      .get(
        `${customerRegistration.config}/${ownerCID}?customerSubscriptionId=${sid}`
      )
      .then(({data}) => {
        console.log(
          '%c ⚙ config data ',
          'color:orange;background:black;padding:5px;',
          data
        );

        console.log(
          '%c 🧪 test mode? ',
          'color:green;background:black;padding:5px;',
          isTestMode
        );

        setRedirectURL(data.redirect_url);

        if (!data.live && !isTestMode)
          return (window.location.href = redirectURL);

        const onboardingData = data?.branding?.onboarding?.data;
        const colorScheme = JSON.parse(onboardingData?.color_scheme);

        setStyling((prevState) => ({
          ...prevState,
          ...colorScheme,
          bodyFont: onboardingData?.body_font,
          headerFont: onboardingData?.header_font,
        }));

        setContent((prevState) => ({
          ...prevState,
          buttonText: onboardingData?.button_text,
          companyName: onboardingData?.company_name,
          explainerText: onboardingData?.explainer_text,
          onboardingLogo: onboardingData?.onboarding_logo,
        }));

        let _actions = data.actions.map((action) => {
          action.toggle = true;
          return action;
        });

        setActions(_actions);

        setPlanId(data.plan_id);
      })
      .catch((error) => console.log(error.data));
  };

  const createUser = () =>
    axios
      .post(`${customerRegistration.save}/${ownerCID}`, {
        cid,
        sid,
      })
      .then(({data}) => {
        console.log(
          '%c 👤👨👨👦 create user data ',
          'color:yellow;background:black;padding:5px;',
          data
        );

        if (data.onboarded && !isTestMode)
          return (window.location.href = data.redirect_url);

        setCustomerActivationID(data.customer.getonboardly_id);
        setContent((prevState) => ({
          ...prevState,
          customerName: data.customer.first_name,
        }));
        setLoading(false);
      })
      .catch((error) => {
        errorLog('error', error.response);
        setLoading(false);
      });

  const activateSubscription = (id) => {
    let action_codes = [];
    actions.forEach((action) => {
      if (action.toggle) action_codes.push(action.action_code);
    });

    axios
      .put(`${customerRegistration.activate}/${id}`, {
        planId,
        optIn: action_codes,
      })
      .then(({data}) => {
        warningLog('data', data);
        window.location.href = redirectURL;
        setLoading(false);
      })
      .catch((error) => {
        errorLog('error', error.response);
        setLoading(false);
      });
  };

  const handleToggle = (action_code) => {
    setActions((prevState) => {
      let newState = prevState.map((prev) => {
        if (prev.action_code === action_code) prev.toggle = !prev.toggle;
        return prev;
      });
      return newState;
    });
  };

  const headerFontStyle = {
    color: styling.header.color,
    fontFamily: styling.headerFont,
  };

  const bodyFontStyle = {
    color: styling.body.color,
    fontFamily: styling.bodyFont,
  };

  useEffect(() => {
    (async function () {
      await setConfig();
      createUser();
      setLoading(false);
    })();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="page-customer-registration">
      {loading && (
        <div className="loader-wrapper">
          <BeatLoader loading={loading} color="white" />
        </div>
      )}

      <div
        className="customer-header"
        style={{background: styling?.header?.bg}}
      >
        <div className="customer-header__wrapper">
          <img
            src={content.onboardingLogo}
            alt=""
            className="customer-header__logo"
          />

          <h1 className="customer-header__title" style={headerFontStyle}>
            Welcome to {content.companyName} {content.customerName} lets finish
            your account
          </h1>

          <p
            className="customer-header__title-description"
            style={headerFontStyle}
          >
            {content.explainerText}
          </p>
        </div>
      </div>

      <div className="customer-body" style={{background: styling?.body?.bg}}>
        <div className="form-wrapper v2">
          <form onSubmit={handleSubmit(onSubmit)} style={bodyFontStyle}>
            {actions.length > 0 &&
              actions.map(
                ({
                  action_code,
                  toggle,
                  web_form_snippet_text,
                  marketing_consent,
                }) =>
                  marketing_consent ? (
                    <div style={{display: 'flex'}} key={action_code}>
                      <div className="toggler-wrapper">
                        <div className="toggler-button">
                          <span
                            className={clsx('check', {
                              'active-btn': toggle,
                            })}
                            onClick={() => handleToggle(action_code)}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span
                            className={clsx('times', {
                              'active-btn': !toggle,
                            })}
                            onClick={() => handleToggle(action_code)}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        </div>
                      </div>
                      <div className="text-wrapper">
                        {web_form_snippet_text}
                      </div>
                    </div>
                  ) : null
              )}

            <div className="button-wrapper">
              <button
                type="submit"
                className="btn"
                style={{
                  background: styling.button.bg,
                  color: styling.button.color,
                  font: styling.bodyFont,
                }}
              >
                Activate my subscription
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* NOTE: HAX to dynamically load paid fonts from googlefont */}
      <MemoizedFontPicker
        headerFont={styling.headerFont}
        bodyFont={styling.bodyFont}
      />
    </div>
  );
};

export default CustomerRegistration;
