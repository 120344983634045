import React from 'react';
// export const customStyles = {
//   container: (base) => ({
//     ...base,
//     width: '100%',
//     height: 58,
//     minHeight: 58,
//     background: 'red'
//   }),
//   control: (base) => ({
//     ...base,
//     width: '100%'
//   }),
// };

export const customStyles = {
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: '1px dotted pink',
  //   color: state.isSelected ? 'red' : 'blue',
  //   padding: 20,
  // }),
  container: () => ({
    width: '100%',
    background: 'white',
    height: '46px !important',
  }),
  dropdownIndicator: () => ({
    display: 'none'
  }),
  clearIndicator: () => ({
    display: 'none'
  }),
  menu: () => ({
    width: '100%',
    background: 'white',
    border: '1px solid #cccccc',
    borderRadius: '4px'
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    height: '46px !important',
    width: '100%',
    background: 'white',
    border: '1px solid #cccccc',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center'
  }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
}

export const indicatorSeparatorStyle = {
  display: 'none',
};

export const IndicatorSeparator = ({innerProps}) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
