import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import siteLogo from '../assets/images/getonboard.ly@2x.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFileInvoiceDollar,
  faDirections,
  faClipboardList,
  faCogs,
  faUserCircle,
  faSignOutAlt,
  faUsersCog,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import {connect} from 'react-redux';
import {logout} from '../actions/auth';

function SideBar({logout, isAdmin, proxyMode, isLive, authUser, getStarted}) {
  const [showGetStarted, setShowGetStarted] = React.useState(false);
  const handleGetStarted = () => {
    // Render Condition
    if (!proxyMode && !authUser?.live) return setShowGetStarted(true);
    if (proxyMode && !isLive) return setShowGetStarted(true);
  };

  React.useEffect(() => {
    handleGetStarted();
    // eslint-disable-next-line
  }, [proxyMode, authUser, isLive]);

  return (
    <div className="sidebar-wrapper">
      <div className="logo-wrapper">
        <img src={siteLogo} alt="logo" />
      </div>
      <div className="linklist">
        <div className="linklist__top">
          <ul>
            {showGetStarted && (
              <li className="linklist__item">
                <NavLink
                  activeClassName="is-active"
                  className={clsx('nav-link', {'is-disabled': isLive})}
                  to="/"
                  isActive={(match, location) => {
                    return match.isExact;
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />

                  <span>Get started</span>
                </NavLink>
              </li>
            )}
            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className={clsx('nav-link', {'is-disabled': !isLive})}
                to="/subscriptions"
              >
                <FontAwesomeIcon icon={faUsers} />
                <span>Subscriptions</span>
              </NavLink>
            </li>
            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className={clsx('nav-link', {'is-disabled': !isLive})}
                to="/actions"
              >
                <FontAwesomeIcon icon={faDirections} />
                <span>Actions</span>
              </NavLink>
            </li>
            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className={clsx('nav-link', {'is-disabled': !isLive})}
                to="/event-log"
              >
                <FontAwesomeIcon icon={faClipboardList} />
                <span>Event Log</span>
              </NavLink>
            </li>

            {/* TODO compare all sub settings page to make this active */}
            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className={clsx('nav-link', {
                  'is-disabled': !isLive && !getStarted.isLinked,
                })}
                to="/settings"
              >
                <FontAwesomeIcon icon={faCogs} />
                <span>Settings</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="linklist__bottom">
          <ul>
            {isAdmin && !proxyMode && (
              <li className="linklist__item">
                <NavLink
                  activeClassName="is-active"
                  className="nav-link"
                  to="/admin/customers"
                >
                  <FontAwesomeIcon icon={faUsersCog} />
                  <span>Enter admin mode</span>
                </NavLink>
              </li>
            )}

            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className="nav-link"
                to="/my-subscription"
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                <span>My Subscription</span>
              </NavLink>
            </li>

            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className="nav-link"
                to="/account"
              >
                <FontAwesomeIcon icon={faUserCircle} />
                <span>My Account</span>
              </NavLink>
            </li>

            {!proxyMode && (
              <li onClick={logout} className="linklist__item logout">
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  logout: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  proxyMode: PropTypes.bool,
  isLive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  proxyMode: state.account.proxyMode,
  isLive: state.account?.account?.live,
  authUser: state.auth.user,
  getStarted: state.getstarted,
});

export default connect(mapStateToProps, {logout})(SideBar);
