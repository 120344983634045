import React, {useState, useEffect, useRef, memo} from 'react';
// import {useForm} from 'react-hook-form';
// import FontPicker from 'font-picker-react';
import {MemoizedFontPicker} from '../components/FontPickerHidden';

import TogglerMultiple from '../components/TogglerMultiple';
import Button from '../components/Buttons';

// import {fonts} from '../helpers/StaticVariables';
import {actions as actionsUrl, settings} from '../helpers/Urls';
import axios from 'axios';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

// const fontAPIKey = process.env.REACT_APP_GOOGLEFONTS_API_KEY;

const PreviewWebForm = ({
  styling,
  content,
  planName,
  planId,
  emailConsent = true,
  actions,
}) => {
  const firstName = useSelector((state) => state.account?.account?.firstName);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [mailingConsent, setMailingConsent] = useState({});

  const [companyName, setCompanyName] = useState('');
  const [explainerText, setExplanerText] = useState('');

  const CompanyNameRef = useRef();
  const explainerNameRef = useRef();

  const headerFontStyle = {
    color: styling.header.color,
    fontFamily: styling.headerFont,
  };

  const bodyStyle = {
    background: styling.body.bg,
    color: styling.body.color,
    fontFamily: styling.bodyFont,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // create a loop
    Object.keys(mailingConsent).map(async (actionCode) => {
      let {action_config} = actions.find(
        (action) => actionCode === action.action_code
      );
      let newData = {
        actionConfig: {
          ...action_config,
          web_form_snippet_text:
            mailingConsent[actionCode].web_form_snippet_text,
        },
      };
      await updateAction(actionCode, newData);
      console.log('new ' + actionCode, newData);
    });

    await updateBranding();
    toast.success('Action updated succesfully', {});
  };

  const updateBranding = () => {
    const fd = new FormData();
    fd.append('companyName', companyName);
    fd.append('explainerText', explainerText);
    fd.append('buttonText', content.buttonText);
    fd.append('headerFont', styling.headerFont);
    fd.append('bodyFont', styling.bodyFont);
    fd.append('logo', '');
    fd.append(
      'colorScheme',
      JSON.stringify({
        header: styling.header,
        body: styling.body,
        button: styling.button,
      })
    );
    return axios.put(settings.updateOnboarding, fd).catch((err) => {
      console.log('Save Branding Error', err.response.data);
      setSubmitLoading(false);
      toast.error('Something went wrong');
    });
  };

  const updateAction = (actionCode, data) =>
    axios
      .put(`${actionsUrl.saveActions}/${planId}/${actionCode}`, data)
      .then(({data}) => data)
      .catch((err) => toast.error('Action updated Fail', {}));

  // const fetchSpecificAction = (actionCode) =>
  //   axios
  //     .get(`${actionsUrl.getActions}/${planId}/${actionCode}`)
  //     .then(({data}) => data)
  //     .catch((err) => toast.error('Action updated Fail', {}));

  const populateNewsletter = () => {
    let _mailingCosent = {};
    [...actions].map(
      ({
        action_code,
        action_config: {
          web_form_snippet,
          web_form_snippet_text,
          signup_actions,
        },
      }) => {
        if (signup_actions.marketing_consent)
          _mailingCosent = {
            ..._mailingCosent,
            [action_code]: {web_form_snippet, web_form_snippet_text},
          };
        return action_code;
      }
    );
    return {..._mailingCosent};
  };

  const handleCallback = (data, isToggle, text) =>
    setMailingConsent({
      ...mailingConsent,
      [data]: {
        ...mailingConsent[data],
        web_form_snippet: isToggle,
        web_form_snippet_text: text,
      },
    });

  const setInputDynamicWidth = (ref, pixel) => {
    const el = ref.current;
    const length = el.value.length;
    el.style.width = `${(length + 1) * pixel}px`;
  };

  useEffect(() => {
    setMailingConsent(populateNewsletter());
    // eslint-disable-next-line
  }, [actions]);

  useEffect(() => {
    setCompanyName(content.companyName);
    setExplanerText(content.explainerText);
  }, [content]);

  useEffect(() => {
    setInputDynamicWidth(CompanyNameRef, 14);
  }, [companyName]);

  useEffect(() => {
    setInputDynamicWidth(explainerNameRef, 7.5);
  }, [explainerText]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-wrapper v2">
        <div className="preview-onboarding preview-webform">
          <div
            className="customer-header"
            style={{background: styling?.header?.bg}}
          >
            <div className="customer-header__wrapper">
              <img
                src={content.onboardingLogo}
                alt=""
                className="customer-header__logo"
              />
              <label className="inline-input-wrapper">
                <h1 className="customer-header__title" style={headerFontStyle}>
                  Welcome to{' '}
                  <input
                    ref={CompanyNameRef}
                    className="inline-with-text"
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />{' '}
                  {firstName}
                </h1>
                <h1 className="customer-header__title" style={headerFontStyle}>
                  Let's finish configuring your account.
                </h1>
              </label>

              <p
                className="customer-header__title-description"
                style={headerFontStyle}
              >
                <input
                  ref={explainerNameRef}
                  className="inline-with-text"
                  type="text"
                  value={explainerText}
                  onChange={(e) => {
                    setExplanerText(e.target.value);
                  }}
                />
              </p>
            </div>
          </div>

          <div className="customer-body" style={bodyStyle}>
            <div className="form-wrapper v2">
              <form>
                {Object.keys(mailingConsent).map((action) => {
                  return (
                    <div style={{marginBottom: '15px'}} key={action}>
                      <TogglerMultiple
                        isToggled={
                          mailingConsent[action].web_form_snippet ?? true
                        }
                        actionCode={action}
                        text={mailingConsent[action].web_form_snippet_text}
                        callback={handleCallback}
                      />
                    </div>
                  );
                })}
                <div className="button-wrapper">
                  <button
                    type="submit"
                    className="btn"
                    disabled
                    style={{
                      background: styling.button.bg,
                      color: styling.button.color,
                      font: styling.bodyFont,
                    }}
                  >
                    {content.buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Button
          className="btn-purple"
          loading={submitLoading}
          text={`Save changes to web form for ${planName}`}
        />
        <MemoizedFontPicker
          bodyFont={styling.bodyFont}
          headerFont={styling.headerFont}
        />
      </div>
    </form>
  );
};

export default memo(PreviewWebForm);
