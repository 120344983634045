import {
  ACCOUNT_ERROR,
  GET_ACCOUNT,
  GET_ACCOUNTS,
  CLEAR_ACCOUNT,
  GET_PROXY_ACCOUNT,
} from '../actions/types';
const initialState = {
  account: null,
  currentAccountList: [],
  accounts: {},
  loading: true,
  error: {},
  accounType: null,
  proxyMode: localStorage.getItem('proxyMode') === 'true',
  proxyToken: localStorage.getItem('proxyToken'),
  proxyEmail: localStorage.getItem('proxyEmail'),
};

export default function (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case GET_ACCOUNT:
      return {
        ...state,
        account: {...payload},
        loading: false,
        error: {},
      };

    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        loading: false,
      };

    case GET_PROXY_ACCOUNT:
      localStorage.setItem('proxyToken', payload?.proxyToken);
      localStorage.setItem('proxyMode', true);
      localStorage.setItem('proxyEmail', payload?.proxyEmail);

      console.log('payload', payload);
      return {
        ...state,
        account: {...payload.account},
        loading: false,
        error: {},
        proxyMode: true,
        proxyToken: payload?.proxyToken,
      };

    case ACCOUNT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case CLEAR_ACCOUNT:
      localStorage.removeItem('proxyToken');
      localStorage.removeItem('proxyEmail');
      localStorage.setItem('proxyMode', false);
      return {
        ...state,
        account: null,
        proxyMode: false,
        proxyToken: null,
        proxyEmail: null,
        accounType: null,
        error: {},
        loading: true,
      };

    default:
      return state;
  }
}
