import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocation, useParams} from 'react-router-dom';
import axios from 'axios';
import {constantContact} from '../../../helpers/Urls';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';

import {
  faDoorOpen,
  faPencilAlt,
  faBan,
  faCheck,
  // faTimes,
  faEnvelopeOpenText,
  faThList,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../../../components/Buttons';
import Toggler from '../../../components/Toggler';
import {errorLog, requestLog, successLog} from '../../../helpers/Logger';

import {Actions} from '../../../lib/Actions';
import {getActionConfig} from '../../../helpers/HelperFunctions';

import TabHeader from '../../../components/actions/newsletters/TabHeader';
import Groupings from '../../../components/actions/newsletters/Groupings';
import Banner from '../../../components/actions/newsletters/Banner';

const ConstantContactActionForm = ({
  onSubmit = () => null,
  setLoading = () => null,
  planId,
}) => {
  let {pathname} = useLocation();

  const {handleSubmit} = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [planName, setPlanName] = useState('');

  const [brandingConfig, setBrandingConfig] = useState({});

  const [oAuthURL, setOAuthURL] = useState('');
  const [oAuthConnected, setOAuthConnected] = useState(false);
  // const [oAuthData, setOAuthData] = useState(undefined);

  const [isUpdateMode, setIsUpdateMode] = useState(false);

  const [signUpAddToGroup, setSignUpAddToGroup] = useState([]);
  const [signUpAddToGroupOption, setSignUpAddToGroupOption] = useState([]);
  const [signUpRemoveFromGroup, setSignUpRemoveFromGroup] = useState(true);

  const [cancelAddToGroup, setCancelAddToGroup] = useState([]);
  const [cancelAddToGroupOption, setCancelAddToGroupOption] = useState([]);
  const [cancelRemoveFromGroup, setCancelRemoveFromGroup] = useState(true);
  const [cancelAction, setCancelAction] = useState(true);

  const [marketingConsent, setMarketingConsent] = useState(true);
  const [syncAction, setSyncAction] = useState(true);
  const [webFormSnippet, setWebFormSnippet] = useState(true);
  const [wfSnippetText, setWFSnippetText] = useState(
    'Get email updates about your subscription'
  );
  const [emailSnippet, setEmailSnippet] = useState(true);
  const [emailSnippetText, setEmailSnippetText] = useState(
    'You’ll get email updates about your subscription'
  );

  const saveConfig = async (data) => {
    setSubmitLoading(true);
    requestLog('Saving...', data);

    if (signUpAddToGroup.length === 0) {
      setSubmitLoading(false);
      return toast.error('Sign up group is empty', {});
    }

    const action = new Actions({
      planId: planId,
      planName: planName,
      actionCode: 'constant_contact',
      actionName: 'Constant Contact',
      actionConfig: {
        signup_actions: {
          add_to_group: signUpAddToGroup,
          remove_from_group: signUpRemoveFromGroup,
          marketing_consent: marketingConsent,
        },
        sync_action: syncAction,
        cancel_actions: {
          toggle: cancelAction,
          add_to_group: cancelAddToGroup,
          remove_from_group: cancelRemoveFromGroup,
        },
        web_form_snippet_text: wfSnippetText,
        email_snippet_text: emailSnippetText,
      },
    });

    await action.save(isUpdateMode);
    setSubmitLoading(false);
    onSubmit(true);
  };

  const handleSignUpAddToGroupOnChange = (value) => setSignUpAddToGroup(value);

  const handleCancelAddToGroupOnChange = (value) => setCancelAddToGroup(value);

  const syncData = ({action_config}) => {
    setSignUpAddToGroup(action_config.signup_actions.add_to_group);
    setSignUpRemoveFromGroup(action_config.signup_actions.remove_from_group);
    setMarketingConsent(action_config.signup_actions.marketing_consent);
    setSyncAction(action_config.sync_action);
    setCancelAction(action_config.cancel_actions.toggle);
    setCancelAddToGroup(action_config.cancel_actions.add_to_group);
    setCancelRemoveFromGroup(action_config.cancel_actions.remove_from_group);
    // setWebFormSnippet(action_config.web_form_snippet);
    setWFSnippetText(action_config.web_form_snippet_text);
    // setEmailSnippet(action_config.email_snippet);
    setEmailSnippetText(action_config.email_snippet_text);
    setIsUpdateMode(true);
  };

  const fetchOAuthURL = () =>
    axios
      .get(constantContact.getAuthURL)
      .then(({data: link}) => setOAuthURL(link));

  const fetchInitialConfig = () => {
    setLoading(true);
    // Promise.all([
    //   axios.get(actions.getPlans + '?id=' + planId),
    //   axios.get(actions.getActions + `/${planId}/constant_contact`),
    //   axios.get(settings.onboarding + '?type=onboarding'),
    //   axios.get(constantContact.getOAuthToken),
    // ])
    getActionConfig(planId, 'constant_contact')
      .then(async ([{data: plan}, {data: action}, {data: branding}]) => {
        setPlanName(plan.plan_name);
        branding.color_scheme = JSON.parse(branding.color_scheme);
        setBrandingConfig(branding);
        console.log('fawefawf', action);
        if (action) {
          if (!action.action_code) await fetchOAuthURL();
          else syncData(action);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(constantContact.getOAuthToken + `?plan_id=${planId}`)
      .then(({data: oauthToken}) => {
        if (oauthToken) {
          // setOAuthData(oauthToken);
          setOAuthConnected(true);
          fetchListNames();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchInitialConfig();
    // eslint-disable-next-line
  }, []);

  const colordiv = () => ({
    background: brandingConfig?.color_scheme?.body?.bg,
    color: brandingConfig?.color_scheme?.body?.color,
    fontFamily: brandingConfig?.body_font,
  });

  const openOAuthConnection = (e) => {
    e.preventDefault();
    // if connect is from Get Started
    console.log('connecting...');
    if (pathname === '/') {
      localStorage.setItem('OAuthActionURL', 'get-started/constant_contact');
      localStorage.setItem('plan_id', planId);
    } else {
      localStorage.setItem('OAuthActionURL', pathname);
      localStorage.setItem('plan_id', planId);
    }

    window.open(oAuthURL, '_self');
  };

  const fetchListNames = () =>
    axios
      .get(constantContact.getListNames + `?plan_id=${planId}`)
      .then(({data}) => {
        if (data.lists.length > 0) {
          const list = data.lists.map(({list_id, name}) => ({
            value: list_id,
            label: name,
          }));
          setSignUpAddToGroupOption(list);
          setCancelAddToGroupOption(list);
          // setSignUpAddToGroup(list);
          // setCancelAddToGroup(list);
          toast.success('List fetched successfully', {});
          successLog('List fetched successfully', data.lists);
        }
      })
      .catch((err) => {
        toast.error('Failed fetching list', {});
        errorLog('Failed fetching list', err);
      });

  return (
    <>
      <Banner title="Constant Contact" />
      <form onSubmit={handleSubmit(saveConfig)}>
        <div className="form-wrapper v2">
          <section className="connect-wrapper">
            <h2 className="section-title">Connect Constant Contact</h2>
            {!oAuthConnected ? (
              <button
                className="btn-purple"
                onClick={(e) => openOAuthConnection(e)}
              >
                Connect
              </button>
            ) : (
              <div>
                <button
                  className="btn-gold"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  Disconnect
                </button>
                {/* <button
                  className="btn-purple"
                  onClick={(e) => {
                    e.preventDefault();
                    fetchListNames();
                  }}
                >
                  Fetch List
                </button> */}
              </div>
            )}
          </section>

          <TabHeader icon={faDoorOpen} name="Sign up action" />

          <Groupings
            description={`On sign up, we’ll add the customer to the Constant Contact
            audience group you specify:`}
            options={signUpAddToGroupOption}
            value={signUpAddToGroup}
            onChange={handleSignUpAddToGroupOnChange}
            removeToggleHandle={setSignUpRemoveFromGroup}
            removeToggleValue={signUpRemoveFromGroup}
          >
            <Toggler
              title="Collect marketing consent"
              setIsToggled={setMarketingConsent}
              isToggled={marketingConsent}
            />
          </Groupings>

          <TabHeader
            icon={faPencilAlt}
            name="Sync action"
            toggleAction={setSyncAction}
            isToggled={syncAction}
          />

          <section>
            <p>
              If your clients name or email address changes in your subscription
              billing platform, we’ll change it in Constant Contact.
            </p>
          </section>

          <TabHeader
            icon={faBan}
            name="Cancel action"
            toggleAction={setCancelAction}
            isToggled={cancelAction}
          />

          <Groupings
            description={` If the subscription is cancelled, we’ll remove the customer from
              the group you chose above. You can also add them to a different
              group. We’ll only take action if they provided marketing consent
              in the sign up action, unless you chose not to collect marketing
              consent. When customers switch plans, we first process a cancel
              action on their old plan, and then process a sign up action on
              their new plan.`}
            options={cancelAddToGroupOption}
            value={cancelAddToGroup}
            onChange={handleCancelAddToGroupOnChange}
            removeToggleHandle={setCancelRemoveFromGroup}
            removeToggleValue={cancelRemoveFromGroup}
          />

          {marketingConsent && (
            <>
              <TabHeader icon={faThList} name="Web form snippet" />

              <div className="colorize-div" style={colordiv()}>
                <Toggler
                  setIsToggled={setWebFormSnippet}
                  isToggled={webFormSnippet}
                />
                <div className="text-wrapper">
                  <div className="auto-grow-input">
                    <input
                      value={wfSnippetText}
                      onChange={(e) => setWFSnippetText(e.target.value)}
                      type="text"
                      className="web-form-snippet-input transparent-input"
                    />
                    <span>{wfSnippetText}</span>
                  </div>
                </div>
              </div>
            </>
          )}

          <TabHeader
            icon={faEnvelopeOpenText}
            name="Email snippet"
            toggleAction={setEmailSnippet}
            isToggled={emailSnippet}
          />

          <div className="colorize-div" style={colordiv()}>
            <div className="text-wrapper">
              <FontAwesomeIcon icon={faCheck} />
              <div className="auto-grow-input">
                <input
                  value={emailSnippetText}
                  onChange={(e) => setEmailSnippetText(e.target.value)}
                  type="text"
                  className="web-form-snippet-input transparent-input"
                />
                <span>{emailSnippetText}</span>
              </div>
            </div>
          </div>
          <Button
            disabled={!oAuthConnected}
            className="btn-purple"
            loading={submitLoading}
            text={
              (isUpdateMode ? `Update` : `Activate`) +
              ` Constant Contact action for ${planName}`
            }
          />
        </div>
      </form>
    </>
  );
};

export default ConstantContactActionForm;
