import React from 'react';

const Banner = ({title}) => {
  return (
    <section className="banner">
      <h2 className="section-title">{title}</h2>
      <p>
        Share your ideas with {title} email newsletters-then use its landing
        page and form builders to grow your lists and take marketing further
        with drip and transactional emails.
      </p>
    </section>
  );
};

export default Banner;
