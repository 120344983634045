import React, {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import clsx from 'clsx';
import FontPicker from 'font-picker-react';
import ColorPicker from '../ColorPicker';
import demoLogo from '../../assets/images/burtsbeekeeping-logo.png';
import PreviewOnboarding from '../../components/PreviewOnboarding';
import {actions} from '../../helpers/StaticVariables';
import axios from 'axios';
import {getStarted} from '../../helpers/Urls';
import Button from '../Buttons';

import UploadImage from '../UploadImage';

import {fonts} from '../../helpers/StaticVariables';

const ConfigureOnboarding = ({setStep}) => {
  const {register, handleSubmit, errors} = useForm();
  const [fontIsReady, setFontIsReady] = useState(false);

  const [styling, setStyling] = useState({
    header: {
      bg: '#F0B922',
      color: '#1C0B19',
    },
    body: {
      bg: '#FCF1D2',
      color: '#1C0B19',
    },
    button: {
      bg: '#000000',
      color: '#FFFFFF',
    },
    bodyFont: 'Sans Serif',
    headerFont: 'Sans Serif',
  });

  const [content, setContent] = useState({
    buttonText: 'Activate my subscription',
    companyName: "Burt's Bee Keeping",
    explainerText:
      'We just need to collect a few more details from you so we can activate your new subscription',
    onboardingLogo: demoLogo,
    customerName: '',
  });

  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const fontAPIKey = process.env.REACT_APP_GOOGLEFONTS_API_KEY;

  const onSubmit = (data) => {
    setSubmitLoading(true);
    console.log(
      '%c 📩 Request Update... ',
      'color:Green;background:white;padding:5px;',
      files.length > 0 && files[0].size ? files[0] : ''
    );

    const fd = new FormData();
    fd.append('companyName', content.companyName);
    fd.append('explainerText', content.explainerText);
    fd.append('buttonText', content.buttonText);
    fd.append('headerFont', styling.headerFont);
    fd.append('bodyFont', styling.bodyFont);
    fd.append(
      'colorScheme',
      JSON.stringify({
        header: styling.header,
        body: styling.body,
        button: styling.button,
      })
    );

    if (content.onboardingLogo !== '')
      fd.append('logo', files.length > 0 && files[0].size ? files[0] : '');

    axios
      .post(`${getStarted.createOnboarding}`, fd)
      .then(({data}) => {
        console.log(
          '%c ✔ Onboarding succesffuly updated ',
          'color:white;background:green;padding:5px;',
          data
        );
        setSubmitLoading(false);
        setStep(2);
      })
      .catch((err) => {
        console.log('Save Branding Error', err.response.data);
        setSubmitLoading(false);
      });
  };
  // NOTE: To watch On input change and update the preview text

  useEffect(() => {
    const fontsTimeout = setTimeout(() => {
      setFontIsReady(true);
    }, 1000);

    return () => {
      clearTimeout(fontsTimeout);
    };
  }, []);

  return (
    <>
      <div className="wizard-steps">
        <div className={`wizard-steps__content__header `}>
          <span className="wizard-steps__number">1</span>
          <div className="wizard-steps__content__title">On-boarding page</div>
        </div>
        <div className="wizard-steps__content__body">
          <div className="config-wrapper">
            <div className="config-wrapper__left">
              <div className="form-wrapper v2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.companyName,
                    })}
                  >
                    <label>
                      <span>Company Name</span>
                      <input
                        name="companyName"
                        onChange={(e) =>
                          setContent({
                            ...content,
                            companyName: e.target.value,
                          })
                        }
                        ref={register({
                          required: 'Required',
                        })}
                        placeholder={`Burt's Bee Keeping`}
                      />
                    </label>
                  </div>

                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.explainerText,
                    })}
                  >
                    <label>
                      <span>Explainer Text</span>
                      <input
                        name="explainerText"
                        placeholder={`We just need to collect few more details....`}
                        onChange={(e) =>
                          setContent({
                            ...content,
                            explainerText: e.target.value,
                          })
                        }
                        ref={register({
                          required: 'Required',
                        })}
                      />
                    </label>
                  </div>

                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.buttonText,
                    })}
                  >
                    <label>
                      <span>Button Text</span>
                      <input
                        name="buttonText"
                        placeholder={`Activate my subscription`}
                        onChange={(e) =>
                          setContent({
                            ...content,
                            buttonText: e.target.value,
                          })
                        }
                        ref={register({
                          required: 'Required',
                        })}
                      />
                    </label>
                  </div>

                  <div className={clsx('input-wrapper')}>
                    <label>
                      <span>Header Font</span>
                      {fontIsReady && (
                        <FontPicker
                          families={fonts}
                          id="headerFont"
                          pickerId="headerFont"
                          apiKey={fontAPIKey}
                          activeFontFamily={styling.headerFont}
                          onChange={(nextFont) =>
                            setStyling((prevState) => ({
                              ...prevState,
                              headerFont: nextFont.family,
                            }))
                          }
                        />
                      )}
                    </label>
                  </div>
                  <div className={clsx('input-wrapper')}>
                    <label>
                      <span>Body Font</span>
                      {fontIsReady && (
                        <FontPicker
                          families={fonts}
                          id="bodyFont"
                          pickerId="bodyFont"
                          apiKey={fontAPIKey}
                          activeFontFamily={styling.bodyFont}
                          onChange={(nextFont) =>
                            setStyling((prevState) => ({
                              ...prevState,
                              bodyFont: nextFont.family,
                            }))
                          }
                        />
                      )}
                    </label>
                  </div>

                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.bodyFont,
                    })}
                  >
                    <div className="button-wrapper">
                      <span>Color Scheme</span>
                      <div className="palette-wrapper">
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.header.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  header: {
                                    ...styling.header,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.header.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  header: {
                                    ...styling.header,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Header</p>
                        </div>
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.body.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  body: {
                                    ...styling.body,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.body.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  body: {
                                    ...styling.body,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Body</p>
                        </div>
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.button.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  button: {
                                    ...styling.button,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.button.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  button: {
                                    ...styling.button,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Buttons</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.bodyFont,
                    })}
                  >
                    <label>
                      <span>Logo</span>
                      <UploadImage
                        ImgPreviewUrl={(preview) =>
                          setContent((prevState) => ({
                            ...prevState,
                            onboardingLogo: preview,
                          }))
                        }
                        isUploaded={isUploaded}
                        onImageSelect={(file) => setFiles(file)}
                        setIsUploaded={(isImageUploaded) =>
                          setIsUploaded(isImageUploaded)
                        }
                      />
                    </label>
                  </div>
                  <Button
                    text="Continue to email configuration"
                    className="btn-purple"
                    loading={submitLoading}
                  />
                </form>
              </div>
            </div>

            <div className="config-wrapper__right config-onboarding">
              <PreviewOnboarding
                styling={styling}
                content={content}
                actions={actions}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigureOnboarding;
