import axios from 'axios';
import {actions, settings} from './Urls';

export const checkIsEmptyOrNull = (data) => {
  return data === null || data === '';
};

export const removeSpaces = (string) => {
  return string.replaceAll(/\s/g, '');
};

export const getActionConfig = (planId, actionCode, addPromises) => {
  let promises = [
    axios.get(actions.getPlans + '?id=' + planId),
    axios.get(actions.getActions + `/${planId}/${actionCode}`),
    axios.get(settings.onboarding + '?type=onboarding'),
  ];

  if (typeof addPromises !== 'undefined')
    if (addPromises.length > 0) {
      for (let promise of addPromises) {
        promises.push(promise);
      }
    }

  return Promise.all(promises);
};
