import React from 'react';
import {Link} from 'react-router-dom';

const ActionList = ({actionList = [], planID, available_actions = []}) => {
  return (
    <div className="options">
      {actionList.map((action, index) => (
        <Link
          to={`/actions/${action.plan_id}/${action.action_code}`}
          key={action.action_code}
          className="options__link"
        >
          <div className="options__card">
            <p>{action.action_name}</p>
          </div>
        </Link>
      ))}

      {available_actions.length > 0 && (
        <Link to={`actions/${planID}`} className="options__link">
          <div className="options__card">
            <p>
              {actionList.length > 0
                ? 'Add Another Action'
                : 'Add First Action'}
            </p>
          </div>
        </Link>
      )}
    </div>
  );
};

export default ActionList;
