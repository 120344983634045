export const openTrengo = () => {
  window.Trengo = window.Trengo || {};
  window.Trengo.key = 'wlhXRDJXk5pIyiTHrWOE';
  (function (d, script, t) {
    script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.id = 'trengo';
    script.src = 'https://static.widget.trengo.eu/embed.js';
    d.getElementsByTagName('head')[0].appendChild(script);
  })(document);

  // Set initial render to false
  window.Trengo.render = false;

  // Events
  window.Trengo.on_ready = function () {
    // console.log('the widget is ready!');
    // console.log(window.Trengo);
    window.Trengo.Api.Widget.close();
    window.Trengo.Api.Widget.render();
  };
  window.Trengo.on_close = function () {
    // console.log('the widget is closed.');
    window.Trengo.Api.Widget.render();
    // console.log(window.Trengo);
    // setTimeout(function () {
    // }, 2500);
  };
};

export const signInTrengo = (email, name) => {
  // Close Trengo first, if it is opened previously
  window.Trengo.Api.Widget.close();

  // Set contact data
  // console.log('Signing in trengo:', email, name);
  window.Trengo = window.Trengo || {};

  // Initializing data
  window.Trengo.contact_data = {
    email,
    name,
  };

  window.Trengo.ticket_data = {
    email,
    name,
  };

  // Render new data to trengo
  window.Trengo.Api.Widget.render();
  window.Trengo.Api.Widget.url_updated();
};

export const logoutTrengo = () => {
  // re-initialize trengo
  if (!!document.querySelector('#trengo'))
    document.querySelector('#trengo').remove();
  if (!!document.querySelector('#trengo-web-widget'))
    document.querySelector('#trengo-web-widget').remove();
};

export const reRenderTrengo = () => {
  window.Trengo = window.Trengo || {};
  // Removing data
  delete window.Trengo.contact_data;
  delete window.Trengo.ticket_data;
  logoutTrengo();
  openTrengo();
};
