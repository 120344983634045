import React from 'react';
import {Link} from 'react-router-dom';

import LayoutDark from '../layout/LayoutDark';

const NotFoundPage = () => (
  <LayoutDark>
    <div className="container">
      <div className="not-found">
        <div className="content-wrapper">
          <h2>404: There's nothing to see here</h2>
          <Link to="/" className="btn-purple">
            Go home
          </Link>
        </div>
      </div>
    </div>
  </LayoutDark>
);

export default NotFoundPage;
