import React, {useState, useEffect} from 'react';

import LayoutDashboard from '../../layout/LayoutDashboard';
import Breadcrumbs from '../../components/Breadcrumbs';
import PreviewWebForm from '../../components/PreviewWebForm';
import {settings, actions} from '../../helpers/Urls';
import axios from 'axios';
import {useParams} from 'react-router-dom';

const EditWebForm = () => {
  const {planId} = useParams();
  const [planName, setPlanName] = useState();
  const [loading, setLoading] = useState(true);
  const [actionsState, setActionsState] = useState([]);
  const [styling, setStyling] = useState({
    header: {
      bg: '',
      color: '',
    },
    body: {
      bg: '',
      color: '',
    },
    button: {
      bg: '',
      color: '',
    },
    bodyFont: '',
    headerFont: '',
  });

  const [content, setContent] = useState({
    buttonText: '',
    companyName: '',
    explainerText: '',
    onboardingLogo: '',
    customerName: '',
  });

  useEffect(() => {
    axios
      .get(settings.onboarding, {
        params: {
          type: 'onboarding',
        },
      })
      .then(({data: onboardingData}) => {
        const colorScheme = JSON.parse(onboardingData?.color_scheme);

        setStyling((prevState) => ({
          ...prevState,
          ...colorScheme,
          bodyFont: onboardingData?.body_font,
          headerFont: onboardingData?.header_font,
        }));

        setContent((prevState) => ({
          ...prevState,
          buttonText: onboardingData?.button_text,
          companyName: onboardingData?.company_name,
          explainerText: onboardingData?.explainer_text,
          onboardingLogo: onboardingData?.onboarding_logo,
        }));

        setLoading(false);
      })
      .catch((err) => setLoading(false));

    axios.get(`${actions.getActions}/${planId}`).then(({data}) => {
      console.log(
        '%c ⚠ actions ',
        'color:yellow;background:black;padding:5px;',
        data
      );
      setPlanName(data.plan_name);
      setActionsState(data.actions);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <LayoutDashboard className="edit-web-form" pageLoading={loading}>
      <Breadcrumbs link="/actions" text={`Web Form for ${planName} `} />
      <PreviewWebForm
        styling={styling}
        content={content}
        planName={planName}
        actions={actionsState}
        planId={planId}
      />
    </LayoutDashboard>
  );
};

export default EditWebForm;
