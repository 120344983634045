import React, {useEffect} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {hot} from 'react-hot-loader';
import history from './history';

import Login from './containers/Login';
import Forgot from './containers/Forgot';
import Register from './containers/Register';
import ResetPassword from './containers/ResetPassword';
import About from './containers/About';
import PageNotFound from './containers/404';
import PrivateRoute from './components/routing/PrivateRoute';

import GetStarted from './containers/GetStarted';
import Subscriptions from './containers/Subscriptions';
import SubscriptionsInfo from './containers/SubscriptionsInfo';
import Actions from './containers/Actions';
import EventLog from './containers/EventLog';
import Settings from './containers/Settings';
import MySubscription from './containers/MySubscription';
import MyAccount from './containers/MyAccount';
import Customers from './containers/Customers';
import CustomerRegistration from './containers/CustomerRegistration';
// settings
import Branding from './containers/settings/Branding';
import Billsby from './containers/settings/Billsby';
import Chargebee from './containers/settings/Chargebee';
import Email from './containers/settings/Email';
//actions
import Mailchimp from './containers/actions/emails/Mailchimp';
import MailerLite from './containers/actions/emails/MailerLite';
import GetResponse from './containers/actions/emails/GetResponse';
import ConstantContact from './containers/actions/emails/ConstantContact';
import AWeber from './containers/actions/emails/AWeber';
import SelectPlan from './containers/actions/SelectPlan';
import EditWebForm from './containers/actions/EditWebForm';
//Redux
import {Provider} from 'react-redux';
import store from './store';
import {loadUser} from './actions/auth';
// import {loadProxyUser} from './actions/account';
import setAuthToken from './utils/setAuthToken';

import './stylesheets/main.scss';
import {openTrengo} from './lib/trengo';

// Test Pages
import OAuthRedirect from './containers/OAuthRedirect';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    openTrengo();
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route path="/registration" exact component={Register} />
            <Route path="/login" exact component={Login} />
            <Route path="/login/forgot" component={Forgot} />
            <Route path="/login/reset" exact component={ResetPassword} />
            <Route path="/about" component={About} />
            <Route path="/registration/:cid" component={CustomerRegistration} />

            {/* protected routes DASHBOARD  */}
            {/* <Route path="/get-started" component={GetStarted} /> */}

            <PrivateRoute path="/" exact component={GetStarted} />
            <PrivateRoute path="/admin/customers" exact component={Customers} />
            <PrivateRoute
              path="/subscriptions"
              exact
              component={Subscriptions}
            />
            <PrivateRoute
              path="/subscriptions-info/"
              component={SubscriptionsInfo}
            />
            <PrivateRoute
              path="/subscriptions/:getOnboardlyId"
              exact
              component={SubscriptionsInfo}
            />

            {/* Actions */}
            <PrivateRoute path="/actions" exact component={Actions} />

            <PrivateRoute
              // path="/actions/edit-web-form/:id"
              path="/actions/edit-web-form/:planId"
              exact
              component={EditWebForm}
            />

            {/* <PrivateRoute
              path="/actions/edit-web-form/:id"
              exact
              component={EditWebForm}
            /> */}

            <PrivateRoute path="/actions/:id" exact component={SelectPlan} />

            <PrivateRoute
              path="/actions/:planId/mailchimp"
              exact
              component={Mailchimp}
            />

            <PrivateRoute
              path="/actions/:planId/mailerlite"
              exact
              component={MailerLite}
            />

            <PrivateRoute
              path="/actions/:planId/getresponse"
              exact
              component={GetResponse}
            />

            <PrivateRoute
              path="/actions/:planId/constant_contact"
              exact
              component={ConstantContact}
            />

            <PrivateRoute
              path="/actions/:planId/aweber"
              exact
              component={AWeber}
            />

            <PrivateRoute path="/event-log" component={EventLog} />
            <PrivateRoute path="/settings" exact component={Settings} />

            <PrivateRoute
              path="/settings/branding"
              exact
              component={Branding}
            />
            <PrivateRoute path="/settings/billsby" component={Billsby} />
            <PrivateRoute path="/settings/chargebee" component={Chargebee} />
            <PrivateRoute path="/settings/emails" component={Email} />

            <PrivateRoute path="/my-subscription" component={MySubscription} />
            <PrivateRoute path="/account" component={MyAccount} />

            <PrivateRoute
              path="/oauth/redirect"
              exact
              component={OAuthRedirect}
            />

            {/* <Route component={Dashboard404} /> */}
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default hot(module)(App);
