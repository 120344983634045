import React, {memo, useEffect, useState} from 'react';
import FontPicker from 'font-picker-react';
import {fonts} from '../helpers/StaticVariables';
const fontAPIKey = process.env.REACT_APP_GOOGLEFONTS_API_KEY;

const FontPickerHidden = ({headerFont, bodyFont}) => {
  console.log(
    '%c 🔥 memoized rendered ',
    'color:yellow;background:black;padding:5px;'
  );
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const fontsTimeout = setTimeout(() => {
      setIsReady(true);
    }, 1000);

    return () => {
      clearTimeout(fontsTimeout);
    };
  }, []);

  return (
    <div className="font-style-hax" style={{display: 'none'}}>
      {isReady && (
        <>
          <FontPicker
            families={fonts}
            id="headerFont"
            apiKey={fontAPIKey}
            activeFontFamily={headerFont}
            pickerId="headerFont"
          />

          <FontPicker
            families={fonts}
            id="bodyFont"
            apiKey={fontAPIKey}
            activeFontFamily={bodyFont}
            pickerId="bodyFont"
          />
        </>
      )}
    </div>
  );
};

export const MemoizedFontPicker = memo(FontPickerHidden);
