import React, {useState, useEffect} from 'react';
import ColorPicker from '../ColorPicker';
import FontPicker from 'font-picker-react';
import UploadImage from '../UploadImage';
import {fonts} from '../../helpers/StaticVariables';
import clsx from 'clsx';
import {settings} from '../../helpers/Urls';
import axios from 'axios';
import Button from '../../components/Buttons';
import {toast} from 'react-toastify';

import {useForm} from 'react-hook-form';

const fontAPIKey = process.env.REACT_APP_GOOGLEFONTS_API_KEY;

const ConfigureOnboarding = ({
  content,
  styling,
  setStyling,
  setFiles,
  setIsUploaded,
  isUploaded,
  setContent,
  files,
}) => {
  const {register, handleSubmit, errors} = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fontIsReady, setFontIsReady] = useState(false);

  const onSubmit = () => {
    setSubmitLoading(true);
    console.log(
      '%c 📩 Request Update... ',
      'color:Green;background:white;padding:5px;',
      files.length > 0 && files[0].size ? files[0] : ''
    );

    const fd = new FormData();
    fd.append('companyName', content.companyName);
    fd.append('explainerText', content.explainerText);
    fd.append('buttonText', content.buttonText);
    fd.append('headerFont', styling.headerFont);
    fd.append('bodyFont', styling.bodyFont);
    fd.append(
      'colorScheme',
      JSON.stringify({
        header: styling.header,
        body: styling.body,
        button: styling.button,
      })
    );

    if (content.onboardingLogo !== '')
      fd.append('logo', files.length > 0 && files[0].size ? files[0] : '');

    axios
      .put(settings.updateOnboarding, fd)
      .then(({data}) => {
        console.log(
          '%c ✔ Onboarding succesffuly updated ',
          'color:white;background:green;padding:5px;',
          data
        );
        setSubmitLoading(false);

        toast.success('Settings saved succesfully', {});
      })
      .catch((err) => {
        console.log('Save Branding Error', err.response.data);
        setSubmitLoading(false);
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    const fontsTimeout = setTimeout(() => {
      setFontIsReady(true);
    }, 1000);

    return () => {
      clearTimeout(fontsTimeout);
    };
  }, []);

  return (
    <div className="branding">
      <div className="form-wrapper v2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="duo-wrapper">
            <div className="left">
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.companyName,
                })}
              >
                <label>
                  <span>Company Name</span>
                  <input
                    onChange={(e) =>
                      setContent({
                        ...content,
                        companyName: e.target.value,
                      })
                    }
                    name="companyName"
                    defaultValue={content?.companyName}
                    ref={register({
                      required: 'Required',
                    })}
                    placeholder={`Burt's Bee Keeping`}
                  />
                </label>
              </div>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.buttonText,
                })}
              >
                <label>
                  <span>Button Text</span>
                  <input
                    name="buttonText"
                    defaultValue={content?.buttonText}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        buttonText: e.target.value,
                      })
                    }
                    placeholder={`Activate my subscription`}
                    ref={register({
                      required: 'Required',
                    })}
                  />
                </label>
              </div>
              <div className={clsx('input-wrapper')}>
                <label>
                  <span>Header Font</span>
                  {fontIsReady && (
                    <FontPicker
                      families={fonts}
                      id="headerFont"
                      pickerId="headerFont"
                      apiKey={fontAPIKey}
                      activeFontFamily={styling?.headerFont ?? 'Roboto'}
                      onChange={(nextFont) =>
                        setStyling({
                          ...styling,
                          headerFont: nextFont.family,
                        })
                      }
                    />
                  )}
                </label>
              </div>
              <div className={clsx('input-wrapper')}>
                <label>
                  <span>Body Font</span>
                  {fontIsReady && (
                    <FontPicker
                      families={fonts}
                      id="bodyFont"
                      pickerId="bodyFont"
                      apiKey={fontAPIKey}
                      activeFontFamily={styling?.bodyFont ?? 'Roboto'}
                      onChange={(nextFont) =>
                        setStyling({
                          ...styling,
                          bodyFont: nextFont.family,
                        })
                      }
                    />
                  )}
                </label>
              </div>
            </div>
            <div className="right">
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.explainerText,
                })}
              >
                <label>
                  <span>Explainer Text</span>
                  <input
                    name="explainerText"
                    defaultValue={content?.explainerText}
                    placeholder={`We just need to collect few more details....`}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        explainerText: e.target.value,
                      })
                    }
                    ref={register({
                      required: 'Required',
                    })}
                  />
                </label>
              </div>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.bodyFont,
                })}
              >
                <div className="button-wrapper">
                  <span>Color Scheme</span>
                  <div className="palette-wrapper">
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.header?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              header: {
                                ...styling.header,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.header?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              header: {
                                ...styling.header,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Header</p>
                    </div>
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.body?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              body: {
                                ...styling.body,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.body?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              body: {
                                ...styling.body,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Body</p>
                    </div>
                    <div className="palette-wrapper__single">
                      <div className="palette-wrapper__colors">
                        <ColorPicker
                          defaultColor={styling?.button?.bg}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              button: {
                                ...styling.button,
                                bg: color,
                              },
                            })
                          }
                        />
                        <ColorPicker
                          defaultColor={styling?.button?.color}
                          callback={(color) =>
                            setStyling({
                              ...styling,
                              button: {
                                ...styling.button,
                                color: color,
                              },
                            })
                          }
                        />
                      </div>
                      <p>Buttons</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={clsx('input-wrapper', {
                  'input-error': errors.bodyFont,
                })}
              >
                <div className="button-wrapper">
                  <span>Logo</span>
                  <UploadImage
                    ImgPreviewUrl={(preview) =>
                      setContent({
                        ...content,
                        onboardingLogo: preview,
                      })
                    }
                    isUploaded={isUploaded}
                    onImageSelect={(file) => setFiles(file)}
                    setIsUploaded={(isImageUploaded) =>
                      setIsUploaded(isImageUploaded)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Button
            className="btn-purple"
            loading={submitLoading}
            text="Update branding settings"
          />
        </form>
      </div>
    </div>
  );
};

export default ConfigureOnboarding;
