import React, {useState, useEffect} from 'react';

const WizardButton = ({
  handleNextStep,
  step,
  isSavedWebhookForwarding,
  isBothBlanked,
  type,
}) => {
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    if (step === 3)
      if (isBothBlanked)
        if (isBothBlanked.clicked) return setHideButton(false);
        else return setHideButton(true);

    // eslint-disable-next-line
  }, [isBothBlanked ?? null]);

  const renderButton = () => {
    switch (step) {
      case 1:
        return 'Next Step';
      case 2:
        return 'Next Step';
      case 3:
        if (isBothBlanked)
          if (isSavedWebhookForwarding || isBothBlanked?.both)
            return 'We’ll move to the next step automatically after the test web hook is received';
          else return 'Next Step';
        else
          return 'We’ll move to the next step automatically after the test web hook is received';
      case 4:
        return 'I’ve done it! Connect ' + type;

      default:
        return '';
    }
  };

  return (
    <>
      {!hideButton && (
        <button className="btn-purple" onClick={handleNextStep}>
          {renderButton()}
        </button>
      )}
    </>
  );
};

export default WizardButton;
