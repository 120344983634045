import React, {useState, useEffect} from 'react';

import LayoutDashboard from '../../../layout/LayoutDashboard';
import Breadcrumbs from '../../../components/Breadcrumbs';
import {actions} from '../../../helpers/Urls';

import Banner from '../../../components/actions/newsletters/Banner';
import {ConstantContactActionForm} from '../../../components/actions/forms';
import {useParams} from 'react-router-dom';
import axios from 'axios';

const ActionConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');
  let {planId} = useParams();

  // TODO: handle fetch planName here -- Apply for all actions

  useEffect(() => {
    axios.get(actions.getPlans + '?id=' + planId).then(({data: plan}) => {
      setPlanName(plan.plan_name);
    });
  }, []);

  const onSubmit = (isSuccess) => {
    console.log(isSuccess);
  };
  return (
    <LayoutDashboard pageLoading={loading} className="action-configuration">
      <Breadcrumbs link="/actions" text={`Actions for ${planName}`} />
      <ConstantContactActionForm
        loadin={loading}
        planId={planId}
        onSubmit={onSubmit}
      />
    </LayoutDashboard>
  );
};

export default ActionConfiguration;
