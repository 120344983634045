import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';

import siteLogo from '../assets/images/getonboard.ly-admin@2x.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

import {connect} from 'react-redux';
import {clearAccount} from '../actions/account';
import {loadUser} from '../actions/auth';

function SideBar({clearAccount, loadUser}) {
  return (
    <div className="sidebar-wrapper sidebar-admin">
      <div className="logo-wrapper">
        <img src={siteLogo} alt="logo" />
      </div>
      <div className="linklist">
        <div className="linklist__top">
          <ul>
            <li className="linklist__item">
              <NavLink
                activeClassName="is-active"
                className="nav-link"
                to="/admin/customers"
              >
                <FontAwesomeIcon icon={faUser} />
                <span>Customers</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="linklist__bottom">
          <ul>
            <li
              onClick={() => {
                clearAccount();
                loadUser();
              }}
              className="linklist__item"
            >
              <NavLink
                activeClassName="is-active"
                className="nav-link"
                to="/"
                isActive={(match, location) => {
                  console.log(match);
                  return match.isExact;
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Exit admin mode</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  clearAccount: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
};

export default connect(null, {clearAccount, loadUser})(SideBar);
