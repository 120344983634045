import React, {useState, useEffect, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import { Helmet } from 'react-helmet'

import Dropdown from './Dropdown';
import siteLogo from '../assets/images/getonboard.ly@2x.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const ref = useRef(null);

  const headerOnScroll = () => {
    if (document)
      document.getElementsByTagName('body')[0].onscroll = (e) => {
        if (document.getElementsByTagName('header').length > 0) {
          if (window.scrollY <= 15) {
            document
              .getElementsByTagName('header')[0]
              .classList.remove('header-onscroll');
          } else {
            document
              .getElementsByTagName('header')[0]
              .classList.add('header-onscroll');
          }
        }
      };
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    headerOnScroll();
  });

  const planData = {
    billsby: {
      productId: 6352,
      planId: 8794,
      cycleId: 11339,
    },
    chargebee: {
      productId: 6353,
      planId: 8796,
      cycleId: 11341,
    },
  };

  return (
    <header className={clsx('header', {'header-menu-open': isOpen})} ref={ref}>
      <Helmet>
        <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
        <noscript>{`<img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" />`}</noscript>
      </Helmet>
      <div className="container">
        <div className="header-wrapper">
          <a
            href="https://getonboard-ly-marketing.onrender.com/"
            className="logo-link"
          >
            <img className="logo" src={siteLogo} alt="Logo" />
          </a>
          <div className={clsx('menu-wrapper', {'menu-open': isOpen})}>
            <div className="menu-link-wrapper">
              <ul className="links-wrapper">
                <li>
                  <a
                    href="https://getonboard-ly-marketing.onrender.com/"
                    className="header-link"
                  >
                    Product
                  </a>
                </li>
                <li>
                  <a
                    href="https://getonboard-ly-marketing.onrender.com/pricing/"
                    className="header-link"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="https://getonboard-ly-marketing.onrender.com/workswith/"
                    className="header-link"
                  >
                    Works with
                  </a>
                </li>
              </ul>
              <div className="sign-in-wrapper">
                <NavLink
                  to="/"
                  className="header-link"
                  activeClassName="is-active"
                >
                  Login
                </NavLink>
                <div className="btn-holder">
                  <button
                    className="btn-purple"
                    onClick={() => setIsShown(!isShown)}
                  >
                    Start your one month free trial
                  </button>
                  {isShown && <Dropdown data={planData} />}
                </div>
              </div>
            </div>
          </div>
          <button
            className={clsx('toggle-menu burger-close', {
              'burger-open': isOpen,
            })}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
