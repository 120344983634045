import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faEye,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import ReactPaginate from 'react-paginate';

import LayoutAdmin from '../layout/LayoutAdmin';
import BeatLoader from 'react-spinners/BeatLoader';

import {connect} from 'react-redux';
import {
  loadProxyUser,
  getAllAccount,
  getPagedAccount,
} from '../actions/account';
import {infoLog} from '../helpers/Logger';
function Customers({loadProxyUser, getAllAccount, accounts, getPagedAccount}) {
  const [customerList, setCustomerList] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);

  // const itemCountPerPage = 10;

  let history = useHistory();
  const handleImpersonate = async (email) => {
    //set loader here
    setLoading(true);
    await loadProxyUser(email);
    setLoading(false);
    history.push('/');
  };

  useEffect(() => {
    setCustomerList(accounts.results ?? []);
    setLoading(false);
  }, [accounts]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      infoLog('Debounced request query', '');
      getPagedAccount(1, 10, 'desc', query);
      setLoading(true);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    //eslint-disable-next-line
  }, [query]);

  const handlePageChange = async ({selected}) => {
    const page = selected + 1;
    await getPagedAccount(page, 10);
  };

  return (
    <LayoutAdmin className="dashboard-admin">
      {loading && (
        <div className="loader-wrapper">
          <BeatLoader loading={loading} color="white" />
        </div>
      )}
      <div className="customers">
        <div className="section-top">
          <div className="filter-wrapper">
            <p>{customerList.length} Customers</p>
            <div className="filter-search">
              <label>
                <input
                  type="text"
                  placeholder="Search selected customers by name, email or customer ID"
                  name="query"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </label>
            </div>
          </div>
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
              nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={!!accounts.pageCount ? accounts.pageCount : 1}
              // pageCount={25}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </div>
        </div>
        <div className="table-wrapper">
          <div className="table">
            <div className="row-wrapper">
              <div className="column-wrapper">
                <p className="table-heading">Email</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Full name</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Created on</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Type</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Subscription ID</p>
              </div>
              <div className="column-wrapper"></div>
            </div>
            {!!customerList.length ? (
              customerList.map(
                ({email, full_name, created_on, type, subscription_id}, i) => (
                  <div className="row-wrapper" key={i}>
                    <div className="column-wrapper">
                      <p className="table-info">{email}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">{full_name}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">{created_on}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">{type}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">{subscription_id}</p>
                    </div>
                    <div className="column-wrapper">
                      <button onClick={() => handleImpersonate(email)}>
                        <FontAwesomeIcon icon={faEye} />
                        Impersonate
                      </button>
                    </div>
                  </div>
                )
              )
            ) : (
              <p style={{padding: '20px'}}>No results found</p>
            )}
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
}

Customers.propTypes = {
  loadProxyUser: PropTypes.func.isRequired,
  getAllAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.account,
  accounts: state.account.accounts,
  filteredAccounts: state.account.filteredAccounts,
});

export default connect(mapStateToProps, {
  loadProxyUser,
  getAllAccount,
  getPagedAccount,
})(Customers);
