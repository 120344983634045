import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';

import Modal from './shared/Modal';
import {getActions} from '../actions/actions';
import {getAllAccount, getCurrentAccount} from '../actions/account';
import ActionForm from '../components/wizard/ActionForm';
import useQuery from '../utils/useQuery';

const FirstAction = ({
  show,
  handleClose,
  actionList = [],
  available_actions = [],
  fetchLinkedAccount,
  plan_name,
  plan_id,
}) => {
  let query = useQuery();
  const defaultPlanId = query.get('planId');
  const defaultActionCode = query.get('actionCode');

  var {id} = useParams();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.actions.plans);
  const account = useSelector((state) => state.account);
  const [step, setStep] = useState(1);
  const [onboardedCustomer, setOnboardedCustomer] = useState({
    plan_name: null,
    available_actions: [],
  });
  const [planId, setPlanId] = useState();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');

  // Action Form State ========
  const [actionCode, setActionCode] = useState('mailchimp');
  const [activePlan, setActivePlan] = useState({});
  // Action Form State END ========

  const handleStep = () => {
    setStep(step + 1);
  };

  const submitCallback = (isSuccess) => {
    console.log(isSuccess);

    if (isSuccess) {
      fetchLinkedAccount();
      handleClose();
    }
  };

  const closeAndReset = () => {
    setStep(1);
    handleClose();
  };

  const handleTitle = () => {
    if (step === 1) {
      setTitle('Select Plan');
    }

    if (step === 3) {
      setTitle('Action');
    }
  };

  useEffect(() => {
    dispatch(getActions());
  }, [dispatch, account]);

  useEffect(() => {
    if (defaultPlanId) {
      setPlanId(defaultPlanId);
      setActionCode(defaultActionCode);
      setStep(3);
    }
  }, []);

  useEffect(() => {
    handleTitle();
  }, [step]);

  return (
    <Modal show={show} title={title} handleClose={closeAndReset}>
      <div className="modal-body modal-first-action">
        <div className="content-wrapper">
          {step === 1 && (
            <>
              <p>Select a plan to create your first action</p>
              <section className="cards no-bg">
                {plans.map(({plan_name, plan_id, ...plan}, i) => (
                  <section
                    key={i}
                    className="cards__item"
                    onClick={() => {
                      setActivePlan({...plan, plan_id, plan_name});
                      setPlanId(plan_id);
                      handleStep();
                      setTitle(plan_name);
                    }}
                  >
                    <p className="dollar-icon">$</p>
                    <p>{`${plan_name}`}</p>
                    <FontAwesomeIcon icon={faAngleRight} />
                  </section>
                ))}
              </section>
            </>
          )}
          {step === 2 && (
            <>
              <p>Choose your first action</p>
              <section className="actions no-bg">
                {activePlan.available_actions.length ? (
                  activePlan?.available_actions?.map(
                    ({action_name, action_code}) => (
                      <div
                        key={action_code}
                        className="options__card"
                        onClick={() => {
                          setActionCode(action_code);
                          handleStep();
                        }}
                      >
                        <p>{action_name}</p>
                      </div>
                    )
                  )
                ) : (
                  <p>No actions Found</p>
                )}
              </section>
            </>
          )}
          {step === 3 && (
            <section className="no-padding">
              <ActionForm
                actionCode={actionCode}
                planId={planId}
                onSubmit={submitCallback}
              />
            </section>
          )}
        </div>
      </div>
    </Modal>
  );
};

FirstAction.propTypes = {
  getAllAccount: PropTypes.func.isRequired,
  getCurrentAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account?.account,
  accounts: state.account?.accounts,
  filteredAccounts: state.account?.filteredAccounts,
  onboardedCustomers: state.account?.accounts.onboardedCustomers,
});

export default connect(mapStateToProps, {
  getAllAccount,
  getCurrentAccount,
})(FirstAction);
