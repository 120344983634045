import React from 'react';
import emailBg from '../assets/images/email-bg.png';

const PreviewEmail = ({styling, content}) => {
  const headerFontStyle = {
    color: styling?.header?.color,
    fontFamily: styling?.font,
  };

  return (
    <div className="preview-email">
      <img src={emailBg} alt="" />
      <div className="content">
        <div
          className="content__banner"
          style={{
            background: styling?.header?.bg,
            color: styling?.header?.color,
            fontFamily: styling?.font,
          }}
        >
          <img src={content.emailLogo} alt="" />
          <h2 style={headerFontStyle}>Activate your subscription</h2>
        </div>
        <div
          className="content__body"
          style={{
            background: styling.body.bg,
          }}
        >
          <div className="container">
            <p
              className="content__description"
              style={{
                background: styling?.body?.bg,
                color: styling?.body?.color,
                fontFamily: styling?.font,
              }}
            >
              Now that you’ve signed up, you just need to activate your
              subscription so that you can access our services.
            </p>
            <div className="form-wrapper v2">
              <form>
                <button
                  type="submit"
                  className="btn"
                  disabled
                  style={{
                    background: styling?.button?.bg,
                    color: styling?.button?.color,
                    font: styling?.font,
                    opacity: '1',
                  }}
                >
                  Activate my subscription
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewEmail;
