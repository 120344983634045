import React, {useState, useEffect} from 'react';
import LayoutDark from '../layout/LayoutDark';
import {useForm} from 'react-hook-form';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';

import {reRenderTrengo} from '../lib/trengo';

import BeatLoader from 'react-spinners/BeatLoader';

import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {login} from '../actions/auth';

function Login({history, login, auth, isAuthenticated}) {
  const {register, handleSubmit, errors} = useForm();
  const [requestLoading, setRequestLoading] = useState(false);

  const onSubmit = async (data) => {
    setRequestLoading(true);
    const {email, password} = data;
    await login(email, password);
    reRenderTrengo();
    setRequestLoading(false);
  };

  const handleRedirect = () => {
    if (isAuthenticated) {
      console.log(isAuthenticated);
      if (auth?.user?.live) history.push('/settings');
      else history.push('/');
    }
  };

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line
  }, [auth, isAuthenticated]);

  return (
    <div className="login-page">
      <LayoutDark>
        <div className="container">
          <h1 className="page-title">Login to GetOnboard.ly</h1>

          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={clsx('input-wrapper', {'input-error': errors.email})}
              >
                <label>
                  <span>Email address</span>
                  <input
                    name="email"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    })}
                  />
                </label>
                {errors.email && (
                  <span className="error-text" role="alert">
                    {errors.email.message}
                  </span>
                )}
              </div>

              <div
                className={clsx('input-wrapper', {
                  'input-error': auth?.message,
                })}
              >
                <label>
                  <span>Password</span>
                  <input ref={register} type="password" name="password" />
                </label>
                {auth?.message && (
                  <span className="error-text" role="alert">
                    {auth?.message}
                  </span>
                )}
              </div>

              <button type="submit" className="btn-purple">
                Login to my account
                <BeatLoader loading={requestLoading} color="white" />
              </button>
            </form>
            <div className="form-footer">
              <NavLink to="/login/forgot" className="forgot">
                Forgotten password
              </NavLink>
            </div>
          </div>
        </div>
      </LayoutDark>
    </div>
  );
}
Login.propTypes = {
  login: PropTypes.func.isRequired,
  auth: PropTypes.object,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {login})(Login);
