import React, {useState, useEffect, useRef} from 'react';
import {SketchPicker} from 'react-color';

const ColorPicker = ({defaultColor, callback}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(null);
  const clickAwayRef = useRef();

  const handleColorChange = (color) => {
    setColor(color.hex);

    //callback
    callback(color.hex);
  };

  const handleClickAway = (e) => {
    if (clickAwayRef?.current?.contains(e.target)) {
      return;
    }
    setShowPicker(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  return (
    <div className="picker-wrapper">
      <button
        type="button"
        style={{
          background: color,
        }}
        className="btn-color"
        onClick={() => setShowPicker(true)}
      ></button>
      {showPicker && (
        <div ref={clickAwayRef} className="pallet-modal-wrapper">
          <SketchPicker color={color} onChange={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
