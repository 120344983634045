import React, {useState, useEffect} from 'react';
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {eventLog} from '../helpers/Urls';
import {
  faSearch,
  faCaretLeft,
  faCaretRight,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import axios from 'axios';
import {useSelector} from 'react-redux';

import LayoutDashboard from '../layout/LayoutDashboard';
import FailedEvent from '../components/FailedEvent';

function EventLog() {
  const [logs, setLogs] = useState([]);
  const [query, setQuery] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [showFailedEvent, setShowFailedEvent] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const account = useSelector((state) => state.account);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null) ;

  // JSX returns
  const TableRow = ({
    list: {status, getonboardly_id, type, event_for, added_to_database, email, json_response, id},
  }) => (
    <div className={clsx('row-wrapper', {'is-failed': status === 'Failed'})}>
      <div className="column-wrapper">
        <p className="table-info">{email}</p>
      </div>
      <div className="column-wrapper">
        <p className="table-info">{added_to_database}</p>
      </div>
      <div className="column-wrapper">
        <p>{event_for}</p>
      </div>
      <div className="column-wrapper">
        <p className="table-info">{type}</p>
      </div>
      <div className="column-wrapper is-id">
        <p className="table-info">{getonboardly_id}</p>
      </div>
      <div className="column-wrapper">
        <p className="table-info">{status}</p>
      </div>
      <div className="column-wrapper">
        <button onClick={() => handleData({getonboardly_id, added_to_database, json_response, id})}>
          <FontAwesomeIcon icon={faEye} />
        </button>
      </div>
    </div>
  );
  const TableHead = () => (
    <div className="row-wrapper">
      <div className="column-wrapper">
        <p className="table-heading">Email</p>
      </div>
      <div className="column-wrapper">
        <p className="table-heading">Attempted</p>
      </div>
      <div className="column-wrapper">
        <p className="table-heading">Action</p>
      </div>
      <div className="column-wrapper">
        <p className="table-heading">Type</p>
      </div>
      <div className="column-wrapper">
        <p className="table-heading">GetOnboard.ly ID</p>
      </div>
      <div className="column-wrapper">
        <p className="table-heading">Status</p>
      </div>
      <div className="column-wrapper"></div>
    </div>
  );
  // JSX returns END

  const handlePageChange = ({selected}) => {
    console.log(selected);
    const page = selected + 1;
    setActivePage(page);
  };

  const getResults = (page = 1, query = '') =>
    axios
      .get(eventLog.logs, {
        params: {
          page,
          search: query,
        },
      })
      .then(({data}) => data)
      .catch((err) => console.log(err));

  const handleData = (payload) => {
    setData(payload)
    setShowFailedEvent(true)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      const results = await getResults(activePage, query);
      setLoading(false);
      setLogs(results.results);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    //eslint-disable-next-line
  }, [query, activePage]);

  // usses account as dependency to make sure that proxy token is set
  useEffect(() => {
    (async () => {
      const results = await getResults();
      setLogs(results.results);
      setPageCount(results.pageCount);
      setLoading(false);
    })();
  }, [account]);

  return (
    <LayoutDashboard className="event-log" pageLoading={loading}>
      <div className="section-top">
        <div className="filter-wrapper">
          <div></div>
          <p>{logs?.length} events</p>
          <div className="filter-search">
            <label>
              <input
                type="text"
                placeholder="Search events by email, action, type, GetOnboardly ID or status"
                name="query"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <button>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </label>
          </div>
        </div>
        <div className="pagination-wrapper">
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
            nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            subContainerClassName={'page-item'}
            activeClassName={'active'}
          />
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table">
          <TableHead />
          {!logs.length ? (
            <p style={{padding: '20px'}}>No results found</p>
          ) : (
            logs.map((result, i) => <TableRow key={i} list={result} />)
          )}
        </div>
      </div>

      <FailedEvent
        show={showFailedEvent}
        handleClose={() => setShowFailedEvent(false)}
        logs={data}
      />
    </LayoutDashboard>
  );
}

export default EventLog;
