import React from 'react';
import {customStyles} from '../../../helpers/ActionsVariables';
import clsx from 'clsx';
import Select from 'react-select';
import Toggler from '../../Toggler';

const Groupings = ({
  description,
  options,
  value,
  onChange,
  children,
  removeToggleHandle,
  removeToggleValue,
}) => {
  return (
    <section>
      <p>{description}</p>
      <div className={clsx('input-wrapper', {})}>
        <label>
          <span>Add to Group</span>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={options}
            placeholder="[SELECT LIST NAME]"
            value={value}
            isMulti
            onChange={onChange}
            styles={customStyles}
          />
        </label>
      </div>
      <div style={{display: 'flex'}}>
        <Toggler
          title="Remove from all other groups"
          setIsToggled={removeToggleHandle}
          isToggled={removeToggleValue}
        />
        {children}
      </div>
    </section>
  );
};

export default Groupings;
