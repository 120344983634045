import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import ConfigureOnboarding from './branding/ConfigureOnboarding';
import Modal from './shared/Modal';

import ConfigureEmail from './branding/ConfigureEmail';

const BrandingSetup = ({show, handleClose, branding, fetchLinkedAccount}) => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (branding?.onboarding?.configured) setStep(2);
  }, [branding]);

  return (
    <Modal show={show} title="Configure Branding" handleClose={handleClose}>
      <div className="modal-body">
        <p>
          The next step is to configure the branding we use on your
          GetOnboard.ly page and on the emails we send out to your customers.
          Customers see the GetOnboard.ly page after the've finished checkout if
          we need to collect additional information to complete actions (like
          the one you saw when you signed up and we needed to have you set a
          password!). Customers get a serires of emails from us if we see that
          they've signed up, but haven't yet activated thery subscription. For
          now, let's just configure basics.
        </p>

        <div className="wizard-container">
          <div className="wizard-steps__content">
            {step === 1 && <ConfigureOnboarding setStep={setStep} />}

            {step === 2 && (
              <ConfigureEmail
                handleClose={() => handleClose()}
                fetchLinkedAccount={fetchLinkedAccount}
              />
            )}
          </div>
        </div>
      </div>
      {/* <div className="modal-footer"></div> */}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  onBoardingConfig: state.getstarted?.branding?.onboarding?.data,
  emailConfig: state.getstarted?.branding?.email?.data,
  branding: state.getstarted?.branding,
  account: state.account,
});

export default connect(mapStateToProps)(BrandingSetup);
