import React, {useState, useEffect} from 'react';
import SideBar from '../components/SideBar';
import NotificationHeader from '../components/NotificationHeader';
// import Toast from '../components/shared/Toast';
import Helmet from 'react-helmet';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';

import {connect} from 'react-redux';
import {clearAccount} from '../actions/account';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LayoutDashboard = ({
  children,
  className,
  clearAccount,
  proxyMode,
  account,
  loading,
  pageLoading = false,
  getStarted,
}) => {
  let history = useHistory();

  const handleReturnToAdmin = () => {
    clearAccount();
    history.push('/admin/customers');
  };

  useEffect(() => {
    const path = history.location.pathname;
    if (account) {
      // if (path === '/' && account.live) return history.push('/settings');
      // if (!account.live) {
      //   const restrictedPaths = ['/subscriptions', '/actions', '/event-log'];
      //   // If isLinked is not yet configured during not live account; add settings to restricted pages
      //   if (!getStarted.isLinked) restrictedPaths.push('/settings');
      //   if (restrictedPaths.includes(path)) return history.push('/');
      // }
    }
    //eslint-disable-next-line
  }, [account]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pageLoading || loading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    //eslint-disable-next-line
  }, [account, pageLoading]);

  return (
    <div className={clsx('dashboard', className, {impersonate: proxyMode})}>
      <ToastContainer />

      {proxyMode && (
        <NotificationHeader
          onClick={handleReturnToAdmin}
          email={account?.email}
          full_name={`${account?.firstName} ${account?.lastName}`}
        />
      )}
      <div className="main-wrapper">
        <SideBar />
        <Helmet>
          <script
            src="https://checkoutlib.billsby.com/checkout.min.js"
            data-billsby-company="getonboardly"
          ></script>
        </Helmet>
        <div className="main-content">
          {isLoading && (
            <div className="loader-wrapper">
              <BeatLoader loading={isLoading} color="white" />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  proxyMode: state.account.proxyMode,
  account: state.account.account,
  getStarted: state.getstarted,
  loading: state.account.loading,
});
export default connect(mapStateToProps, {clearAccount})(LayoutDashboard);
