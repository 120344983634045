const log = console.log;

export const warningLog = (label, data) =>
  log(`%c ⚠ ${label} `, 'color:yellow;background:black;padding:5px;', data);

export const requestLog = (label, data) =>
  log(`%c 📩 ${label} `, 'color:Green;background:white;padding:5px;', data);

export const successLog = (label, data) =>
  log(`%c ✅ ${label} `, 'color:white;background:green;padding:5px;', data);

export const errorLog = (label, data) =>
  log(`%c ❌ ${label} `, 'color:white;background:red;padding:5px;', data);

export const infoLog = (label, data) =>
  log(`%c 👋 ${label} `, 'color:blue;background:white;padding:5px;', data);
