import axios from 'axios';
import {toast} from 'react-toastify';
import {errorLog, requestLog, successLog} from '../helpers/Logger';
import {actions} from '../helpers/Urls';

export class Actions {
  constructor({planId, planName, actionCode, actionName, actionConfig}) {
    this.payload = {
      planId,
      planName,
      actionCode,
      actionName,
      actionConfig,
    };
  }

  print() {
    console.log(this.payload);
  }

  save(isUpdateMode) {
    return new Promise(async (resolve, reject) => {
      requestLog('Saving...', this.payload);

      if (isUpdateMode) {
        await axios
          .put(
            actions.saveActions +
              `/${this.payload.planId}/${this.payload.actionCode}`,
            this.payload
          )
          .then(({data}) => {
            toast.success('Action updated succesfully', {});
            successLog('Action updated succesfully', data);
          })
          .catch((error) => {
            toast.error('Action update failed', {});
            errorLog('Action update failed', error.response);
          });
      } else {
        await axios
          .post(actions.saveActions, this.payload)
          .then(({data}) => {
            toast.success('Action saved succesfully', {});
            successLog('Action saved succesfully', data);
          })
          .catch((error) => {
            toast.error('Action save failed', {});
            errorLog('Action save failed', error.response);
          });
      }
      resolve();
    });
  }
}
