const BASE_URL = process.env.REACT_APP_BE_URL;
// const BASE_URL = 'http://localhost:9000';

export const user = {
  // Descending orders
  register: BASE_URL + '/api/auth/register',
  login: BASE_URL + '/api/auth/login',
  profile: BASE_URL + '/api/account',
  validate: BASE_URL + '/api/auth/login/validate',
  update: BASE_URL + '/api/account',
  marketingConsent: BASE_URL + '/api/account/marketing',
  subscription: BASE_URL + '/api/subscription',
  cancelAccount: BASE_URL + '/api/subscription/cancel',
  proxyAccount: BASE_URL + '/api/auth/login/proxy',
};

export const billsbyURL = {
  customerDetails: BASE_URL + '/api/billsby/customers',
  cancelSubscription: BASE_URL + '/api/billsby/customer/cid/subscription/sid',
  checkDomain: BASE_URL + '/api/billsby/domain/billsbyDomain',
};

export const registerURL = {
  checkEmail: BASE_URL + '/api/auth/register/check',
  verifyEmail: BASE_URL + '/api/auth/register/email/verify',
  registerUser: BASE_URL + '/api/auth/register',
};

export const resetURL = {
  sendMail: BASE_URL + '/api/auth/reset/password',
  validateToken: BASE_URL + '/api/auth/reset/password/validate',
  resetPassword: BASE_URL + '/api/auth/reset/password',
};

export const customerURL = {
  allCustomer: BASE_URL + '/api/customers',
  paginated: BASE_URL + '/api/customers/paginated',
};

export const getStarted = {
  linkedAccount: BASE_URL + '/api/get-started/account/link',
  linkDomain: BASE_URL + '/api/get-started/account/link/domain',
  linkWebhookForwarding: BASE_URL + '/api/get-started/account/link/webhook',
  linkRedirect: BASE_URL + '/api/get-started/account/link/redirect',
  status: BASE_URL + '/api/get-started/account/status',
  branding: BASE_URL + '/api/get-started/branding',
  goLive: BASE_URL + '/api/get-started/account/go-live',

  createOnboarding: BASE_URL + '/api/settings/branding/onboarding',
  createBrandingEmail: BASE_URL + '/api/settings/branding/email',
};

export const customerRegistration = {
  save: BASE_URL + '/api/onboarding',
  activate: BASE_URL + '/api/onboarding/activate',
  config: BASE_URL + '/api/onboarding/registration',
};

export const settings = {
  onboarding: BASE_URL + '/api/get-started/branding',
  updateOnboarding: BASE_URL + '/api/settings/branding/onboarding',
  updateEmail: BASE_URL + '/api/settings/branding/email',
  billsby: BASE_URL + '/api/settings/billsby',
  linked: BASE_URL + '/api/settings/linked',
  chargebee: BASE_URL + '/api/settings/chargebee',
};

export const fetchSubscriptions = {
  subscriptions: BASE_URL + '/api/onboarding',
};

export const fetchCustomerDetails = {
  onboardedCustomers: BASE_URL + '/api/onboarding/customer',
};

export const actions = {
  getActions: BASE_URL + '/api/actions',
  saveActions: BASE_URL + '/api/actions',
  getPlans: BASE_URL + '/api/plans',
  mailchimp: BASE_URL + '/api/ext/mailchimp/interest-categories',
  getResponse: BASE_URL + '/api/ext/get-response/campaigns',
  mailerlite: BASE_URL + '/api/ext/mailerlite/groups',
};

export const constantContact = {
  getAuthURL: BASE_URL + '/api/ext/constant-contact/auth/uri',
  getAccessToken: BASE_URL + '/api/ext/constant-contact/auth/access_token',
  refreshToken: BASE_URL + '/api/ext/constant-contact/auth/access_token',
  getOAuthToken: BASE_URL + '/api/ext/constant-contact/auth/token',
  getListNames: BASE_URL + '/api/ext/constant-contact/contact_lists',
};

export const aweber = {
  getAuthURL: BASE_URL + '/api/ext/aweber/authorized-Url',
  checkToken: BASE_URL + '/api/ext/aweber/auth/check_token',
  refreshToken: BASE_URL + '/api/ext/aweber/auth/refresh_token',
  getOAuthToken: BASE_URL + '/api/ext/aweber/auth/token',
  getListNames: BASE_URL + '/api/ext/aweber/lists',
  getAccountId: BASE_URL + '/api/ext/aweber/account_id',
};

export const eventLog = {
  logs: BASE_URL + '/api/event_logs',
  reAttempt: BASE_URL + '/api/event_logs/re-attempt',
};
