import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faAngleRight,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import {fetchSubscriptions} from '../helpers/Urls';
import LayoutDashboard from '../layout/LayoutDashboard';
import BeatLoader from 'react-spinners/BeatLoader';

import {getAllAccount, getCurrentAccount} from '../actions/account';
import {infoLog} from '../helpers/Logger';

function Subscription({account, customerUniqueId}) {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (account) {
      const delayDebounceFn = setTimeout(() => {
        infoLog('Debounced request query', '');
        setLoading(true);
        filterSubscription(query);
        setLoading(false);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }

    //eslint-disable-next-line
  }, [query, account]);

  const handlePageChange = async ({selected}) => {
    // const page = selected + 1;
    // await getPagedAccount(page, 1);
  };

  const filterSubscription = (query) => {
    axios
      .get(
        `${fetchSubscriptions.subscriptions}/${customerUniqueId}?search=${query}`
      )
      .then(({data}) => {
        setSubscriptionList(data.result);
      })
      .catch((error) => console.log(error.data));
  };

  return (
    <LayoutDashboard className="dashboard-subscription">
      {loading && (
        <div className="loader-wrapper">
          <BeatLoader loading={loading} color="white" />
        </div>
      )}
      <div className="subscriptions">
        <div className="section-top">
          <div className="filter-wrapper">
            <div></div>
            <p>{subscriptionList?.length} Subscriptions</p>
            <div className="filter-search">
              <label>
                <input
                  type="text"
                  placeholder="Search subscriptions by email, name, GetOnboard.ly ID or Billsby ID"
                  name="query"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button>
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </label>
            </div>
          </div>
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={<FontAwesomeIcon icon={faCaretLeft} />}
              nextLabel={<FontAwesomeIcon icon={faCaretRight} />}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              subContainerClassName={'page-item'}
              activeClassName={'active'}
            />
          </div>
        </div>
        <div className="table-wrapper">
          <div className="table">
            <div className="row-wrapper">
              <div className="column-wrapper">
                <p className="table-heading">Email</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Full name</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Created on</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">GetOnboardly ID</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Billsby ID</p>
              </div>
              <div className="column-wrapper">
                <p className="table-heading">Subscription Status</p>
              </div>
              <div className="column-wrapper"></div>
            </div>
            {!!subscriptionList?.length ? (
              subscriptionList.map(
                (
                  {
                    email,
                    first_name,
                    last_name,
                    created_at,
                    getonboardly_id,
                    customer_unique_id,
                    status,
                  },
                  i
                ) => (
                  <div className="row-wrapper" key={i}>
                    <div className="column-wrapper">
                      <p className="table-info">{email}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">
                        {first_name} {last_name}
                      </p>
                    </div>
                    <div className="column-wrapper">
                      <p>{moment(created_at).format('MMMM DD YYYY')}</p>
                    </div>
                    <div className="column-wrapper is-id">
                      <p className="table-info">{getonboardly_id}</p>
                    </div>
                    <div className="column-wrapper is-id">
                      <p className="table-info">{customer_unique_id}</p>
                    </div>
                    <div className="column-wrapper">
                      <p className="table-info">{status}</p>
                    </div>
                    <div className="column-wrapper">
                      <Link to={`/subscriptions/${getonboardly_id}`}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </Link>
                    </div>
                  </div>
                )
              )
            ) : (
              <p style={{padding: '20px'}}>No results found</p>
            )}
          </div>
        </div>
      </div>
    </LayoutDashboard>
  );
}

Subscription.propTypes = {
  getAllAccount: PropTypes.func.isRequired,
  getCurrentAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account?.account,
  accounts: state.account?.accounts,
  filteredAccounts: state.account?.filteredAccounts,
  subscriptions: state.account?.accounts?.subscriptions,
  customerUniqueId: state.account?.account?.customerUniqueId,
});

export default connect(mapStateToProps, {
  getAllAccount,
  getCurrentAccount,
})(Subscription);
