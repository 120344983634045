import React from 'react';

import Splode from '../assets/images/splode-green.svg';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LayoutDark = ({children}) => {
  return (
    <>
      <Header />
      <div className="layout-dark">
        <img className="splode" src={Splode} alt="splode" />
        <div className="main">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default LayoutDark;
