import {GET_ACTIONS} from './types';

import {actions} from '../helpers/Urls';
import axios from 'axios';

export const getActions = () => (dispatch) => {
  let token = '';

  if (localStorage.proxyMode === 'true') {
    token = localStorage.proxyToken;
  } else {
    token = localStorage.token;
  }

  // HotFix on HotReload
  const overideDefaults = axios.create({
    headers: {
      common: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  overideDefaults
    .get(actions.getActions, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({data}) => {
      dispatch({
        type: GET_ACTIONS,
        payload: data,
      });
    });
};
