import React from 'react';
import {Link} from 'react-router-dom';

export const Breadcrumbs = ({text, link}) => {
  return (
    <div className="breadcrumbs">
      <Link to={link}>
        {' '}
        {'<'} {text}
      </Link>
    </div>
  );
};

export default Breadcrumbs;
