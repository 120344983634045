import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <p>© GetOnboard.ly</p>
          <p>
            A product of
            <a
              href="https://www.billsby.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-underline"
            >
              Billsby
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
