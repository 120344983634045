import {GET_ACTIONS, GET_ACTIONS_ERROR} from '../actions/types';

const initialState = {
  plans: [],
  loading: true,
};

export default function (state = initialState, action) {
  const {type, payload} = action;
  switch (type) {
    case GET_ACTIONS:
      return {
        ...state,
        plans: payload,
        loading: false,
      };
    case GET_ACTIONS_ERROR:
      return {
        ...state,
        plans: [],
        loading: false,
      };

    default:
      return state;
  }
}
