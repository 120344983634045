import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import domainImg from '../assets/images/domain-tip.png';
import domainImg2x from '../assets/images/domain-tip@2x.png';
import {
  urlRegex,
  chargebeeWizardSteps as wizardSteps,
} from '../helpers/StaticVariables';
import WizardButton from './wizard/WizardButton';
import Modal from './shared/Modal';
import {checkIsEmptyOrNull, removeSpaces} from '../helpers/HelperFunctions';

const ChargebeeConnect = ({
  show,
  handleClose,
  linkedAccount,
  handleConnectAccount,
  customerUniqueId,
  fetchLinkedAccount,
}) => {
  const [step, setStep] = useState(1);
  const [chargebeeDomain, setChargebeeDomain] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [redirectURL, setRedirectURL] = useState('');
  const [verifiedDomain, setVerifiedDomain] = useState(false);
  const [error, setError] = useState(null);
  const [webhookChecker, setWebhookChecker] = useState();

  // fetch webhook function in parent component
  const checkWebhook = () => {
    console.log('Checking webhook');
    fetchLinkedAccount();
  };

  // Initialize webhook checker
  const setChecker = () => {
    setWebhookChecker(setInterval(checkWebhook, 5000));
  };

  // Clear Webhook
  const clearChecker = () => {
    console.log('Clear webhook checker');
    clearInterval(webhookChecker);
  };

  const handleNextStep = async () => {
    setError(null);

    if (step === 1) {
      setVerifiedDomain(false);

      if (checkIsEmptyOrNull(removeSpaces(chargebeeDomain)))
        return setError('Please enter a Chargebee domain');
    }

    if (step === 2) {
      if (checkIsEmptyOrNull(removeSpaces(apiKey)))
        return setError('Please enter a Chargebee API key');
      if (verifiedDomain) return setStep(step + 1);

      /**
       * Sends API Request:
       * - Validates domain and API key in Chargebee
       * - Updates/Saves domain and API key in DB (initialize record in DB)
       * - Set Verified Domain to True
       * - Moves to next step
       *
       * Error:
       * - Domain and API Key not found in Chargebee
       */
      return handleConnectAccount('domain', {
        domain: chargebeeDomain,
        apiKey,
      })
        .then((result) => {
          setVerifiedDomain(true);
          setStep(step + 1);
        })
        .catch((err) =>
          setError(
            'Domain and API Key not found in Chargebee. Please enter a valid domain and API Key.'
          )
        );
    }

    if (step === 4) {
      clearChecker();
      if (checkIsEmptyOrNull(removeSpaces(redirectURL)))
        return setError(
          'Please enter a redirect URL for us to send customers to'
        );

      if (!urlRegex.test(redirectURL)) return setError('Invalid URL format');

      return handleConnectAccount('redirect', {
        redirectUrl: redirectURL,
      });
    }

    return setStep(step + 1);
  };

  useEffect(() => {
    if (linkedAccount) {
      let _step = 1;
      // set Local data with passed props
      setChargebeeDomain(linkedAccount.domain);
      setApiKey(linkedAccount.api_key);
      setRedirectURL(linkedAccount.redirect_url ?? '');

      // Auto step up
      if (!checkIsEmptyOrNull(linkedAccount.domain)) _step++;
      if (!checkIsEmptyOrNull(linkedAccount.api_key)) _step++;
      if (
        linkedAccount.webhook_status === 'active' &&
        (linkedAccount?.redirect_url !== '' ||
          linkedAccount?.redirect_url !== null)
      )
        _step++;

      return setStep(_step);
    }

    setStep(1);
    //eslint-disable-next-line
  }, [show]);

  useEffect(() => {
    // Auto clear error after 5 seconds
    const timer = setTimeout(() => {
      setError(null);
    }, 5000);
    return () => clearTimeout(timer);
  }, [error]);

  useEffect(() => {
    /**
     * Monitors webhook status
     * Works with step 3 > Adding webhook forwarding URL
     */
    if (linkedAccount?.webhook_status === 'active') setStep(step + 1);

    //eslint-disable-next-line
  }, [linkedAccount]);

  useEffect(() => {
    // Monitor steps:
    if (step === 3) setChecker();
    else clearChecker();
    // eslint-disable-next-line
  }, [step]);

  return (
    <Modal
      show={show}
      title="Connecting your Chargebee account"
      handleClose={handleClose}
    >
      <div className="modal-body">
        <p>
          So that you can start using GetOnboard.ly, you’ll need to connect your
          Chargeebee account by giving us your account domain and API key,
          setting up web hook forwarding and configuring your redirect URL.
          This’ll take less than five minutes. As long as you follow all of the
          steps correctly, your customers will have no idea you’ve connected
          GetOnboard.ly until you go live, and everything you currently use will
          still work as expected.
        </p>

        <div className="wizard-container">
          {wizardSteps.map((ws) => (
            <div
              className={clsx(
                'wizard-steps',
                {'step-disabled': ws.step > step},
                {'step-done': ws.step < step},
                {
                  'collapse less':
                    (ws.step === 1 && step === 3) ||
                    (step === 4 && ws.step === 2),
                },
                {'collapse lesser': ws.step === 1 && step === 4}
              )}
              key={ws.step}
            >
              <div className="wizard-steps__content">
                <div className={`wizard-steps__content__header `}>
                  <span className="wizard-steps__number">
                    {ws.step < step ? (
                      <FontAwesomeIcon icon={faCheckCircle} />
                    ) : (
                      ws.step
                    )}
                  </span>
                  <div
                    className="wizard-steps__content__title"
                    onClick={() => {
                      console.log(ws.step);
                      if (ws.step <= step) setStep(ws.step);
                    }}
                  >
                    {ws.title}
                  </div>
                </div>

                {ws.step === 1 && step === 1 && (
                  <div className="wizard-steps__content__body">
                    <div className="wizard-steps__content__body__img-holder">
                      <img
                        src={domainImg}
                        srcSet={domainImg2x}
                        alt="e.g. Chargebee domain"
                      />
                    </div>
                    <p>
                      After logging in to your account on Chargebee, look at the
                      address in your web browser. We need just the bit after
                      the https:// but before the .chargebee.com.
                    </p>
                    <div className="input-group-wrapper">
                      <label className="input-label">Chargebee Domain</label>
                      <div className="input-text-group">
                        <input
                          type="text"
                          className="input"
                          placeholder="burtsbeekeeping"
                          value={chargebeeDomain}
                          onChange={(e) => setChargebeeDomain(e.target.value)}
                        />
                        <span className="input-ext">.chargebee.com</span>
                      </div>
                    </div>
                  </div>
                )}

                {ws.step === 2 && step === 2 && (
                  <div className="wizard-steps__content__body">
                    <p>
                      Great! Next we need your API key. This lets us communicate
                      with your Chargebee account.
                    </p>
                    <a
                      className="btn-purple-inverted"
                      href={`https://${chargebeeDomain}.chargebee.com/apikeys_and_webhooks/api`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access API keys page
                    </a>
                    <p>
                      Create a new <b>Full Access key</b>, call it{' '}
                      <b>GetOnboard.ly</b> and then paste it in the box below.
                      We need a full access key so we can update your users
                      details - a read only key isn’t enough.
                    </p>

                    <div className="input-group-wrapper">
                      <label className="input-label">API Key</label>
                      <div className="input-text-group ">
                        <input
                          type="text"
                          className="input full-width"
                          placeholder="live_uxvkm6JOfMGeY85o9jYpX0G3g66VqaFr"
                          value={apiKey}
                          onChange={(e) => setApiKey(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {ws.step === 3 && step === 3 && (
                  <div className="wizard-steps__content__body">
                    <p>
                      Next, we need to setup the right web hooks, so we can
                      retrieve information from Chargebee about your
                      subscriptions.
                    </p>

                    <a
                      className="btn-purple-inverted"
                      href={`https://${chargebeeDomain}.chargebee.com/apikeys_and_webhooks/webhooks`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access web hooks page
                    </a>

                    <p>
                      Choose <b>Add Webhook</b> then use the name{' '}
                      <b>GetOnboard.ly</b> and the URL:
                    </p>

                    <h2>
                      {process.env.REACT_APP_BE_URL}/webhooks/
                      {customerUniqueId.toLowerCase()}
                    </h2>
                    <p>
                      In <b>Events to send</b> tick{' '}
                      <b>
                        Customer Created, Customer Changed, Customer Deleted,
                        Subscription Cancelled, Subscription Reactivated,
                        Subscription Deleted, Subscription Paused
                      </b>{' '}
                      and
                      <b>Subscription Resumed</b>.
                    </p>
                    <p>
                      Choose <b>Create</b> to save the web hook, then choose{' '}
                      <b>Test Webhook</b> and send a <b>Customer Changed</b>{' '}
                      test by pressing the <b>Test URL</b> button.
                    </p>
                  </div>
                )}
                {ws.step === 4 && step === 4 && (
                  <div className="wizard-steps__content__body">
                    <p>
                      Finally, let’s configure your redirect URL. This is where
                      we send customers after they’ve finished completing your
                      GetOnboard.ly form. Until you go live, we’ll just forward
                      customers straight to your redirect URL. So customers will
                      be redirected from Chargebee to GetOnboard.ly, and then
                      from GetOnboard.ly to you. Simple. We’re assuming that
                      you’re using the embedded (pop-up) Chargebee checkout. If
                      you’re using hosted pages, follow the steps on the
                      Chargebee website to create a redirect URL.
                    </p>
                    <a
                      className="btn-purple-inverted"
                      href={`https://${chargebeeDomain}.chargebee.com/checkout_and_portal_settings/configuration`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access checkout settings
                    </a>
                    <p>
                      In domains, add <b>https://getonboard.ly</b> so that you
                      can redirect customers to us, then press ‘Publish’
                    </p>
                    <a
                      className="btn-purple-inverted"
                      href={`https://${chargebeeDomain}.chargebee.com/plans`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Access plans
                    </a>
                    <p>
                      Next, for every plan you want to use with GetOnboard.ly go
                      to Edit Plan and then add the following URL in the
                      redirect URL box:
                    </p>
                    <h2>
                      https://getonboard.ly/registration/
                      {customerUniqueId?.toLowerCase()}
                      {'?sid={{subscription.id}}&cid={{customer.id}}'}
                    </h2>
                    <p>
                      Finally, enter the URL you’d like us to redirect customers
                      to after finishing the GetOnboard.ly process below:
                    </p>
                    <div className="input-group-wrapper">
                      <label className="input-label">Redirect URL</label>
                      <div className="input-text-group">
                        <input
                          type="text"
                          className="input full-width"
                          placeholder="https://www.mycompany.com/welcome"
                          value={redirectURL}
                          onChange={(e) => setRedirectURL(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {ws.step === step && (
                  <>
                    <WizardButton
                      handleNextStep={handleNextStep}
                      step={step}
                      type={'Chargebee'}
                    />
                    <div className="error-msg">{error}</div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className="modal-footer"></div> */}
    </Modal>
  );
};

export default ChargebeeConnect;
