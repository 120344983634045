import React from 'react';
import {
  GetResponseActionForm,
  MailchimpActionForm,
  MailerLightActionForm,
  AweberActionForm,
  ConstantContactActionForm,
} from '../../components/actions/forms';

const ActionForm = ({actionCode, onSubmit, planId}) => {
  const renderActionForm = () => {
    switch (actionCode) {
      case 'getresponse':
        return <GetResponseActionForm onSubmit={onSubmit} planId={planId} />;

      case 'mailchimp':
        return <MailchimpActionForm onSubmit={onSubmit} planId={planId} />;

      case 'mailerlite':
        return <MailerLightActionForm onSubmit={onSubmit} planId={planId} />;

      case 'aweber':
        return <AweberActionForm onSubmit={onSubmit} planId={planId} />;

      case 'constant_contact':
        return (
          <ConstantContactActionForm onSubmit={onSubmit} planId={planId} />
        );

      default:
        return <p>Invalid Action Code</p>;
    }
  };

  return <>{renderActionForm()}</>;
};

export default ActionForm;
