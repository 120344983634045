import React from 'react';
import LayoutDashboard from '../layout/LayoutDashboard';
import Options from '../components/settings/Options';

function Settings(props) {
  return (
    <LayoutDashboard className="settings">
      <section className="banner">
        <h2 className="section-title">Settings</h2>
        <p>
          This is where you can update the options that dictate how your
          GetOnboard.ly account works, and how things are displayed to your
          customers when they use GetOnboard.ly
        </p>
      </section>
      <Options />
    </LayoutDashboard>
  );
}

export default Settings;
