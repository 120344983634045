import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faKey,
  faUser,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import {fetchCustomerDetails} from '../helpers/Urls';
import LayoutDashboard from '../layout/LayoutDashboard';
import Breadcrumbs from '../components/Breadcrumbs';

import {getAllAccount, getCurrentAccount} from '../actions/account';

function SubscriptionsInfo({match, account}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [onboardedCustomer, setOnboardedCustomer] = useState({
    billing_address: null,
    created_at: null,
    customer_of: null,
    customer_unique_id: 'XXXXXXXXXXX',
    email: null,
    first_name: '',
    getonboardly_id: 'XXXXXXXXXXXXXX',
    history: [
      {
        status: 'Not Onboarded',
        date: 'No date',
      },
    ],
    last_name: '',
    onboarding_time: null,
    plan_id: null,
    plan_name: null,
    status: 'Not onboarded',
    subscription_unique_id: null,
    linked_accounts: [
      {
        link_urls: null,
      },
    ],
  });

  const getSubscriptionDetails = (gid) => {
    axios
      .get(`${fetchCustomerDetails.onboardedCustomers}/${gid}`)
      .then(({data}) => {
        setOnboardedCustomer(data);
      })
      .catch((error) => console.log(error.data));
  };

  const handleClick = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (account) {
      if (match.params.getOnboardlyId) {
        getSubscriptionDetails(match.params.getOnboardlyId);
      }
    }
    // eslint-disable-next-line
  }, [account]);

  return (
    <LayoutDashboard className="dashboard-subscription">
      <div className="subscriptions-info">
        <Breadcrumbs link="/subscriptions" text="Subscriptions" />
        <div className="subscriber-info-wrapper">
          <div className="subscriber-wrapper">
            <div>
              <p className="subscriber-name">
                {onboardedCustomer.first_name +
                  ' ' +
                  onboardedCustomer.last_name}
              </p>
              <p className="subscriber-id">
                GetOnboardly ID: {onboardedCustomer.getonboardly_id}
              </p>
              <p className="subscriber-id">
                Billsby ID: {onboardedCustomer.subscription_unique_id}
              </p>
            </div>
            <div className="subscriber-status">{onboardedCustomer.status}</div>
          </div>
          <div className="subscription-info-heading">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faHistory} />
            </div>
            <p className="heading-wrapper">Status History</p>
          </div>
          <div className="history-wrapper">
            {onboardedCustomer.history.map((history, index) => (
              <div className="status-wrapper" key={index}>
                <p className="status">{history.status}</p>
                <p className="date">{history.date}</p>
              </div>
            ))}
          </div>
          <div className="subscription-info-heading">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faKey} />
            </div>
            <p className="heading-wrapper">Linked Accounts</p>
          </div>
          <div className="accounts-wrapper">
            {onboardedCustomer.linked_accounts.map((linked_accounts, index) => (
              <div key={index} className="linked-account">
                {linked_accounts?.link_urls?.length > 1 ? (
                  <>
                    <div onClick={handleClick} className="title-wrapper">
                      <p>{linked_accounts?.name}</p>
                      <FontAwesomeIcon icon={faAngleDown} />
                    </div>
                    {showDropdown && (
                      <div className="dropdown-wrapper">
                        <div className="list-wrapper">
                          {linked_accounts?.link_urls?.map((link_urls, j) => (
                            <a
                              key={j}
                              href={link_urls.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="plan-link"
                            >
                              {link_urls.plan_name}
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="title-wrapper">
                    {linked_accounts?.link_urls?.length === 1 && (
                      <a
                        href={linked_accounts?.link_urls[0]?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {linked_accounts?.name}
                      </a>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="subscription-info-heading">
            <div className="icon-wrapper">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <p className="heading-wrapper">Customer Data</p>
          </div>
          <div className="data-wrapper">
            <div className="data-wrapper__left">
              <p>Name</p>
              <p>Email Address</p>
              <p>Billing Address</p>
              <p>Plan ID</p>
              <p>Plan Name</p>
              <p>Billsby Customer ID</p>
              <p>Billsby Subscription ID</p>
            </div>
            <div className="data-wrapper__right">
              <p>
                {onboardedCustomer.first_name +
                  ' ' +
                  onboardedCustomer.last_name}
              </p>
              <p>{onboardedCustomer.email}</p>
              <p>
                {onboardedCustomer.billing_address_formatted ??
                  onboardedCustomer.billing_address}
              </p>
              <p>{onboardedCustomer.plan_id}</p>
              <p>{onboardedCustomer.plan_name}</p>
              <p>{onboardedCustomer.customer_unique_id}</p>
              <p>{onboardedCustomer.subscription_unique_id}</p>
            </div>
          </div>
        </div>
      </div>
    </LayoutDashboard>
  );
}

SubscriptionsInfo.propTypes = {
  getAllAccount: PropTypes.func.isRequired,
  getCurrentAccount: PropTypes.func.isRequired,
  account: PropTypes.object,
  accounts: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account?.account,
  accounts: state.account?.accounts,
  filteredAccounts: state.account?.filteredAccounts,
  onboardedCustomers: state.account?.accounts.onboardedCustomers,
});

export default connect(mapStateToProps, {
  getAllAccount,
  getCurrentAccount,
})(SubscriptionsInfo);
