import React, {useState, useEffect} from 'react';
import {actions} from '../../../helpers/Urls';
import axios from 'axios';

import LayoutDashboard from '../../../layout/LayoutDashboard';
import Breadcrumbs from '../../../components/Breadcrumbs';
import MailchimpActionForm from '../../../components/actions/forms/MailchimpActionForm';

import Banner from '../../../components/actions/newsletters/Banner';
import {useParams} from 'react-router-dom';

const ActionConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');
  let {planId} = useParams();

  const onSubmit = (isSuccess) => {
    console.log(isSuccess);
  };

  useEffect(() => {
    axios.get(actions.getPlans + '?id=' + planId).then(({data: plan}) => {
      setPlanName(plan.plan_name);
    });
  }, []);

  return (
    <LayoutDashboard pageLoading={loading} className="action-configuration">
      <Breadcrumbs link="/actions" text={`Actions for ${planName}`} />
      <MailchimpActionForm
        setLoading={setLoading}
        onSubmit={onSubmit}
        planId={planId}
      />
    </LayoutDashboard>
  );
};

export default ActionConfiguration;
