export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGOUT = 'LOGOUT';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_PROXY_ACCOUNT = 'GET_PROXY_ACCOUNT';
export const ACCOUNT_ERROR = 'ACCOUNT_ERROR';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const CANCEL_ACCOUNT = 'CANCEL_ACCOUNT';
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';

/* -------------------------------------------------------------------------- */
/*                              Get Started Types                             */
/* -------------------------------------------------------------------------- */

export const GET_LINKED_ACCOUNT = 'GET_LINKED_ACCOUNT';
export const LINK_ACCOUNT = 'LINK_ACCOUNT';
export const LINK_ACCOUNT_ERROR = 'LINK_ACCOUNT_ERROR';
export const UNLINK_ACCOUNT = 'UNLINK_ACCOUNT';
export const GET_GETSTARTED_STATUS = 'GET_GETSTARTED_STATUS';

/* -------------------------------------------------------------------------- */
/*                              Actions Types                          */
/* -------------------------------------------------------------------------- */

export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR';

/* -------------------------------------------------------------------------- */
/*                                 Toast Types                                */
/* -------------------------------------------------------------------------- */
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
