import {
  LINK_ACCOUNT,
  GET_LINKED_ACCOUNT,
  UNLINK_ACCOUNT,
  GET_GETSTARTED_STATUS,
} from '../actions/types';
const initialState = {
  isLinked: false,
  isConfiguredBranding: false,
  isAddedFirstAction: false,
  isTestedOut: false,
  linkedAccount: null,
  branding: null,
};

export default function (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case GET_GETSTARTED_STATUS:
      return {
        ...state,
        isLinked: payload.isLinked,
        isConfiguredBranding: payload.isConfiguredBranding,
        linkedAccount: payload.linkedAccount,
        branding: payload.branding,
        isAddedFirstAction: payload.isAddedFirstAction,
      };

    case GET_LINKED_ACCOUNT:
      return {
        ...state,
        isLinked: payload.isLinked,
        linkedAccount: payload.linkedAccount,
      };

    case LINK_ACCOUNT:
      return state;

    case UNLINK_ACCOUNT:
      return {
        isLinked: false,
        isConfiguredBranding: false,
        isAddedFirstAction: false,
        isTestedOut: false,
        linkedAccount: null,
      };

    default:
      return state;
  }
}
