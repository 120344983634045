import React, {useState, useEffect} from 'react';
import LayoutDashboard from '../layout/LayoutDashboard';
import clsx from 'clsx';
import {useForm} from 'react-hook-form';
import PropTypes from 'prop-types';
import axios from 'axios';
import {user} from '../helpers/Urls';
import history from '../history';
import {clearAccount} from '../actions/account';

import {connect} from 'react-redux';
import {logout, loadUser} from '../actions/auth';

import BeatLoader from 'react-spinners/BeatLoader';
import {toast} from 'react-toastify';
import Toggler from '../components/Toggler';

function MyAccount({
  auth,
  logout,
  loadUser,
  clearAccount,
  proxyMode,
  loading,
  account,
}) {
  const {register, handleSubmit, errors, setError} = useForm();
  const [wantsNewsLetter, setWantsNewsLetter] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);
  const [marketingRequestLoading, setMarketingRequestLoading] = useState(false);

  // eslint-disable-next-line
  const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@])[A-Za-z\d-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@]{8,}$/;

  const onSubmit = async (data) => {
    setRequestLoading(true);

    const {
      confirmPassword,
      password: newPassword,
      firstName,
      lastName,
      email,
    } = data;

    if (confirmPassword !== newPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'Mismatched password',
      });

      setRequestLoading(false);
      return;
    }
    const body = {
      firstName,
      lastName,
      email,
      newPassword,
    };

    try {
      await axios.put(user.update, body);
      setRequestLoading(false);

      if (proxyMode) {
        clearAccount();
        return history.push('/admin/customers');
      }
      logout();
    } catch (error) {
      console.log('error while updating account', error?.response);

      setRequestLoading(false);

      toast.error('error updating', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  };

  const handleMarketingSubmit = async (e) => {
    e.preventDefault();

    console.log('marketing data', wantsNewsLetter);
    setMarketingRequestLoading(true);

    try {
      await axios.put(`${user.marketingConsent}/${account?.customerUniqueId}`, {
        marketingConsent: wantsNewsLetter,
      });
      setMarketingRequestLoading(false);
      toast.success('Marketing preference updated!', {
        autoClose: 3000,
      });
    } catch (error) {
      toast.error('error updating ', {
        autoClose: 3000,
      });
      setMarketingRequestLoading(false);
    }
  };

  useEffect(() => {
    setWantsNewsLetter(account?.marketingConsent);
    // eslint-disable-next-line
  }, [auth]);

  return (
    <LayoutDashboard className="page-my-account">
      <section className="banner">
        <h2 className="section-title">Manage account</h2>
        <p>
          This is where you can manage your GetOnboard.ly account, as well as
          updating your account details- like your login email address and
          password
        </p>
      </section>

      <section className="account-details">
        <h2 className="section-title">Account details</h2>
        <div className="form-wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="input-wrapper">
                <label>
                  <span>Name</span>
                  <input
                    name="firstName"
                    type="text"
                    placeholder={account?.firstName ?? 'First Name'}
                    defaultValue={account?.firstName}
                    ref={register({
                      required: 'Required',
                    })}
                    className={clsx({
                      'input-error': errors?.firstName,
                    })}
                  />

                  <input
                    name="lastName"
                    type="text"
                    placeholder={account?.lastName ?? 'Last Name'}
                    defaultValue={account?.lastName}
                    ref={register({
                      required: 'Required',
                    })}
                    className={clsx({
                      'input-error': errors?.lastName,
                    })}
                  />
                </label>
                {/* {errors?.firstName && (
                  <span className="error-text" role="alert">
                    {errors?.firstName?.message}
                  </span>
                )} */}
              </div>
            </div>

            <div
              className={clsx('input-wrapper', {
                'input-error': errors.email,
              })}
            >
              <label>
                <span>Email address</span>
                <input
                  name="email"
                  placeholder={account?.email ?? 'Email'}
                  defaultValue={account?.email}
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
              </label>
              {errors.email && (
                <span className="error-text" role="alert">
                  {errors.email.message}
                </span>
              )}
            </div>

            <div
              className={clsx('input-wrapper', {
                'input-error': errors.password,
              })}
            >
              <label>
                <span>Password</span>
                <input
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: passwordPattern,
                      message:
                        'Password does not follow complexity rules. [Atleast 1 letter, 1 character, 1 special char, and atleast 8 length]',
                    },
                  })}
                  placeholder="Password"
                  type="password"
                  name="password"
                />
              </label>
              {errors.password && (
                <span className="error-text" role="alert">
                  {errors.password.message}
                  {console.log(errors)}
                </span>
              )}
            </div>

            <div
              className={clsx('input-wrapper', {
                'input-error': errors.confirmPassword,
              })}
            >
              <label>
                <span>Confirm password</span>
                <input
                  ref={register({
                    required: 'Required',
                    pattern: {
                      value: passwordPattern,
                      message:
                        'Password does not follow complexity rules. [Atleast 1 letter, 1 character, 1 special char, and atleast 8 length]',
                    },
                  })}
                  placeholder="Confirm Password"
                  type="password"
                  name="confirmPassword"
                />
              </label>
              {errors.confirmPassword && (
                <span className="error-text" role="alert">
                  {errors?.confirmPassword?.message}
                  {console.log(errors)}
                </span>
              )}
            </div>
            <button type="submit" className="btn-green">
              Update account details
              <BeatLoader loading={requestLoading} color="white" />
            </button>
          </form>
        </div>
      </section>

      <section className="marketing">
        <h2 className="section-title">Marketing preferences</h2>

        <div className="form-wrapper">
          <form onSubmit={handleMarketingSubmit}>
            <Toggler
              title="Email"
              setIsToggled={setWantsNewsLetter}
              isToggled={wantsNewsLetter}
            />
            <button type="submit" className="btn-green">
              Update marketing preferences
              <BeatLoader loading={marketingRequestLoading} color="white" />
            </button>
          </form>
        </div>
      </section>
    </LayoutDashboard>
  );
}

MyAccount.propTypes = {
  logout: PropTypes.func.isRequired,
  clearAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  proxyMode: PropTypes.bool,
  account: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  proxyMode: state.account.proxyMode,
  account: state.account.account,
});

export default connect(mapStateToProps, {logout, loadUser, clearAccount})(
  MyAccount
);
