export const wizardSteps = [
  {
    step: 1,
    title: 'Your Billsby domain',
  },
  {
    step: 2,
    title: 'API Key',
  },
  {
    step: 3,
    title: 'Web hook forwarding',
  },
  {
    step: 4,
    title: 'Redirect URL',
  },
];

export const chargebeeWizardSteps = [
  {
    step: 1,
    title: 'Your Chargebee domain',
  },
  {
    step: 2,
    title: 'API Key',
  },
  {
    step: 3,
    title: 'Web hook forwarding',
  },
  {
    step: 4,
    title: 'Redirect URL',
  },
];

export const brandingSteps = [
  {
    step: 1,
    title: 'On-boarding page',
  },
  {
    step: 2,
    title: 'Emails from getOnboard.ly',
  },
];

export const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

export const fonts = [
  'Roboto',
  'Orelega One',
  'Antonio',
  'Open Sans',
  'Zen Dots',
  'Noto Sans JP',
  'Lato',
  'Montserrat',
  'Karantina',
  'Source Sans Pro',
  'Roboto Condensed',
  'Oswald',
  'Poppins',
  'Raleway',
  'PT Sans',
  'Merriweather',
  'Ubuntu',
  'Playfair Display',
  'Nunito',
  'Rubik',
  'Original Surfer',
  'Coda Caption',
  'Quicksand',
  'Barlow',
  'Heebo',
  'Oxygen',
  'RocknRoll One',
  'Source Code Pro',
  'Dancing Script',
  'Architects Daughter',
  'Pacifico',
  'Bangers',
  'Amatic SC',
  'Sacramento',
  'Orbitron',
  'Padauk',
  'Jost',
  'Sigmar One',
  'Monoton',
  'Cookie',
];

export const fontOptions = [
  'Arial',
  'Verdana',
  'Helvetica',
  'Georgia',
  'Tahoma',
  'Lucida',
  'Trebuchet',
  'Times',
];

export const actions = [
  {
    name: 'inputName1',
    placeholder: 'This is a description of the action',
    defaulValue: '',
    title: 'Action Title',
    description: 'This is a description of the action',
    label: 'action field name',
  },

  {
    name: 'inputName2',
    placeholder: 'This is a description of the action',
    defaulValue: '',
    title: 'Action Title',
    description: 'This is a description of the action',
    label: 'action field name',
  },

  {
    name: 'inputName3',
    placeholder: 'This is a description of the action',
    defaulValue: '',
    title: 'Action Title',
    description: 'This is a description of the action',
    label: 'action field name',
  },

  {
    name: 'inputName4',
    placeholder: 'This is a description of the action',
    defaulValue: '',
    title: 'Action Title',
    description: 'This is a description of the action',
    label: 'action field name',
  },
];
