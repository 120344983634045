import React from 'react';
import SideBarAdmin from '../components/SideBarAdmin';
import {Redirect} from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

const LayoutAdmin = ({children, className, isAdmin, proxyMode}) => {
  if (!isAdmin && proxyMode) {
    return <Redirect to="/" />;
  }

  return (
    <div className={`dashboard ${className ?? ''}`}>
      <SideBarAdmin />
      <Helmet>
        <script
          src="https://checkoutlib.billsby.com/checkout.min.js"
          data-billsby-company="getonboardly"
        ></script>
      </Helmet>
      <div className="main-content">{children}</div>
    </div>
  );
};

LayoutAdmin.propTypes = {
  isAdmin: PropTypes.bool,
  proxyMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  proxyMode: state.account.proxyMode,
});

export default connect(mapStateToProps)(LayoutAdmin);
