import React from 'react';
import {useDropzone} from 'react-dropzone';

const UploadImage = ({
  onImageSelect,
  setIsUploaded,
  isUploaded,
  ImgPreviewUrl,
}) => {
  // const [isUploaded, setIsUploaded] = useState();
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      onImageSelect(file);
      ImgPreviewUrl(file[0].preview);
    },
  });

  const handleUpload = () => {
    setIsUploaded(true);
  };

  return (
    <div
      className={`btn-upload ${isUploaded ? 'is-uploaded' : ''}`}
      onChange={handleUpload}
    >
      <div
        {...getRootProps({
          className: 'dropzone upload',
        })}
      >
        <input {...getInputProps()} />
        <p>Upload your logo here</p>
      </div>
    </div>
  );
};

export default UploadImage;
