import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Toggler from '../../Toggler';

const TabHeader = ({icon, name, toggleAction, isToggled}) => {
  return (
    <div className="tab-header">
      <div className="tab-header__icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="tab-header__name">{name}</div>
      {toggleAction && (
        <Toggler setIsToggled={toggleAction} isToggled={isToggled} />
      )}
    </div>
  );
};

export default TabHeader;
