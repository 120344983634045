import React, {useState, useEffect} from 'react';
import {actions} from '../../../helpers/Urls';
import axios from 'axios';

import LayoutDashboard from '../../../layout/LayoutDashboard';
import Breadcrumbs from '../../../components/Breadcrumbs';

import {useParams} from 'react-router-dom';
import Banner from '../../../components/actions/newsletters/Banner';
import GetResponseActionForm from '../../../components/actions/forms/GetResponseActionForm';

const ActionConfiguration = () => {
  let {planId} = useParams();
  const [loading, setLoading] = useState(false);
  const [planName, setPlanName] = useState('');

  const onSubmit = (isSuccess) => {
    console.log(isSuccess);
  };

  useEffect(() => {
    axios.get(actions.getPlans + '?id=' + planId).then(({data: plan}) => {
      setPlanName(plan.plan_name);
    });
  }, []);

  return (
    <LayoutDashboard pageLoading={loading} className="action-configuration">
      <Breadcrumbs link="/actions" text={`Actions for ${planName}`} />
      <GetResponseActionForm
        setLoading={setLoading}
        onSubmit={onSubmit}
        planId={planId}
      />
    </LayoutDashboard>
  );
};

export default ActionConfiguration;
