import {
  LINK_ACCOUNT,
  LINK_ACCOUNT_ERROR,
  GET_GETSTARTED_STATUS,
} from '../actions/types';

import {getStarted} from '../helpers/Urls';
import axios from 'axios';
import {getCurrentAccount} from './account';

/**
 * Fetch Get Started Status and update redux state
 * @param {String} token
 * @returns dispatch GET_GETSTARTED_STATUS
 */
export const fetchGetStartedStatus = (token) => (dispatch) => {
  try {
    axios
      .get(getStarted.status, {
        headers: {Authorization: 'Bearer ' + token},
      })
      .then(({data}) => {
        let payload = {
          isLinked: data.linkedAccount.configured,
          isConfiguredBranding: data.branding.configured,
          linkedAccount: data.linkedAccount.data,
          branding: {...data.branding},
          isAddedFirstAction:
            data.linkedAccount.configured && data.branding.configured,
        };

        delete payload.branding.configured;

        return dispatch({
          type: GET_GETSTARTED_STATUS,
          payload,
        });
      })
      .catch((err) => {
        throw err;
      });
  } catch (error) {
    console.log('Fetch Error', error);
  }
};

/**
 * Save Link Account Details
 * @param {String} type
 * @param {Object} payload
 * @returns
 */
export const linkAccount = (type, payload) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    var url = getStarted.linkedAccount;
    var responseCode = 'IN_PROGRESS';

    switch (type) {
      case 'domain':
        url = getStarted.linkDomain;
        break;
      case 'webhook':
        url = getStarted.linkWebhookForwarding;
        break;
      case 'redirect':
        url = getStarted.linkRedirect;
        responseCode = 'DONE';
        break;
      default:
        url = getStarted.linkedAccount;
    }

    axios
      .post(url, payload, {
        headers: {
          Authentication: `Bearer ${localStorage.proxyToken}`,
        },
      })
      .then(({data}) => {
        console.log(data);
        dispatch({
          type: LINK_ACCOUNT,
          payload: {},
        });
        //TODO fallback on own user update

        dispatch(
          fetchGetStartedStatus(localStorage.proxyToken ?? localStorage.token)
        );
        resolve(responseCode);
      })
      .catch((err) => {
        console.log('Linking Account Error', err.response.data);
        dispatch({
          type: LINK_ACCOUNT_ERROR,
          payload: {},
        });
        reject(err.response.data);
      });
  });
};

export const goLiveAccount = (customerUniqueId, subscriptionUniqueId) => (
  dispatch
) => {
  return new Promise((resolve, reject) => {
    console.log('Going Live now');
    axios
      .get(getStarted.goLive)
      .then(({data}) => {
        console.log(data);
        dispatch(getCurrentAccount(customerUniqueId, subscriptionUniqueId));
        resolve(data);
      })
      .catch((err) => {
        console.log(err.response.data);
        resolve(err.response.data);
      });
  });
};
