import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import clsx from 'clsx';
// import FontPicker from 'font-picker-react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {fontOptions} from '../../helpers/StaticVariables';
import Button from '../../components/Buttons';
import PreviewEmail from '../../components/previewEmail';
import demoLogo from '../../assets/images/burtsbeekeeping-logo.png';
import {getStarted} from '../../helpers/Urls';
import axios from 'axios';

import ColorPicker from '../ColorPicker';
import UploadImage from '../UploadImage';
import {} from '../../helpers/BrandingThumbs';

const ConfigureEmail = ({handleClose, fetchLinkedAccount}) => {
  const {register, handleSubmit, errors} = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [styling, setStyling] = useState({
    header: {
      bg: '#F0B922',
      color: '#1C0B19',
    },
    body: {
      bg: '#FCF1D2',
      color: '#1C0B19',
    },
    button: {
      bg: '#000000',
      color: '#FFFFFF',
    },
    font: 'Verdana',
  });

  const [content, setContent] = useState({
    fromName: '',
    replyToAddress: '',
    emailLogo: demoLogo,
  });

  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const onSubmit = (data) => {
    setSubmitLoading(true);
    console.log(
      '%c 📩 Request Update... ',
      'color:Green;background:white;padding:5px;',
      files.length > 0 && files[0].size ? files[0] : ''
    );

    const fd = new FormData();
    fd.append('fromName', content.fromName);
    fd.append('replyToAddress', content.replyToAddress);

    fd.append('font', styling.font);

    fd.append(
      'colorScheme',
      JSON.stringify({
        header: styling.header,
        body: styling.body,
        button: styling.button,
      })
    );

    if (content.emailLogo !== '')
      fd.append('logo', files.length > 0 && files[0].size ? files[0] : '');

    axios
      .post(getStarted.createBrandingEmail, fd)
      .then(({data}) => {
        console.log(
          '%c ✔ Onboarding succesffuly updated ',
          'color:white;background:green;padding:5px;',
          data
        );
        setSubmitLoading(false);
        handleClose();
        fetchLinkedAccount();
      })
      .catch((err) => {
        console.log('Save Branding Error', err?.response?.data);
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <div className="wizard-steps">
        <div className={`wizard-steps__content__header `}>
          <span className="wizard-steps__number">2</span>
          <div className="wizard-steps__content__title">
            Emails from GetOnboard.ly
          </div>
        </div>
        <div className="wizard-steps__content__body">
          <div className="config-wrapper">
            <div className="config-wrapper__left">
              <div className="form-wrapper v2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.fromName,
                    })}
                  >
                    <label>
                      <span>From Name</span>
                      <input
                        name="fromName"
                        ref={register({
                          required: 'Required',
                        })}
                        onChange={(e) =>
                          setContent({
                            ...content,
                            fromName: e.target.value,
                          })
                        }
                        placeholder={`Burt's Bee Keeping`}
                      />
                    </label>
                  </div>

                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.replyToAddress,
                    })}
                  >
                    <label>
                      <span>Reply-to Address</span>
                      <input
                        name="replyToAddress"
                        type="email"
                        placeholder={`support@burtsbeekeeping.com`}
                        ref={register({
                          required: 'Required',
                        })}
                        onChange={(e) =>
                          setContent({
                            ...content,
                            replyToAddress: e.target.value,
                          })
                        }
                      />
                    </label>
                  </div>

                  <div className={clsx('input-wrapper')}>
                    <label>
                      <span>Font</span>
                      <Dropdown
                        options={fontOptions}
                        onChange={(font) =>
                          setStyling({
                            ...styling,
                            font: font.value,
                          })
                        }
                        value={styling.font}
                        placeholder="Select an option"
                      />
                    </label>
                  </div>

                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.bodyFont,
                    })}
                  >
                    <div className="button-wrapper">
                      <span>Color Scheme</span>
                      <div className="palette-wrapper">
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.header.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  header: {
                                    ...styling.header,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.header.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  header: {
                                    ...styling.header,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Header</p>
                        </div>
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.body?.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  body: {
                                    ...styling.body,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.body?.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  body: {
                                    ...styling.body,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Body</p>
                        </div>
                        <div className="palette-wrapper__single">
                          <div className="palette-wrapper__colors">
                            <ColorPicker
                              defaultColor={styling.button?.bg}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  button: {
                                    ...styling.button,
                                    bg: color,
                                  },
                                })
                              }
                            />
                            <ColorPicker
                              defaultColor={styling.button?.color}
                              callback={(color) =>
                                setStyling({
                                  ...styling,
                                  button: {
                                    ...styling.button,
                                    color,
                                  },
                                })
                              }
                            />
                          </div>
                          <p>Buttons</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={clsx('input-wrapper', {
                      'input-error': errors.bodyFont,
                    })}
                  >
                    <label>
                      <span>Logo</span>
                      <UploadImage
                        ImgPreviewUrl={(preview) =>
                          setContent({
                            ...content,
                            emailLogo: preview,
                          })
                        }
                        isUploaded={isUploaded}
                        onImageSelect={(file) => setFiles(file)}
                        setIsUploaded={(isImageUploaded) =>
                          setIsUploaded(isImageUploaded)
                        }
                      />
                    </label>
                  </div>

                  <Button
                    className="btn-purple"
                    loading={submitLoading}
                    text="Save branding configuration"
                  />
                </form>
              </div>
            </div>
            <div className="config-wrapper__right config-email">
              <PreviewEmail styling={styling} content={content} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigureEmail;
