import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

const NotificationHeader = ({onClick, email, full_name}) => {
  return (
    <div className="proxy-notif test">
      <button onClick={onClick}>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} />
          Return to GetOnboard.ly admin
        </span>
      </button>
      <p className="notif-details">
        You’re using GetOnboard.ly as {full_name}
        <strong> ({email})</strong>
      </p>
    </div>
  );
};

export default NotificationHeader;
